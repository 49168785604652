<div class="verbal-cntr">




  <ng-container *ngIf="isStart">

    <div class="title verbal-title"> {{"A&RINFOPAGE"|translate}} </div>

    <img class="verbal-img mt-3" src="/assets/images/verbal-game/ascolta.svg">

    <div class="subtitle verbal-subtitle">{{"A&RINFOTEST"|translate}}</div>

    <button mat-raised-button color="primary" class="verbal-button titleButton" disabled={{disabledButton}}
      (click)="playAudio()"><img class="verbal-icon-play" src="/assets/images/verbal-game/play.svg">{{"A&RLISTEN"|translate}} </button>

  </ng-container>

</div>
