import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TempResultService } from 'src/app/tests/games/temp-result.service';
import { DGCFilterTestDTO } from 'src/app/_core/classes/DTO/DGCFilterTestDTO';
import { DGCTestTypeEnum, SaveProgress } from 'src/app/_core/classes/DTO/test-progress-game/save-progress';
import { TestController } from 'src/app/_core/controllers/test.controller';
import { ISaveProgressDTO } from 'src/app/_core/interface/ISaveProgress';
import { TestConfigurationValue, TestInfoValueObject } from 'src/app/_core/value-object/TestInfoValueObject';

@Component({
  selector: 'app-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.scss'],
  providers: [TestController]
})
export class TestResultComponent implements OnInit {


  testObject?: TestConfigurationValue;
  testInfo: TestConfigurationValue = new TestConfigurationValue("Dati non disponibili",DGCTestTypeEnum.MEMORIA,"");
  resultList: SaveProgress[] = [];
  filter : DGCFilterTestDTO = new DGCFilterTestDTO();
  resultAvaiable: boolean = false;
  NoTestLabel = 'NOTESTSUBTITLE';

  year : number = new Date().getFullYear();

  anni : Array<number> = [];
  ordini : Array<string> = ["CRESCENTE","DECRESCENTE"];


  constructor(private router : Router,private route: ActivatedRoute, private testController : TestController, private _tempResultService : TempResultService ) {


    this.route.params.subscribe((params: Params) => this.testObject = TestInfoValueObject.getByType(params['test_type']));
    if(this.testObject){

      this.testInfo = this.testObject;
      this.filter.TestType = this.testInfo.Type;
      this.filter.Year = this.year;
      this.getTestResults();


    }

  }

  ngOnInit() {

    this.anni = this.getYearsFilter();

  }


  getYearsFilter(){

    let filterYear  = new Array<number>();
    let yearStart = 2021;

    for(let i = this.year-yearStart; i>=0; i-- ){

      filterYear.push(this.year-i);

    }
    return filterYear

  }

 getTestResults() {


    this.resultList = [];

    if(this.filter){
    this.testController.list(this.filter)
      .then((result: ISaveProgressDTO[]) => {

        this.resultAvaiable = false;
        if(result){

        result.forEach(element => {
          this.resultList.push( SaveProgress.CreateByDTO(element));
        });

        this.resultAvaiable = true;
      }

      });

  }
}

 filterByData(value : any){

this.filter.Year = value;

if(this.year == this.filter.Year){

  this.NoTestLabel = 'NOTESTSUBTITLE';

}else{

  this.NoTestLabel = 'NOTESTFORDATA';


}

 this.getTestResults();


}
filterByAscending(value : any){

  this.filter.ResultAscending = value === "CRESCENTE";
  this.getTestResults();

  }

  navigateTo(){


    let game : string = "";

    switch(this.testInfo.Type){

      case DGCTestTypeEnum.MEMORIA:
        game = "memory"
        break;
      case DGCTestTypeEnum.GENERATE:
        game = "generate"
        break;
      case DGCTestTypeEnum.MATCH:
        game = "match_numbers"
        break;
      case DGCTestTypeEnum.VERBAL:
        game = "verbal"
        break;

    }


    this.router.navigate(['mode/autonomous-mode/test/'+game ]);
    this._tempResultService.testSet();

  }

}
