import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserController } from 'src/app/_core/controllers/user.controller';

@Component({
  selector: 'app-tutorial-login',
  templateUrl: './tutorial-login.component.html',
  styleUrls: ['./tutorial-login.component.scss']

})
export class TutorialLoginComponent implements OnInit,AfterViewInit {


schede: SchedaTutorialLogin[] = new Array<SchedaTutorialLogin>;
index : any;
visible : boolean = false;
 constructor(private route: Router, private userController : UserController,private translateService : TranslateService) {

   this.setSchede();

 }


ngOnInit() {
}

ngAfterViewInit() {
  this.visible = true;
}

 setSchede(){


  this.schede.push(new SchedaTutorialLogin(`../../../assets/images/tutorial/login/${this.translateService.currentLang}/0.jpg`,false));
  this.schede.push(new SchedaTutorialLogin(`../../../assets/images/tutorial/login/${this.translateService.currentLang}/1.jpg`,false));
  this.schede.push(new SchedaTutorialLogin(`../../../assets/images/tutorial/login/${this.translateService.currentLang}/2.jpg`,false));
  this.schede.push(new SchedaTutorialLogin(`../../../assets/images/tutorial/login/${this.translateService.currentLang}/3.jpg`,false));
  this.schede.push(new SchedaTutorialLogin(`../../../assets/images/tutorial/login/${this.translateService.currentLang}/4.jpg`,true));

}

eventChange(event : any){

  this.index = event;

}
async endTutorial(){

  await this.userController.SetTutorialCompletion(0);
  this.route.navigate(['getting-started']);


}

}
export class SchedaTutorialLogin{

  ImgUrl : string;
  Last : boolean;

  constructor(imgUrl : string,last : boolean){

    this.ImgUrl = imgUrl;
    this.Last = last
  }

}
