<div class="generate-cntr">

    <div class="title"> {{ "G_PCATEGORY" | translate }} </div>

    <div class="subtitle mb-40"> {{ "G_PINFOCATEGORIA" | translate }} </div>

    <img class="generate-img mb-40" src="/assets/images/verbal-game/ripeti.svg">

    <mat-progress-bar mode="determinate" [value]="viewModel.progressBarValue"></mat-progress-bar>

    <div class="subtitle mb-40"> {{ "G_PINFOTEST" | translate }} </div>

</div>
