import { UserInfoHistory } from "./DTO/userInfoHistory/user-info-history";

export class Registration {

  private _Name!: string;
  public get Name(): string {
    return this._Name;
  }
  public set Name(value: string) {
    this._Name = value;
  }

  private _Surname!: string;
  public get Surname(): string {
    return this._Surname;
  }
  public set Surname(value: string) {
    this._Surname = value;
  }

  private _Email!: string;
  public get Email(): string {
    return this._Email;
  }
  public set Email(value: string) {
    this._Email = value;
  }

  private _Password!: string;
  public get Password(): string {
    return this._Password;
  }
  public set Password(value: string) {
    this._Password = value;
  }

  // private _FiscalCode!: string;
  // public get FiscalCode(): string {
  //   return this._FiscalCode;
  // }
  // public set FiscalCode(value: string) {
  //   this._FiscalCode = value;
  // }

  private _PhoneNumber!: string;
  public get PhoneNumber(): string {
    return this._PhoneNumber;
  }
  public set PhoneNumber(value: string) {
    this._PhoneNumber = value;
  }

  private _Language : string | undefined;
  public get Language(): string | undefined {
    return this._Language ;
  }
  public set Language(value: string | undefined) {
    this._Language = value;
  }

  private _Birthday!: Date;
  public get Birthday(): Date {
    return this._Birthday;
  }
  public set Birthday(value: Date) {
    this._Birthday = value;
  }

  private _UserInfoHistory!: UserInfoHistory;
  public get UserInfoHistory(): UserInfoHistory {
    return this._UserInfoHistory;
  }
  public set UserInfoHistory(value: UserInfoHistory) {
    this._UserInfoHistory = value;
  }

  private _UserCode!: string;
  public get UserCode(): string {
    return this._UserCode;
  }
  public set UserCode(value: string) {
    this._UserCode = value;
  }

  private _TermsAndConditionAcceptedAt!: Date;
  public get TermsAndConditionAcceptedAt(): Date {
    return this._TermsAndConditionAcceptedAt;
  }
  public set TermsAndConditionAcceptedAt(value: Date) {
    this._TermsAndConditionAcceptedAt = value;
  }


  private _PrivacyAcceptedAt !: Date;
  public get PrivacyAcceptedAt (): Date {
    return this._PrivacyAcceptedAt;
  }
  public set PrivacyAcceptedAt (value: Date) {
    this._PrivacyAcceptedAt = value;
  }

  private constructor(Name : string, Surname : string, Email : string, Password : string, PhoneNumber : string, Birthday : Date, UserInfoHistory : UserInfoHistory, UserCode : string, TermsAndConditionAcceptedAt : Date, PrivacyAcceptedAt : Date){
    this.Name = Name;
    this.Surname = Surname;
    this.Email = Email;
    this.Password = Password;
    this.PhoneNumber = PhoneNumber;
    this.Birthday = Birthday;
    this.Birthday.toISOString;
    this.UserInfoHistory = UserInfoHistory;
    this.UserCode = UserCode;
    this.TermsAndConditionAcceptedAt = TermsAndConditionAcceptedAt;
    this.PrivacyAcceptedAt = PrivacyAcceptedAt;
  }

  public static create(name : string,surname : string, email : string, password : string, phoneNumber : string, birthday : Date, userInfoHistory : UserInfoHistory, userCode : string, termsAndConditionAcceptedAt : Date, privacyAcceptedAt : Date){
    return new Registration(name,surname,email,password,phoneNumber,birthday,userInfoHistory,userCode,termsAndConditionAcceptedAt,privacyAcceptedAt);
  }

  public static createByClass(registrationClass : Registration) : Registration{
    return new Registration(registrationClass.Name,registrationClass.Surname,registrationClass.Email,registrationClass.Password,registrationClass.PhoneNumber,registrationClass.Birthday,registrationClass.UserInfoHistory,registrationClass.UserCode, registrationClass.TermsAndConditionAcceptedAt, registrationClass.PrivacyAcceptedAt);
  }


  public getJsonRequest() : string{
    return JSON.stringify({
        "Name": this.Name,
        "Surname": this.Surname,
        "PhoneNumber": this.PhoneNumber,
        "LanguageCode": this.Language,
        "Birthday": this.Birthday,
        "Email": this.Email,
        "UserInfoHistory": [this.UserInfoHistory],
        "Password": this.Password,
        "TermsAndConditionAcceptedAt": this.TermsAndConditionAcceptedAt,
        "PrivacyAcceptedAt":this.PrivacyAcceptedAt
    });
  }
}
