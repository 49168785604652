import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TempResultService } from 'src/app/tests/games/temp-result.service';
import { GenericLabel, ModalButton } from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import { QuitTestService } from '../../quit-test.service';

@Component({
  selector: 'app-quit-button',
  templateUrl: './quit-button.component.html',
  styleUrls: ['./quit-button.component.scss'],
})
export class QuitButtonComponent implements OnInit {
  @Input() disabled: boolean | undefined;

  closeResult = '';

  labelsDialog : DialogClass = DialogClass.create(
    new GenericLabel("QUITTEST"),
    [
      new ModalButton("NO", () => {}, false),
      new ModalButton("SI", () => this.backClicked(), true),
    ]);

  constructor(
    private route: Router,
    public dialog: MatDialog,
    private _tempResult: TempResultService,
    private quitTestService : QuitTestService
  ) {}

  ngOnInit(): void {}
  backClicked() {

    let url = window.location.pathname;
    if(url.includes("game")){

      this.route.navigate(['/mode/autonomous-mode/game']);

    }else{
      this.route.navigate(['/mode/autonomous-mode/test']);

    }

    this._tempResult.gameQuitted();
    this.quitTestService.setQuitTest(true);

  }
  openDialog() {
    this.dialog.open(GenericModalComponent,{
      data:this.labelsDialog,
    })

  }


}
