import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class LoginController {

  controller: string = '/ct/Login/';

  params: any = {};

  constructor(
    private ctWebapiService: CtWebapiService,
    private router: Router,
    private snackbar: MatSnackBar,
    private translate : TranslateService
  ) {
  }

  forgotPassword(email: string, callBackUrl: string): void {

    let params = {

      Email: email,
      CallBackUrlResetPassword: callBackUrl,

    };

    this.ctWebapiService.post(new DataRequest(`${this.controller}ResetPassword`), params)
      .subscribe((r: any) => {

        this.snackbar.open(this.translate.instant('RESETSENT'), 'X', {
          duration: 3000,
          panelClass: 'general-snackbar-panel',
        });

        this.router.navigate(['/authentication/login']);

      });

  }

  put(email: string, token: string, password: string): Promise<any> {

    let params = {

      Email: email,
      Token: token,
      Password: password,

    };

    return new Promise<any>((resolve) => {
      this.ctWebapiService
        .put(new DataRequest(`${this.controller}ResetPasswordRequest`), params).subscribe(
        r => {
          resolve(r as any);
        });

    });

  }

}
