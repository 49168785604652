<div class="container instructions-cntr">

<ng-container [ngSwitch]="testType">

  <ng-container *ngSwitchCase="testSwitch.MATCH_NUMBERS" [ngTemplateOutlet]="matchNumbersTemplate"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplateTest"></ng-container>

</ng-container>

<ng-template #defaultTemplateTest></ng-template>

<ng-template #matchNumbersTemplate>

  <ng-container [ngSwitch]="matchNumbersIstructionsNumber">

    <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="firstIstructions"></ng-container>
    <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="secondIstructions"></ng-container>
    <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="thirdIstructions"></ng-container>

  </ng-container>
  <ng-template #firstIstructions><app-FirstIstructionsMatch></app-FirstIstructionsMatch></ng-template>
  <ng-template #secondIstructions><app-secondIstructionsMatch></app-secondIstructionsMatch></ng-template>
  <ng-template #thirdIstructions><app-thirdIstructionsMatch></app-thirdIstructionsMatch></ng-template>

<div class="button-cntr">
  <button mat-raised-button class="button-arrow " color="primary" *ngIf="matchNumbersIstructionsNumber>0" (click)="changeIstructionsNumber(-1)"> <fa-icon class="icon" [icon]="ChevronLeft"></fa-icon></button>
  <button mat-raised-button class="button-arrow "color="primary" *ngIf="matchNumbersIstructionsNumber<3"(click)="changeIstructionsNumber(1)"><fa-icon [icon]="ChevronRight"></fa-icon></button>
  <button mat-raised-button class="button-arrowTest"color="primary" *ngIf="matchNumbersIstructionsNumber==3"(click)="navigateTo()">{{'STARTBUTTON' | translate}}</button>
</div>

</ng-template>


</div>
