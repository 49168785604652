import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  DGCTestTypeEnum,
  SaveProgress,
} from '../../_core/classes/DTO/test-progress-game/save-progress';

@Component({
  selector: 'app-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss'],
  providers : [DatePipe]
})
export class ResultCardComponent implements OnInit {
  @Input() result!: SaveProgress;
  @Input() type!: DGCTestTypeEnum;
  dataTest?: string ='' ;
  Result? : string ;
  Points? : number | string;

  constructor(private datePipe: DatePipe) {


  }

  ngOnInit(): void {
    this.dataTest = this.getData(this.result._CreateAppDateTime);
    this.Result = this.setResultString();
    this.getTitle();
  }

  getTitle = (): string => {
    switch (this.type) {
      case DGCTestTypeEnum.MEMORIA:
        return 'R&PTITLE';
      case DGCTestTypeEnum.GENERATE:
        return 'G_PTITLE';
      case DGCTestTypeEnum.VERBAL:
        return 'A&RTITLE';
      case DGCTestTypeEnum.MATCH:
        return 'A_ITITLE';
      default:
        return 'UNABLED TO LOAD DATA';
    }
  };

  getData(data: String): string {

   return this.datePipe.transform(data as string,"dd-MM-YYYY") ?? "";

  }

  setResultString() : string{

    this.Points = this.result._RawPointResult;
    return "RISULTATO";

  }

}
