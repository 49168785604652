import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { TEST_TYPES } from 'src/app/_core/enums/test-types.enum';
import { RimsService } from 'src/app/_core/services/rims.service';
import { TempResultService } from '../temp-result.service';

@Component({
  selector: 'app-games-result',
  templateUrl: './games-result.component.html',
  styleUrls: ['./games-result.component.scss']
})
export class GamesResultComponent implements OnInit {



  isRims = false;
  isTrainingMode = false;
  testType = TEST_TYPES;

  selected!: TestResultInfo | undefined;

  gameResult: TestResultInfo[] = [

    TestResultInfo.create('R&PRESULT','R&PRESULTSUBTITLE',TEST_TYPES.MEMORY),
    TestResultInfo.create('A&RRESULT','RISULTATOCLINICO',TEST_TYPES.LISTEN),
    TestResultInfo.create('G_PRESULTS','RISULTATOCLINICO',TEST_TYPES.GENERATES_WORDS),
    TestResultInfo.create('A_IRESULT','A_IINFOTEST',TEST_TYPES.MATCH_NUMBERS)
  ];

  gameResultTraining:TestResultInfo[] = [

    TestResultInfo.create('TRAINING_RESULT_TITLE','TRAINING_RESULT_SUBTITLE',TEST_TYPES.LISTEN),
    TestResultInfo.create('TRAINING_RESULT_TITLE','TRAINING_RESULT_SUBTITLE',TEST_TYPES.GENERATES_WORDS),
    TestResultInfo.create('TRAINING_RESULT_TITLE','TRAINING_RESULT_SUBTITLE',TEST_TYPES.MATCH_NUMBERS),

  ];

  constructor(
    private _tempResult: TempResultService,
    private route: Router,
    private router: ActivatedRoute,
    private rismService: RimsService) {

    this.router
      .data
      .subscribe((testType : Data) => {

        if (testType['type']) {

          if (this._tempResult.isTest) {

            this.selected = this.gameResult.find((testInfo: TestResultInfo) => testInfo.type === testType['type']);

          }

         else {

          this.selected = this.gameResultTraining.find((testInfo: TestResultInfo) => testInfo.type === testType['type']);

         }


        }
      });


      this.isTrainingMode = !this._tempResult.isTest;

  }
  score1Memory = this._tempResult.getOneResult(1, this.testType.MEMORY);
  score2Memory = this._tempResult.getOneResult(2, this.testType.MEMORY);
  score3Memory = this._tempResult.getOneResult(3, this.testType.MEMORY);
  totalScoreMemory = this._tempResult.getTotatResult(this.testType.MEMORY);

  scoreMatchNumber = this._tempResult.getMatchNumberResult();

  ngOnInit(): void {

      this.isRims = this.rismService.checkRims();
  }

  navigateTo() {

    if(this._tempResult.isTest){
    this.route.navigate(['mode/autonomous-mode/test']);
    }
    else{
      this.route.navigate(['getting-started']);

    }
  }

}


export class TestResultInfo{

  title: string;
  description: string;
  type: TEST_TYPES


  private constructor( title: string, description: string, type: TEST_TYPES )
{
    this.title = title;
    this.description = description;
    this.type = type;
}

public static create( title: string, description: string, type: TEST_TYPES ) : TestResultInfo{

    return new TestResultInfo(title,description,type);

}
}
