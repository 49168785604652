import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { UserInfo} from "../../../_core/classes/user-info";
import { UserController} from "../../../_core/controllers/user.controller";
import {TranslateService} from "@ngx-translate/core";
import {  UserInfoHistory } from 'src/app/_core/classes/DTO/userInfoHistory/user-info-history';
import { MaritalStatus, MaritalStatusObjectValue } from 'src/app/_core/classes/DTO/userInfoHistory/marital-status';
import { Education, EducationObjectValue } from 'src/app/_core/classes/DTO/userInfoHistory/education';
import { EDSS, EDSSObjectValue } from 'src/app/_core/classes/DTO/userInfoHistory/edss';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import { GenericLabel, ModalButton } from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { diseaseForm, DISEASEFORMObjectValue } from 'src/app/_core/classes/DTO/userInfoHistory/diseaseForm';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  providers: [UserController]
})
export class ProfileFormComponent implements OnInit {

  form: FormGroup;
  user! : UserInfo ;
  // maritalStatuses : Array<MaritalStatus> = MaritalStatusObjectValue.list();
  // educationes : Array<Education> = EducationObjectValue.list();
  // professionalConditions : Array<string> = ['Occupato','Disoccupato','Pensionato'];
  // edss : Array<EDSS> = EDSSObjectValue.list();
  // diseaseForm : Array<diseaseForm> = DISEASEFORMObjectValue.list();
  thisYear? : number ;



  labelsDialog : DialogClass = DialogClass.create(
    new GenericLabel("DATI"),
    [
      new ModalButton("CHIUDI", () => '', true,"button-profile"),

    ]);


  constructor(
    private fb: FormBuilder,
    private userController: UserController,
    private translate: TranslateService,
    private dialog : MatDialog,
  ) {

    this.form = this.fb.group({
      Oid: new FormControl(this.user?.Oid, Validators.required),
      Name: new FormControl(this.user?.Name, [Validators.required]),
      Surname: new FormControl(this.user?.Surname, [Validators.required]),
      Birthday: new FormControl(this.user?.Birthday, [Validators.required]),
      // FiscalCode: new FormControl( this.user?.FiscalCode),
      // MaritalStatus : new FormControl(this.user?.UserInfoHistory[this.user.UserInfoHistory.length-1].MaritalStatus.label),
      // Education : new FormControl(this.user?.UserInfoHistory[this.user.UserInfoHistory.length-1].Education.label),
      // ProfessionalCondition : new FormControl(this.user?.UserInfoHistory[this.user.UserInfoHistory.length-1].ProfessionalCondition),
      // EDSS : new FormControl(this.user?.UserInfoHistory[this.user.UserInfoHistory.length-1].EDSS.label),
      // DiseaseForm : new FormControl(this.user?.UserInfoHistory[this.user.UserInfoHistory.length-1].DiseaseForm.label),
      // DiagnosisDate : new FormControl<number | null>(this.user?.UserInfoHistory[this.user.UserInfoHistory.length-1].DiagnosisDate ?? null)
    });

  }

  ngOnInit(): void {

    this.thisYear = new Date().getFullYear();

    this.getUserInfo();

  }

  getUserInfo  (){

    this.userController.getUserInfo()
    .then((result: UserInfo) =>(this.user = UserInfo.create(result), this.setFormValue()));

  }

  putUserInfo = () => {

    if (this.form.valid) {


      let userForm : UserInfo = this.form.value as UserInfo;

      this.user.setUserData(userForm);

      //this.user.UserInfoHistory.push(UserInfoHistory.create(this.form.value as UserInfoHistory));

      // Aggiorna le info dell'utente. Passare sempre la lista di InfoHistory!
      // La sync delle infohistory funziona così:
      // se ha l'Oid settato, aggiorno quella esistente
      // se ha Oid = -1 o non settato, crea una nuova infohistory
      // se non viene passata, sarà cancellata dal db
      // attenzione che se mi passi un dto con le infohistory vuote, le cancello tutte!

       this.userController.putUserInfo(this.user);
       this.openDialog();

    }



  }

  private openDialog(){

    this.dialog.open(GenericModalComponent,{
      data:this.labelsDialog,
    })

  }

  private setFormValue() {

    if (this.user.Oid) {

      this.form.get('Oid')?.setValue(this.user.Oid);

    }

    if (this.user.Name) {

      this.form.get('Name')?.setValue(this.user.Name);

    }

    if (this.user.Surname) {

      this.form.get('Surname')?.setValue(this.user.Surname);

    }

    // if (this.user.FiscalCode) {

    //   this.form.get('FiscalCode')?.setValue(this.user.FiscalCode);

    // }
    if (this.user.Birthday) {

      this.form.get('Birthday')?.setValue(this.user.Birthday);

    }
    // if (this.user.UserInfoHistory && this.user.UserInfoHistory.length>0)
    // {
    //   const lastUserInfoHistory : UserInfoHistory = this.user.UserInfoHistory[this.user.UserInfoHistory.length-1];
    //   this.form.get('MaritalStatus')?.setValue(lastUserInfoHistory.MaritalStatus);
    //   this.form.get('Education')?.setValue(lastUserInfoHistory.Education);
    //   this.form.get('ProfessionalCondition')?.setValue(lastUserInfoHistory.ProfessionalCondition);
    //   this.form.get('EDSS')?.setValue(lastUserInfoHistory.EDSS);
    //   this.form.get('DiseaseForm')?.setValue(lastUserInfoHistory.DiseaseForm);
    //   this.form.get('DiagnosisDate')?.setValue(lastUserInfoHistory.DiagnosisDate);

    // }

  }

}
