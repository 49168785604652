<div class="login-cntr">

  <div class="text-center">

    <img class="login-logo" src="assets/images/logo/mail.svg">

    <p class="login-subtitle text-center">
      {{"RECUPERAMAIL"|translate}}
        </p>

    <form [formGroup]="form" (submit)="resetPassword()">

      <mat-form-field appearance="outline" style="width: 100%;">

        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="form.get('email')?.hasError('required')">{{'CAMPOOBBL'|translate}}</mat-error>
        <mat-error *ngIf="form.get('email')?.hasError('email')">{{'CAMPONONVALIDO'|translate}}</mat-error>

      </mat-form-field>

      <div>

        <button mat-raised-button type="submit" color="primary" class="titleButton login-button"> {{"RECUPERA"|translate}}</button>

      </div>

    </form>

  </div>

</div>
