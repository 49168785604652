<div class="result-mode-cntr container">

  <div class="scheda-result-cntr">
    <div class="text-center">
      <div class="title mb-2"><img class="img-cntr" src="../../../assets/images/result/circle-check.svg">{{'YOURRESULTTEST'|translate}}</div>
      <div class="subtitle mt-2 mb-3">{{"YOURRESULTSUBTITLE"|translate}}</div>
    </div>
        <div class="row">

          <app-test-description-card></app-test-description-card>

        </div>

  </div>

</div>
