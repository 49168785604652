import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconaProfiloComponent } from './IconaProfilo.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [IconaProfiloComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [IconaProfiloComponent],
})
export class IconaProfiloModule {}
