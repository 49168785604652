import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {NavigationEnd, Router} from '@angular/router';
import {CtWebviewService} from '@ctsolution/ct-webview';
import {TranslateService} from '@ngx-translate/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {environment} from 'src/environments/environment';
import {ENV} from 'src/environments/environment-type.enum';
import {DrawerService} from './layout/drawer.service';
import {UserInfo} from './_core/classes/user-info';
import { ClaimsService } from './_core/services/claims.service';
import { LocalStorageService } from './_core/services/localStorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  @ViewChild('drawer') public drawer: MatDrawer | undefined;
  profile?: UserInfo;

  constructor(
    private drawerService: DrawerService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private translate: TranslateService,
    private ctWebviewService: CtWebviewService,
    private claimService : ClaimsService,
    private localStorageService : LocalStorageService) {

    this.ctWebviewService.subscribeToInAppOpeningIntents();



    // TODO: chiaramente questo check diventarà if production, una volta disponibile
    // l'ambiente di produzione; piu avanti verrà migrato sulla tipologia di applicazione iOS/Android
    if (environment.type === ENV.DEMO) {

      this.router
        .events.forEach(item => {

        if (item instanceof NavigationEnd) {

          const gtmTag = {
            event: 'page',
            pageName: item.url
          };

          this.gtmService.pushTag(gtmTag);
        }

      });

    }

  }

 ngOnInit(): void {

    let code = this.localStorageService.getLanguage();
    if(code){

      this.translate.use(code);

    }

  }

  ngAfterViewInit(): void {

    if (this.drawer) {

      this.drawerService.setDrawer(this.drawer);

    }

  }

}
