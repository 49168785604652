import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';
import {
  GridDetail,
  MemoryTestResult
} from 'src/app/_core/classes/DTO/test-progress-game/memory-test-result';
import {
  DGCTestStateEnum,
  DGCTestTypeEnum,
  SaveProgress
} from 'src/app/_core/classes/DTO/test-progress-game/save-progress';
import { TestController } from 'src/app/_core/controllers/test.controller';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { TEST_TYPES } from 'src/app/_core/enums/test-types.enum';
import { RimsService } from 'src/app/_core/services/rims.service';
import { TempResultService } from '../temp-result.service';
import { GridCell, GridCellObjectValue } from './grid/grid-cell';
import { GridComponent } from './grid/grid.component';
import { MatrixService, MATRIX_ELEMENT_COUNT } from './grid/matrix.service';

@Component({
  selector: 'app-memory-game',
  templateUrl: './memory-game.component.html',
  styleUrls: ['./memory-game.component.scss'],
})
export class MemoryGameComponent implements OnInit, OnDestroy {
  type: GAME = GAME.TRAINING_MODE;
  memoryTestResult: Array<MemoryTestResult>=[];
  quitTest: boolean = true;

  startGrid: GridDetail[] = [
    { X: 0, Y: 0 },
    { X: 0, Y: 3 },
    { X: 1, Y: 5 },
    { X: 2, Y: 2 },
    { X: 2, Y: 5 },
    { X: 3, Y: 0 },
    { X: 4, Y: 2 },
    { X: 4, Y: 4 },
    { X: 5, Y: 1 },
    { X: 5, Y: 2 },
  ];

  @ViewChild('grid') grid!: GridComponent;

  viewModel: any = {
    isTrainingMode: false,
    progressBarValue: 0,
    currentTimingInSecond: null,
    readyToPlay: false,
    missingCellsCount: 0,
    gameFinished: false,
    disableButtonsUntilFinishGame: true,
    result: 0,
    testCounter: 1,
    hiddenButtonPreNewTestOrTraining: true,
    showTrainingResult: false,
    endGridDetail: [],
    allGridDetail: [],
    provaGridDetail: [],
    GRID_SETTINGS: GridCellObjectValue.memory_default_settings,
    createAppDateTime: Date,
  };

  saveProgress?: SaveProgress;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _matrix: MatrixService,
    private _result: TempResultService,
    private _memoryGameController: TestController,
    private RimsService : RimsService
  ) {
    this.route.data.subscribe((d) => {
      if (d) {
        this.type = d['type'] as GAME;
      }
    });
      if(this.type == GAME.TEST_MODE){
      this.saveProgress = SaveProgress.Create(DGCTestTypeEnum.MEMORIA);
      }

  }

  ngOnInit(): void {

    this.viewModel.createAppDateTime = new Date();
    this.viewModel.createAppDateTime.setMilliseconds(0);

  }
  ngOnDestroy() {
    if (this.quitTest) {
      this.saveTestProgress(DGCTestStateEnum.Quitted);
    }
  }

  /**
   * It starts a timer that counts down from a given number of seconds, and when it reaches zero, it
   * sets a boolean to true
   * @param {number} ms - number =&gt; the time in milliseconds
   */
  startTimer(ms: number, mid: boolean = false) {
    this.viewModel.readyToPlay = false;
    this.viewModel.showTrainingResult = false;
    this.viewModel.hiddenButtonPreNewTestOrTraining = !mid;
    let seconds = ms / 100;

    const timer$ = interval(100);

    const sub = timer$.subscribe((sec: number) => {
      this.viewModel.progressBarValue = 1 + (sec * 100) / seconds;
      this.viewModel.currentTimingInSecond = sec;
      if (this.viewModel.currentTimingInSecond === seconds) {
        if (mid) {
          if (this.viewModel.isTrainingMode) {
            this.repeatTraining();
          } else {
            this.viewModel.readyToPlay = false;
            this.endGame();
            this.grid.initMatrix(this.viewModel.isTrainingMode);
            // inizia un nuovo test
          }
        } else {
          this.grid.matrix = this._matrix.reinitMatrix(this.grid.matrix, true);
          this.viewModel.readyToPlay = true; // il timer è finito, posso iniziare a giocare
        }

        sub.unsubscribe();
      }
    });
  }

  /* A function that calls the startTimer function with the parameters ms and true. */
  startTimerBetweenTests = (ms: number = 4000) => this.startTimer(ms, true);

  repeatTraining() {
    this.viewModel.disableButtonsUntilFinishGame = true;
    this.viewModel.gameFinished = false;
    this.viewModel.missingCellsCount = 0;
    this.grid.initMatrix(this.viewModel.isTrainingMode);
  }

  /**
   * If the user is in training mode, then the game is finished and the user is redirected to the
   * result page. If the user is in test mode, then the game is finished and the user is redirected to
   * the result page.
   */
  endGame() {
    this.viewModel.result = this.getCorrectSelectedCellsCount(this.grid.matrix);
    this._result.setOneResult(
      this.viewModel.testCounter,
      this.viewModel.result,
      TEST_TYPES.MEMORY
    );

    if (this.viewModel.isTrainingMode) {
      this.viewModel.gameFinished = true;
      this.viewModel.showTrainingResult = true;
      this.viewModel.readyToPlay = false;
      this._result.gameEnded(this.viewModel.result);
    } else {
      switch (this.viewModel.testCounter) {
        case 1:
          this.viewModel.missingCellsCount = 0;
          this.viewModel.disableButtonsUntilFinishGame = true;
          this.viewModel.testCounter++;
          this.saveTestProgress(DGCTestStateEnum.Executing);
          break;
        case 2:
          this.viewModel.missingCellsCount = 0;
          this.viewModel.disableButtonsUntilFinishGame = true;
          this.viewModel.testCounter++;
          this.saveTestProgress(DGCTestStateEnum.Executing);
          break;
        default:
          this.saveTestProgress(DGCTestStateEnum.Completed);
          this.router.navigate(['../result'], { relativeTo: this.route });
          break;
      }
    }
  }

  /* A private method that returns the number of cells that are selected and are correct. */
  private getCorrectSelectedCellsCount = (matrix: GridCell[][]) =>
    this._matrix.getMatrixCountByElementType(
      matrix,
      MATRIX_ELEMENT_COUNT.CORRECT
    );

   saveTestProgress(stateTest: DGCTestStateEnum) {

    if(!this.saveProgress){
      return
    }

    if(stateTest == DGCTestStateEnum.Quitted){

      this._memoryGameController.putFormData(this.saveProgress.Quitted());
      return;

    }

    this.setMemoryTestResult();
    this.saveProgress.Completed(JSON.stringify(this.memoryTestResult),stateTest);
    this._memoryGameController.putFormData(this.saveProgress);
    this.quitTest = stateTest != DGCTestStateEnum.Completed;

   }

  setMemoryTestResult(){

    this.viewModel.createAppDateTime.setMilliseconds(0);
    this.viewModel.allGridDetail.push(this.viewModel.provaGridDetail);
    this.viewModel.provaGridDetail = [];
    let updateDate = new Date();
    const ExecutionSecondsSet : number = this.calculateSecond(updateDate);
    this.memoryTestResult.push(new MemoryTestResult(
    this.viewModel.result,
    this.startGrid,
    this.viewModel.endGridDetail,
    this.viewModel.allGridDetail,
    ExecutionSecondsSet
     ));

  }
  calculateSecond(updateDate : Date) : number{

    const secondsUpdate = updateDate.getSeconds() + (updateDate.getMinutes() * 60) + (updateDate.getHours() * 3600);
    const secondCreate = this.viewModel.createAppDateTime.getSeconds() + (this.viewModel.createAppDateTime.getMinutes() * 60) + (this.viewModel.createAppDateTime.getHours() * 3600);
    return  secondsUpdate - secondCreate;



  }
}
