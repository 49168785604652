<div class="login-cntr">

  <div class="text-center">

    <img class="login-logo" src="assets/images/logo/mail.svg">

    <p class="login-subtitle text-center">
      {{"RECUPERAMAIL"|translate}}
    </p>

    <form [formGroup]="form" (submit)="onSubmit()">

      <mat-form-field appearance="outline" style="width: 100%;">

        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="form.get('email')?.hasError('required')">{{'CAMPOOBBL'|translate}}</mat-error>
        <mat-error *ngIf="form.get('email')?.hasError('email')">{{'CAMPONONVALIDO'|translate}}</mat-error>

      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%;">

          <mat-label> {{"PASSWORD"|translate}}</mat-label>
          <input matInput type="password" [type]="hide ? 'password' : 'text'" [minlength]="6" [placeholder]="'PASSWORD'|translate" formControlName="password">
          <mat-icon matSuffix  (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="form.get('Password')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>
          <mat-error *ngIf="form.get('Password')?.hasError('minlength')">{{"6CHAR"|translate}}</mat-error>
          <mat-error *ngIf=" form.get('Password')?.hasError('passwordStrength')">{{"PWSTRENGHT"|translate}} </mat-error>

      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label> {{ "CONFERMAPASSWORD" | translate }}</mat-label>
        <input matInput type="password" [type]="hideConf ? 'password' : 'text'" [minlength]="6" [placeholder]="'CONFERMAPASSWORD'|translate" formControlName="ConfermaPassword">
        <mat-icon matSuffix  (click)="hideConf = !hideConf">{{hideConf ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="form.get('ConfermaPassword')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>

      </mat-form-field>
      <small>
        <mat-error class="pw-not-equal-cntr" *ngIf="passwordNotEqual && !form.get('ConfermaPassword')?.hasError('required')">* {{ 'PASSWORDDIVERSE' | translate }}</mat-error>
    </small>

      <div>

        <button mat-raised-button type="submit" color="primary" class="titleButton login-button"> {{'MODIFICA'|translate}}</button>

      </div>

    </form>

  </div>

</div>
