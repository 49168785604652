<mat-card class="test-score-cntr">


  <mat-card-title *ngIf="obj.title.label && obj.title.label !== ''" [ngClass]="obj.title.cssClass ?? ''">

    <img class={{obj.image?.cssClass}} src={{obj.image?.label}}>
    <div class={{obj.title.cssClass}}>{{obj.title.label | translate }}</div>
    <div class={{obj.subtitle?.cssClass}}>{{obj.subtitle?.label  }}</div>

  </mat-card-title>


</mat-card>
