<div class="grid-cntr">



<ng-container *ngIf="isTutorial; else isNotTutorialTemplate">

  <div class="match-tutorial-info">

    <p class="subtitle" [innerHTML]="['A_ITUTORIALINFO'|translate]"></p>

  </div>

  <app-match-number-grid [matrix]="legends" [matrixOption]="legedsOption"></app-match-number-grid>

  <mat-card class="grid-number-cntr mt-3 text-right">

    <mat-grid-list cols="4" rowHeight="40px">

      <mat-grid-tile *ngFor="let tile of matrixCompleteTutorial[this.posMatrix]" [colspan]="tile.cols"
        [rowspan]="tile.rows" [style.background]="tile.color" [ngClass]="{'border-solid': tile.selected}">

        <ng-container *ngIf="tile.text ; else iconTemplate">

          <div class="bolder">{{tile.text}}</div>

        </ng-container>

        <ng-template #iconTemplate>

          <mat-icon>{{tile.icon}}</mat-icon>

        </ng-template>

      </mat-grid-tile>

      <mat-grid-tile *ngFor="let tile of matrixTutorial[this.posMatrix]" [colspan]="tile.cols"
        [rowspan]="tile.rows" [style.background]="tile.color" [ngClass]="{'border-solid': tile.selected}"
        (click)="getPosition(tile.index ?? -1,matrixTutorial,this.posMatrix)">

        <div class="bolder">{{tile.number}}</div>

      </mat-grid-tile>

    </mat-grid-list>

  </mat-card>

</ng-container>











<ng-template #isNotTutorialTemplate>



<app-match-number-grid [matrix]="legends" [matrixOption]="legedsOption"></app-match-number-grid>



<ng-container *ngIf="this.posMatrix < this.matrix.length">

  <mat-card class="grid-number-cntr mt-3 text-right">

    <mat-grid-list cols="4" rowHeight="50px">

      <mat-grid-tile *ngFor="let tile of matrixComplete[this.posMatrix]" [colspan]="tile.cols"
        [rowspan]="tile.rows" [style.background]="tile.color" [ngClass]="{'border-solid': tile.selected}">

        <ng-container *ngIf="tile.text ; else iconTemplate">

          <div class="bolder">{{tile.text}}</div>

        </ng-container>

        <ng-template #iconTemplate>

          <mat-icon>{{tile.icon}}</mat-icon>

        </ng-template>

      </mat-grid-tile>

      <mat-grid-tile *ngFor="let tile of matrix[this.posMatrix]" [colspan]="tile.cols"
        [rowspan]="tile.rows" [style.background]="tile.color" [ngClass]="{'border-solid': tile.selected}"
        (click)="getPosition(tile.index ?? -1,matrix,this.posMatrix)">

        <div class="bolder">{{tile.number}}</div>

      </mat-grid-tile>

    </mat-grid-list>

  </mat-card>

</ng-container>


<ng-container *ngIf="this.posMatrix < this.matrix.length -1  ; else finishMatrixTemplate">
  <div class="match-button-cntr">

    <button mat-raised-button class="subtitle match-button" [disabled]="disabledButton" (click)="nextMatrix()"><fa-icon class="icon" [icon]="arrowRightLong"></fa-icon></button>

  </div>

</ng-container>


<ng-template #finishMatrixTemplate>

  <div class="match-button-cntr">

    <button mat-raised-button class="subtitle match-button mt-2" (click)="concludeTest()">Termina test</button>

  </div>

</ng-template>

</ng-template>

<ng-container *ngIf="this.posMatrix < this.matrix.length">

  <app-match-number-grid [matrix]="qwerty" [matrixOption]="qwertyOption" (selectNumber)="selectNumber($event)">
  </app-match-number-grid>

</ng-container>


<ng-container *ngIf="isTutorial">

  <div class="match-button-tutorial-cntr">

    <button mat-raised-button class="subtitle match-button-tutorial mt-3 bolder" (click)="openDialog()">{{"FINEPROVA"|translate}}</button>

  </div>

</ng-container>

</div>
