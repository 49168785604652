import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstIstructionsMatchComponent } from './FirstIstructionsMatch.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { TranslateModule } from '@ngx-translate/core';
import { MatchNumberGridModule } from 'src/app/tests/games/match-numbers/match-number-grid/match-number-grid.module';
import { DotsIstructionsModule } from '../../dots-istructions/dots-istructions.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule,
    MatchNumberGridModule,
    DotsIstructionsModule
  ],
  declarations: [FirstIstructionsMatchComponent],
  exports:[FirstIstructionsMatchComponent]
})
export class FirstIstructionsMatchModule { }
