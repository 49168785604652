import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DGCTestTypeEnum, SaveProgress } from 'src/app/_core/classes/DTO/test-progress-game/save-progress';
import { TestConfigurationValue, TestInfoValueObject } from 'src/app/_core/value-object/TestInfoValueObject';

@Component({
  selector: 'app-test-description-card',
  templateUrl: './test-description-card.component.html',
  styleUrls: ['./test-description-card.component.scss']
})
export class TestDescriptionCardComponent implements OnInit {

  testValueObject: Array<TestConfigurationValue> = [];
  constructor(private route : Router) { 
    this.testValueObject = TestInfoValueObject.Items;
  }

  ngOnInit() {
    
  }

  navigateTo(test_type : DGCTestTypeEnum){

    this.route.navigate(['test-result',test_type]);

  }
 
    
}
