<div class="login-cntr">

  <div class="text-center">

    <img class="login-logo" src="assets/images/logo/mail.svg">

    <p class="subtitle">
      {{'LOGINTITLE'|translate}}
    </p>

    <form [formGroup]="form" (submit)="login()">

      <mat-form-field appearance="outline" class="form-field">

        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="form.get('email')?.hasError('required')">{{'CAMPOOBBL'|translate}}</mat-error>
        <mat-error *ngIf="form.get('email')?.hasError('email')">{{'CAMPONONVALIDO'|translate}} </mat-error>

      </mat-form-field>

      <mat-form-field appearance="outline" class="form-field">

        <mat-label>Password</mat-label>
        <input type="password" formControlName="password" matInput required [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix  (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="form.get('password')?.hasError('required')">{{'CAMPOOBBL'|translate}}</mat-error>

      </mat-form-field>

      <p class="login-subtitle form-subtitle">
        <a (click)="navigateToReset()" target="_blank">{{"RECUPERA"|translate}}</a>
      </p>

      <div>

        <button mat-raised-button type="submit" color="primary" class="titleButton login-button"> {{"ACCEDI"|translate}}</button>
        <button mat-raised-button [routerLink]="'/registration'" type="button" color="primary" class="titleButton registration-button" style="margin-bottom: 30px;"> {{"REGISTRATI"|translate}}</button>

      </div>

    </form>

  </div>

</div>
