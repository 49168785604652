<div class="d-flex flex-column intro-text-cntr container">

  <ng-container *ngIf="selected">

    <div class="title text-center">
      {{ selected.title | translate }}
    </div>

    <div class="subtitle">

      <p [innerHTML]="selected.description | translate"></p>

    </div>

    <!-- <img src={{selected.img}} class="img-cntr"> -->

    <ng-container *ngIf="isTest; else trainingTemplate">

      <ng-container [ngSwitch]="selected.type">

        <ng-container *ngSwitchCase="testTypes.MEMORY" [ngTemplateOutlet]="memoryTemplateRims"></ng-container>
        <ng-container *ngSwitchCase="testTypes.MATCH_NUMBERS" [ngTemplateOutlet]="matchNumbersTemplate"></ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplateTest"></ng-container>

      </ng-container>

      <ng-template #defaultTemplateTest>

        <button mat-raised-button color="primary" class="titleButton start-button mb-4" [hidden]="isRims && selected.type == testTypes.MEMORY" (click)="onSelected('test')">
          {{'STARTTESTBUTTON' | translate}} </button>

      </ng-template>

      <ng-template #matchNumbersTemplate>

        <button mat-raised-button color="primary" class="titleButton start-button mb-4" [hidden]="isRims && selected.type == testTypes.MATCH_NUMBERS"(click)="onSelected('instructions')">
          {{'STARTBUTTON' | translate}} </button>

      </ng-template>

      <ng-template #memoryTemplateRims>

        <div class="text-center"  [hidden]="!isRims">

        <form [formGroup]="form" (ngSubmit)="putUserInfo()">

          <p>
            <mat-form-field appearance="fill">
              <mat-label> {{'INSERISCICODICE'| translate}}</mat-label>
              <input matInput [placeholder]="'CODICE'|translate" formControlName="codeRims">
              <mat-error *ngIf="form.get('codeRims')?.hasError('required')">{{'CAMPOOBBL'|translate}}</mat-error>
            </mat-form-field>
          </p>

          <button mat-raised-button color="primary" type="submit" class="titleButton start-button mb-4 ">
            {{'STARTTESTBUTTON' | translate}} </button>

        </form>

      </div>

      <button mat-raised-button color="primary" class="titleButton start-button mb-4 " [hidden]="isRims" (click)="onSelected('test')">
        {{'STARTTESTBUTTON' | translate}} </button>

      </ng-template>


    </ng-container>

    <ng-template #trainingTemplate>

      <ng-container [ngSwitch]="selected.type">

        <ng-container *ngSwitchCase="testTypes.MEMORY" [ngTemplateOutlet]="memoryTemplate"></ng-container>
        <ng-container *ngSwitchCase="testTypes.MATCH_NUMBERS" [ngTemplateOutlet]="matchTemplate"></ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplate"></ng-container>

      </ng-container>


    </ng-template>

  </ng-container>

</div>

<ng-template #memoryTemplate>

  <div class="row">

    <div class="card-cntr d-flex mb-4">

      <div class="difficult-text">
        <h2 class="subtitle" [innerHTML]="'R&PSELECTDIFFICULT'| translate"></h2>
      </div>

      <div class="select-difficult-cntr">
      <ng-container *ngFor="let difficult of difficults">

        <app-difficult-card [obj]="difficult" (newItemEvent)="setArray($event)"></app-difficult-card>

      </ng-container>
    </div>
    </div>
    <div class="body-text">
      <h2 class="subtitle bolder" style="text-align: center;" [innerHTML]="'R&PCLICKSTART'| translate"></h2>
    </div>

    <button mat-raised-button color="primary" class="titleButton start-button mb-4 " (click)="onSelected('training')"
    [disabled]="!_difficult.isSet()">
    {{'ALLENATIBUTTON' | translate}}
  </button>

  </div>

</ng-template>

<ng-template #matchTemplate>

  <button mat-raised-button color="primary" class="titleButton start-button mb-4" (click)="onSelected('instructions-training')">
    {{'ALLENATIBUTTON' | translate}}</button>

</ng-template>

<ng-template #defaultTemplate>


  <button mat-raised-button color="primary" class="titleButton start-button mb-4" (click)="onSelected('training')">
  {{'ALLENATIBUTTON' | translate}}</button>

</ng-template>
