import { Injectable } from '@angular/core';

export const TRAINING_CYCLES: { x: number, y: number }[] = [
  { x: 0, y: 0 },
  { x: 0, y: 3 },
  { x: 3, y: 0 },
  { x: 3, y: 3 }
];

export const TEST_CYCLES: { x: number, y: number }[] = [
  { x: 0, y: 3 },
  { x: 0, y: 4 },
  { x: 1, y: 1 },
  { x: 1, y: 3 },
  { x: 2, y: 5 },
  { x: 3, y: 0 },
  { x: 3, y: 3 },
  { x: 4, y: 0 },
  { x: 5, y: 2 },
  { x: 5, y: 5 }
];

@Injectable({
  providedIn: 'root'
})
export class MemoryGameService {

  constructor() { }

}
