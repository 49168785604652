<div class="getting-started-cntr w-100">

  <div class="text-center">

    <p class="title mb-4 padding-top">

      {{ 'WELCOME' | translate:{ projectName } }} <sup style="font-weight: 400;">®</sup>


    </p>
    <!-- <app-IconaProfilo></app-IconaProfilo> -->
    <div class="body-cntr mb-4 margin-top-50-tablet">
    <img src="/assets/images/homepage/benvenuto.svg" class="getting-started-img ">
    </div>

    <div class="button-cntr margin-top-50-tablet">
    <button mat-raised-button color="primary" class="titleButton start-button generic-button" (click)="navigateTo('/mode/autonomous-mode')"> {{ 'STARTBUTTON' | translate }} </button>
  </div>

  <div class="img-cntr">
    <img src="/assets/images/homepage/logo_digicog.svg" class="getting-started-logo ">

  </div>

  </div>
</div>
