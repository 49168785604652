<!-- <ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title title" id="modal-basic-title">{{'QUITTEST'|translate}}</h4>

  </div>

  <div class="modal-body">
    <div class="row">
      <button mat-raised-button color="primary" class="scheda-button title" (click)=" backClicked()">{{'ESCIBUTTON'|translate}}</button>

      <button mat-raised-button color="primary" class="scheda-button title" (click)="modal.close('Save click')">
        {{'RESUMEBUTTON'|translate}}</button>

    </div>

  </div>
</ng-template> -->
<button mat-flat-button class="quit-button" (click)="openDialog()">
  {{'ESCIBUTTON'|translate}}
</button>





