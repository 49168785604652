import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {UserInfo} from "../classes/user-info";

@Injectable()
export class UserController {

  private controller: string = '/user';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  getAvatar(): Promise<Blob> {

    const request: DataRequest = new DataRequest(`${this.controller}/GetAvatar`, { hideSpinner: true});
    request.setResponseType('blob');

    return new Promise<Blob>((resolve) => {

      this.ctWebApiService
        .get(request)
        .subscribe((result: Blob) => resolve(result));

    });

  }

  setAvatar(file: File): Promise<boolean> {

    const request: DataRequest = new DataRequest(`${this.controller}/SetAvatar`);

    const formData: FormData = new FormData();
    formData.append('newFile', file, file.name);
    return new Promise<boolean>((resolve) => {

      this.ctWebApiService
        .put(request, formData)
        .subscribe((result: any) => resolve(true));

    });

  }

  getUserInfo(): Promise<UserInfo> {

    const request: DataRequest = new DataRequest(`${this.controller}/GetUserInfo`);


    return new Promise<UserInfo>((resolve) => {

      this.ctWebApiService
        .get(request)
        .subscribe((result: UserInfo) => resolve(result));

    });

  }

  SetTutorialCompletion(state : number) : Promise<any>{

    const request: DataRequest = new DataRequest(`${this.controller}/SetTutorialCompletion`);


    return new Promise<any>((resolve) => {

      this.ctWebApiService
        .put(request,state)
        .subscribe((result: any) => resolve(true));

    });


  }
  // getLanguage(): Promise<UserInfo["Language"]> {

  //   const request: DataRequest = new DataRequest(`${this.controller}/GetUserInfo`);

  //   return new Promise<UserInfo["Language"]>((resolve) => {

  //     this.ctWebApiService
  //       .get(request)
  //       .subscribe((result: UserInfo) => resolve(result.Language));

  //   });

  // }

  putUserInfo(userInfo: UserInfo): Promise<any> {

    const request: DataRequest = new DataRequest(`${this.controller}/SyncUserInfo`);

    // this.putUserLanguage(userInfo.LanguageCode);

    return new Promise<any>((resolve) => {

      this.ctWebApiService
        .put(request, userInfo)
        .subscribe((result: any) => resolve(result));

    });


  }

  putUserLanguage(language : string) : Promise<any>{

    const request: DataRequest = new DataRequest(`${this.controller}/SetLanguage`);

    return new Promise((resolve) =>{


      this.ctWebApiService
      .post(request,language)
      .subscribe((result : any) => resolve(result));


    });

  }

  ProfileDeletionRequest(notifyAdmin : boolean | null) : Promise <any>{

    const request: DataRequest = new DataRequest(`${this.controller}/ProfileDeletionRequest`);

    return new Promise<any>((resolve) => {

      this.ctWebApiService
        .put(request, notifyAdmin)
        .subscribe((result: any) => resolve(result));

    });

  }


}

