import {UserInfoHistory} from "./DTO/userInfoHistory/user-info-history";

export class UserInfo {

  CreateAt?: Date;
  UpdateAt?: Date;


  constructor(
    public Oid: number,
    public Name: string,
    public Surname: string,
    // public FiscalCode : string,
    public Birthday : Date,
    //public UserInfoHistory: UserInfoHistory[],
    public LanguageCode: string
  ) {

  }

  public setUserData(user: UserInfo){
    this.Name = user.Name;
    this.Surname = user.Surname;
    this.Birthday = user.Birthday;
    this.LanguageCode = user.LanguageCode;
  }

  public static create(user: UserInfo): UserInfo{
    return new UserInfo(user.Oid, user.Name, user.Surname,user.Birthday, user.LanguageCode);
  }
}

