import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchNumberGridComponent } from './match-number-grid.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatGridListModule,
    MatCardModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
  ],
  declarations: [MatchNumberGridComponent],
  exports: [MatchNumberGridComponent]
})
export class MatchNumberGridModule { }
