import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuitButtonComponent } from './quit-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [QuitButtonComponent],
  imports: [CommonModule, MatButtonModule,TranslateModule, NgbModule, MatButtonModule],
  exports: [QuitButtonComponent],
})
export class QuitButtonModule {}
