import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TestController } from 'src/app/_core/controllers/test.controller';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { TEST_TYPES } from 'src/app/_core/enums/test-types.enum';
import { GamesResultComponent } from '../games-result/games-result.component';
import { TestIntroComponent } from '../test-intro/test-intro.component';
import { Recorder_componentComponent } from './recorder_component/recorder_component.component';
import { Recorder_componentModule } from './recorder_component/recorder_component.module';
import { VerbalMemoryGameComponent } from './verbal-memory-game.component';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    Recorder_componentModule,
    TranslateModule,
    RouterModule.forChild([
      {
        path: 'training',
        data: { type: GAME.TRAINING_MODE },
        children: [
          {
            path: 'record',
            component: Recorder_componentComponent,
            data: { disableBack: true, disableQuit: true },

          },
          {
            path: '',
            component: VerbalMemoryGameComponent,

          }
        ]
      },
      {
        path: 'test',
        data: { type: GAME.TEST_MODE},
        children: [
          {
            path: 'record',
            component: Recorder_componentComponent,
            data: { disableBack: true, disableQuit: true,type: GAME.TEST_MODE },

          },
          {
            path: '',
            component: VerbalMemoryGameComponent,

          }
        ]
      },
      {
        path: 'result',
        component: GamesResultComponent,
        data: { type: TEST_TYPES.LISTEN, disableBack: true, disableQuit: false },

      },
      {
        path: '',
        component: TestIntroComponent,
        data: { type: TEST_TYPES.LISTEN, disableBack: false, disableQuit: false },
      },
    ]),
  ],
  declarations: [VerbalMemoryGameComponent],
  exports: [VerbalMemoryGameComponent],
  providers : [TestController]
})
export class VerbalMemoryGameModule { }
