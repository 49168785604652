import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GenericLabel,
  ICard,
  ModalButton,
} from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import { ClaimsService } from 'src/app/_core/services/claims.service';

@Component({
  selector: 'app-test-type',
  templateUrl: './test-type.component.html',
  styleUrls: ['./test-type.component.scss'],
})
export class TestTypeComponent implements OnInit {
  modes: Array<ICard> = [
    {
      type : 0,
      title: new GenericLabel('AUTONOMA', 'black-text'),
      button: {
        label: 'OKBUTTON',
        action: () =>
          this.router.navigate(['autonomous-mode'], { relativeTo: this.route }),
      },
      class: { label: 'autonoma-scheda-img' },
    },
    //  {
    //   type : 0,
    //   title: new GenericLabel('GUIDATA', 'black-text'),
    //   button: { label: 'OKBUTTON', action: () =>this.openDialog()},
    //   class: { label: 'guidata-scheda-img' },
    //   disabled: true,
    //  },
  ];


  constructor(private router: Router, private route: ActivatedRoute, private dialog  : MatDialog,private claimsService : ClaimsService) {}

  async ngOnInit(): Promise<void> {

    this.router.navigate(['autonomous-mode'], { relativeTo: this.route }); 
  }


  openDialog(){
    let label : GenericLabel = new GenericLabel('GUIDATACOOMINGSOON');
    let buttons : ModalButton[] = [new ModalButton("CHIUDI",()=>{},true)];
    let dialogData : DialogClass = DialogClass.create(label,buttons);
    this.dialog.open(GenericModalComponent, {data : dialogData });
  }
}
