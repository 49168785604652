import { Injectable } from '@angular/core';

export enum LEVEL {

  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD'

}

@Injectable({
  providedIn: 'root'
})
export class LevelService {

  currentLevel: LEVEL | null = null;

  constructor() { }

  public isSet = () => this.currentLevel !== null;

  public setLevel = (level: LEVEL) => this.currentLevel = level;

  private checkCurrentLevel = (level: LEVEL) => this.currentLevel === level;

  public reset() {

    this.currentLevel = null;

  }

  public setMode = {

    easy: () => this.setLevel(LEVEL.EASY),

    medium: () => this.setLevel(LEVEL.MEDIUM),

    hard: () => this.setLevel(LEVEL.HARD)

  }

  public getMode = {

    easy: () => this.checkCurrentLevel(LEVEL.EASY),

    medium: () => this.checkCurrentLevel(LEVEL.MEDIUM),

    hard: () => this.checkCurrentLevel(LEVEL.HARD),

  }

}
