import { Data } from "@angular/router";

export class MemoryTestResult {


  private _correct?: number | undefined;

  private _startGridDetail?: GridDetail[] | undefined;

  private _endGridDetail?: GridDetail[] | undefined;

  private _actionHistory?: GridDetail[] | undefined;

  private _ExecutionSecondsSet?: number | undefined;

  public constructor(

    correct: number,
    startGridDetail: GridDetail[],
    endGridDetail: GridDetail[],
    actionHistory: GridDetail[],
    ExecutionSecondsSet : number
  )
  {


    this.correct = correct;
    this.endGridDetail = endGridDetail;
    this.startGridDetail = startGridDetail;
    this.actionHistory = actionHistory;
    this.ExecutionSecondsSet = ExecutionSecondsSet;

  }





  public get correct(): number | undefined {
    return this._correct;
  }
  private set correct(value: number | undefined) {
    this._correct = value;
  }
  public get startGridDetail(): GridDetail[] | undefined {
    return this._startGridDetail;
  }
  private set startGridDetail(value: GridDetail[] | undefined) {
    this._startGridDetail = value;
  }
  public get endGridDetail(): GridDetail[] | undefined {
    return this._endGridDetail;
  }
  private set endGridDetail(value: GridDetail[] | undefined) {
    this._endGridDetail = value;
  }
  public get actionHistory(): GridDetail[] | undefined {
    return this._actionHistory;
  }
  private set actionHistory(value: GridDetail[] | undefined) {
    this._actionHistory = value;
  }

  public get ExecutionSecondsSet(): number | undefined{
    return this._ExecutionSecondsSet;
  }
  private set ExecutionSecondsSet(value: number | undefined) {
    this._ExecutionSecondsSet = value;
  }
}

export interface ActionHistory {
  PosX: number;
  PosY: number;
  UserDateTime: Date;
}

export class GridDetail {
  constructor(public X : number, public Y : number, public dataClick? : Data)
{
}
}
