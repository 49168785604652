import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GamesModule } from './games/games.module';
import { TestModeModule } from './test-type/test-mode/test-mode.module';
import { TestTypeModule } from './test-type/test-type.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'autonomous-mode',
        children: [
          {
            path: 'test',
            loadChildren: () => GamesModule
          },
          {
            path: 'game',
            loadChildren: () => GamesModule,
          },
          {
            path: '',
            loadChildren: () => TestModeModule
          }
        ]
      },
      {
        path: '',
        loadChildren: () => TestTypeModule
      }
    ])
  ],
})
export class TestsModule { }
