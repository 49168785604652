export class VerbalMemoryGameInfo {


    private _secondsAttempt?: number | undefined;

    private _numberAttempt?: number | undefined;


    private constructor(

      secondsAttempt: number,
      numberAttempt: number,

    )
    {

      this.secondsAttempt = secondsAttempt;
      this.numberAttempt = numberAttempt;

    }

    public static create(secondsAttempt : number, numberAttempt : number) : VerbalMemoryGameInfo{


      return new VerbalMemoryGameInfo(secondsAttempt,numberAttempt);

    }



    public get secondsAttempt(): number | undefined {
      return this._secondsAttempt;
    }
    private set secondsAttempt(value: number | undefined) {
      this._secondsAttempt = value;
    }
    public get numberAttempt(): number  | undefined {
      return this._numberAttempt;
    }
    private set numberAttempt(value:number | undefined) {
      this._numberAttempt = value;
    }

  }




