import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestSelectionComponent } from './test-selection.component';
import { GenericCardModule } from 'src/app/_components/generic-card/generic-card.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, GenericCardModule,TranslateModule],
  declarations: [TestSelectionComponent],
})
export class TestSelectionModule {}
