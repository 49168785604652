import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TEST_TYPES } from 'src/app/_core/enums/test-types.enum';
import { Location } from '@angular/common';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {faChevronLeft} from'@fortawesome/free-solid-svg-icons';
import { GAME } from 'src/app/_core/enums/game-type.enum';


@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {

  testType?: TEST_TYPES;
  testSwitch = TEST_TYPES;
  matchNumbersIstructionsNumber : number = 1;
  ChevronRight = faChevronRight;
  ChevronLeft = faChevronLeft;
  type?: GAME;


  constructor(private route : ActivatedRoute,
    private router : Router,
    private location : Location) { }

  ngOnInit() {
    this.route.data.subscribe((d) => {

      if (d) {
        this.testType =d['testType'],

        this.type = d['type'] as GAME;
      }
    });
  }



  navigateTo(){

    if(this.type == GAME.TRAINING_MODE){
    this.router.navigate(['training'],{relativeTo : this.route});
    }else
    {
      this.router.navigate(['test'],{relativeTo : this.route});
    }

  }

  changeIstructionsNumber(value : number){

    if(this.matchNumbersIstructionsNumber +value == 0){

      this.location.back();

    }

    if(this.testType = this.testSwitch.MATCH_NUMBERS){

    this.matchNumbersIstructionsNumber = this.matchNumbersIstructionsNumber +value;

    }
  }

}
