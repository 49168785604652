<div class="match-grid-cntr">
<mat-card class="grid-number-cntr mt-3">

  <mat-grid-list cols={{matrixOption?.cols}} rowHeight={{matrixOption?.rowHeight}}>

    <mat-grid-tile *ngFor="let tile of matrix" [colspan]="tile.cols" [rowspan]="tile.rows"
      [style.background]="tile.color" (click)="getNumberToFill(tile.number)">

      <ng-container *ngIf="matrixOption?.isText ; else isNumTemplate">

        <ng-container *ngIf="tile.text ; else iconTemplate">

          <div class="bolder">{{tile.text}}</div>

        </ng-container>

        <ng-template #iconTemplate>

          <mat-icon>{{tile.icon}}</mat-icon>

        </ng-template>

      </ng-container>

      <ng-template #isNumTemplate>

        <div class="bolder">{{tile.number}}</div>

      </ng-template>

    </mat-grid-tile>

  </mat-grid-list>

</mat-card>
</div>
