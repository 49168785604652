import { Injectable } from '@angular/core';
import { GridCell } from './grid-cell';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {

  constructor() { }

  /**
   * > It counts the number of elements in a matrix that are selected or correct
   * @param {GridCell[][]} matrix - GridCell[][] - the matrix to count the elements in
   * @param {MATRIX_ELEMENT_COUNT} type - MATRIX_ELEMENT_COUNT
   * @returns The number of elements in the matrix that are selected or correct.
   */
  getMatrixCountByElementType(matrix: GridCell[][], type: MATRIX_ELEMENT_COUNT) {

    let count: number = 0;

    matrix
      .forEach((r) => {

        r.forEach((c) => {

          switch (type) {

            case MATRIX_ELEMENT_COUNT.SELECTED:
              if (c.selected) count++;
              break;

            case MATRIX_ELEMENT_COUNT.CORRECT:
              if (c.selected && c.initialValue) count++;
              break;

          }

        });

      });

    return count;

  }


  /**
   * It creates a new matrix of GridCell objects
   * @param {GridCell[][]} matrix - GridCell[][] - This is the matrix that we're going to be
   * reinitializing.
   * @param {number} [length=6] - number = 6
   * @returns A matrix of GridCell objects.
   */
  public reinitMatrix(matrix: GridCell[][], preserveInitialValue: boolean = false, length: number = 6) {

    let prevValue: GridCell[][] = [];

    if (preserveInitialValue) {

      prevValue = matrix.map(function (arr) {
        return arr.slice();
      });

    }

    for (let x = 0; x < length; x++) {

      matrix[x] = [];

      for (let y = 0; y < length; y++) {

        let newValue = new GridCell();
        newValue.initialValue = preserveInitialValue ? prevValue[x][y]?.initialValue : false;

        matrix[x][y] = newValue;

      }

    }

    return matrix;

  }

}

export enum MATRIX_ELEMENT_COUNT {

  /**
   * Used to count selected cells
   */
  SELECTED = "SELECTED",
  /**
   * Used to count correct selected cells
   */
  CORRECT = "CORRECT"

}
