import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Data } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericLabel, ModalButton} from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { GridDetail } from 'src/app/_core/classes/DTO/test-progress-game/memory-test-result';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { RimsService } from 'src/app/_core/services/rims.service';
import { LevelService } from '../level.service';
import { TEST_CYCLES, TRAINING_CYCLES } from '../memory-game.service';
import { GridCell, GridCellObjectValue } from './grid-cell';
import { MatrixService, MATRIX_ELEMENT_COUNT } from './matrix.service';


@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
  @Input() type: GAME = GAME.TRAINING_MODE;
  @Input() viewModel: any;
  isRims: boolean = false;

  @Output() startTimer: EventEmitter<number> = new EventEmitter<number>();

  matrix: GridCell[][] = [];
  gridDetail: GridDetail[] = [];
  titleDialog : GenericLabel = new GenericLabel('MAXCOINS2');
  buttonDialog : ModalButton = new ModalButton("CHIUDI", () => {}, true)

  labelsDialog: DialogClass = DialogClass.create(
    this.titleDialog,
    [
      this.buttonDialog,
    ]);

  constructor(private _level: LevelService, private _matrix: MatrixService, private RimsService: RimsService, public dialog: MatDialog, private translateService: TranslateService) {

    this.isRims = this.RimsService.checkRims();

  }

  ngOnInit(): void {
    this.viewModel.isTrainingMode = this.type === GAME.TRAINING_MODE;

    if (this.viewModel.isTrainingMode) {
      this.setNumberAndTimingDifficulty();
    }
  }

  ngAfterViewInit(): void {
    this.initMatrix(this.viewModel.isTrainingMode);
  }



  /* A private method that returns the count of selected elements in the matrix. */
  private getCountOfSelectedElementsInMatrix = () =>
    this._matrix.getMatrixCountByElementType(
      this.matrix,
      MATRIX_ELEMENT_COUNT.SELECTED
    );

  /**
   * "If the game is in medium mode, set the grid settings to the medium settings, otherwise if the
   * game is in easy mode, set the grid settings to the easy settings."
   */
  private setNumberAndTimingDifficulty() {
    if (this._level.getMode.medium()) {
      this.viewModel.GRID_SETTINGS = GridCellObjectValue.memory_medium_settings;
    } else if (this._level.getMode.easy()) {
      this.viewModel.GRID_SETTINGS = GridCellObjectValue.memory_easy_settings;
    }
  }

  /* A function that returns a random number between 2 and the number of selected elements in the matrix. */
  private randomizeNumberBySelectedElementsInMatrix(offset: number = 0) {
    let countNumber =
      Math.floor(Math.random() * 2 + offset) +
      this.getCountOfSelectedElementsInMatrix();

    if (countNumber > this.viewModel.GRID_SETTINGS.iconsCount) {
      countNumber = this.viewModel.GRID_SETTINGS.iconsCount;
    }

    return countNumber;
  }

  /**
   * "While the number of selected elements in the matrix is less than the random number, set a random
   * element in the matrix to true."
   *
   * The function is called with the following parameters:
   *
   * setElementsInGridByRandomValue(randomNumber: number, offsetX: number = 0, offsetY: number = 0,
   * gridLength: number = 3)
   *
   * The first parameter is the random number. The second and third parameters are the offset values.
   * The fourth parameter is the length of the grid.
   *
   * The function is called with the following parameters:
   *
   * setElementsInGridByRandomValue(randomNumber: number, offsetX: number = 0, offsetY: number = 0,
   * gridLength: number = 3)
   *
   * The first parameter is the random number. The second and third parameters are the offset values.
   * The fourth parameter is the length of the grid.
   * @param {number} randomNumber - the number of elements to be selected in the matrix
   * @param {number} [offsetX=0] - The x-coordinate of the top-left corner of the grid.
   * @param {number} [offsetY=0] - The offset of the Y axis.
   * @param {number} [gridLength=3] - number = 3
   */
  private setElementsInGridByRandomValue(
    randomNumber: number,
    offsetX: number = 0,
    offsetY: number = 0,
    gridLength: number = 3
  ) {
    while (this.getCountOfSelectedElementsInMatrix() < randomNumber) {
      let x = Math.floor(Math.random() * (gridLength + offsetX));
      let y = Math.floor(Math.random() * (gridLength + offsetY));
      this.matrix[x][y] = new GridCell(true);
    }
  }

  /**
   * This function sets the difficulty of the game by setting the number of elements in the grid that
   * are already selected.
   * @param cycles - Array<{ x: number, y: number }>
   */
  private setDifficultyCycles(cycles: Array<{ x: number; y: number }>) {
    let offset: number = 1;

    if (this._level.getMode.easy()) {
      offset = 0;
    }

    cycles.forEach((e: { x: number; y: number }) => {
      const randomNumber =
        this.randomizeNumberBySelectedElementsInMatrix(offset);
      this.setElementsInGridByRandomValue(randomNumber, e.x, e.y);
    });
  }

  /**
   * This function is used to initialize the matrix, and it has a parameter called trainingMode which
   * is a boolean and it's default value is false. If trainingMode is true, then the function will set
   * the difficulty cycles to TRAINING_CYCLES, and it will set the elements in the grid by random value
   * to the GRID_SETTINGS.iconsCount, 3, 3, and 6. If trainingMode is false, then the function will set the
   * difficulty cycles to TEST_CYCLES.
   * @param {boolean} [trainingMode=false] - boolean = false
   */
  initMatrix(trainingMode: boolean = false) {
    this.matrix = this._matrix.reinitMatrix(this.matrix);

    if (trainingMode) {
      this.setDifficultyCycles(TRAINING_CYCLES);
      this.setElementsInGridByRandomValue(
        this.viewModel.GRID_SETTINGS.iconsCount,
        3,
        3
      ); // to fill empty values

      this.startTimer.emit(this.viewModel.GRID_SETTINGS.timer);
      //this.viewModel.hiddenbar = false;
    } else {
      TEST_CYCLES.forEach(
        (e: { x: number; y: number }) =>
          (this.matrix[e.x][e.y] = new GridCell(true))
      );
      this.startTimer.emit(this.viewModel.GRID_SETTINGS.timer);
    }
  }

  /**
   * If the game is finished and the cell is selected, return the color red if the cell is not an
   * initial value, otherwise return the color green
   * @param {GridCell} cell - GridCell - this is the cell that is being rendered.
   * @returns A string.
   */
  getCircleClass(cell: GridCell): string {
    if (this.viewModel.gameFinished && cell.selected) {
      return !cell.initialValue ? 'red' : 'green';
    }

    if (this.isRims) {
      return 'blue';

    }


    return '';
  }

  /**
   * If the user has selected 10 coins, then the user can't select any more coins. If the user has
   * selected 9 coins, then the user can select one more coin. If the user has selected 10 coins and
   * then deselects one coin, then the user can select one more coin.
   * @param {GridCell} col - GridCell - the cell that was clicked
   * @returns the number of selected elements in the matrix.
   */
  selectTile(col: GridCell) {
    let currentSelectedCellsCount = this.getCountOfSelectedElementsInMatrix();


    if (this.viewModel.readyToPlay) {

      col.clicked = true;
      let dataClick = new Date()
      this.returnOneClick(col, dataClick);
      col.clicked = false;

      if (col.selected) {
        this.viewModel.missingCellsCount--;
      } else if (
        this.getCountOfSelectedElementsInMatrix() <
        this.viewModel.GRID_SETTINGS.iconsCount
      ) {
        this.viewModel.missingCellsCount++;
      }

      if (this.viewModel.readyToPlay) {
        if (
          currentSelectedCellsCount ===
          this.viewModel.GRID_SETTINGS.iconsCount &&
          !col.selected
        ) {

          this.openDialog();
          return;
        }

        if (
          (currentSelectedCellsCount ===
            this.viewModel.GRID_SETTINGS.iconsCount + 1 &&
            col.selected) ||
          currentSelectedCellsCount <= this.viewModel.GRID_SETTINGS.iconsCount
        ) {
          col.selected = !col.selected;
        }

        if (
          currentSelectedCellsCount ===
          this.viewModel.GRID_SETTINGS.iconsCount + 1
        ) {
          this.viewModel.disableButtonsUntilFinishGame = col.selected;
        } else if (
          currentSelectedCellsCount ===
          this.viewModel.GRID_SETTINGS.iconsCount - 1 &&
          col.selected
        ) {
          this.viewModel.disableButtonsUntilFinishGame = false;
        } else {
          this.viewModel.disableButtonsUntilFinishGame = true;
        }
      }
      if (currentSelectedCellsCount ===
        this.viewModel.GRID_SETTINGS.iconsCount - 1) {
        this.viewModel.endGridDetail = this.getFinalGrid(col);

      }
    }




  }

  /**
   * If the game is not finished and the cell is selected, then return a check icon if the cell's
   * initial value is true, otherwise return a close icon. Otherwise, return a circle icon.
   * @param {GridCell} col - GridCell - the cell that is being checked
   * @returns a string.
   */
  checkIconOnFinishedTraining(col: GridCell) {
    if (this.viewModel.gameFinished && col.selected) {
      return col.initialValue ? 'check' : 'close';
    }

    return 'circle';
  }

  getFinalGrid(col: GridCell): GridDetail[] {

    let finalGrid: GridDetail[] = [];

    for (let i = 0; i < this.matrix.length; i++) {

      for (let y = 0; y < this.matrix.length; y++) {

        if (this.matrix[i][y].selected == col.selected) {

          let gridpos = new GridDetail(i, y);
          finalGrid.push(gridpos);

        }

      }

    }

    return finalGrid;


  }

  returnOneClick(col: GridCell, dataClick: Data) {


    for (let i = 0; i < this.matrix.length; i++) {

      for (let y = 0; y < this.matrix.length; y++) {

        if (this.matrix[i][y].clicked == col.clicked && col.clicked == true) {

          this.viewModel.provaGridDetail.push(new GridDetail(i, y, dataClick));

        }
      }

    }


    // this.viewModel.allGridDetail[this.viewModel.allGridDetail.length] = this.gridDetail;
    // this.gridDetail = [];

  }

  openDialog() {

    this.labelsDialog.numberCoin = this.viewModel.GRID_SETTINGS.iconsCount;
    this.dialog.open(GenericModalComponent, {
      data: this.labelsDialog,
    });
  }

}
