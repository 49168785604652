import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GettingStartedModule} from './pages/getting-started/getting-started.module';
import {LoginModule} from './pages/login/login.module';
import {ProfileModule} from './pages/profile/profile.module';

import {YourResultModule} from './pages/your-result/your-result.module';
import {TestsModule} from './tests/tests.module';
import {CtAuthenticationGuard} from "@ctsolution/ct-authentication";
import { RegistrationModule } from './pages/registration/registration/registration.module';
import { TestSelectionModule } from './tests/test-type/test-mode/test-selection/test-selection.module';
import { TestResultComponent } from './pages/your-result/test-result/test-result/test-result.component';
import { TutorialLoginModule } from './pages/tutorial-login/tutorial-login.module';
import { FormResetComponent } from './pages/login/form-reset/form-reset.component';

const routes: Routes = [
  {
    path: '',
     canActivate: [CtAuthenticationGuard],
    children: [
      {
        path: '',
        loadChildren: () => GettingStartedModule
      },
      {
        path: 'profile',
        loadChildren: () => ProfileModule
      },
      {
        path: 'results',
        loadChildren: () => YourResultModule
      },
      {
        path: 'mode',
        loadChildren: () => TestsModule
      },
      {
        path: 'getting-started',
        loadChildren: () => GettingStartedModule
      },
      {
        path: 'tutorial',
        loadChildren: () => TutorialLoginModule
      },
      {
        path: 'test',
        loadChildren: () => TestSelectionModule
      },
      {
        path: 'test-result/:test_type',
        component: TestResultComponent
      }

     ],




  },
  {
    path: 'registration',
    loadChildren: () => RegistrationModule
  },
  {
    path:'reset',
    component : FormResetComponent
  },
  {
    path: '',
    children: [
      {
        path: 'login',
        loadChildren: () => LoginModule
      },
    ],
  },
  // {
  //   path: '**',
  //   redirectTo: 'login',
  // }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
