import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatrixOption, Tile } from 'src/app/_core/classes/DTO/test-progress-game/match-number-result';

@Component({
  selector: 'app-match-number-grid',
  templateUrl: './match-number-grid.component.html',
  styleUrls: ['./match-number-grid.component.scss']
})
export class MatchNumberGridComponent implements OnInit {

  @Input () matrix : Tile[] = [];
  @Input () matrixOption?: MatrixOption;
  @Output() selectNumber: EventEmitter<number> = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  getNumberToFill(num? : number){

    this.selectNumber.emit(num);

  }

}





