import { Component, Input, OnInit } from '@angular/core';
import { GenericLabel } from '../../generic-card/generic-card.component';

@Component({
  selector: 'app-test-score-card',
  templateUrl: './test-score-card.component.html',
  styleUrls: ['./test-score-card.component.scss'],
})
export class TestScoreCardComponent implements OnInit {
  @Input() obj!: IScore;

  constructor() {}

  ngOnInit(): void {}
}
export interface IScore {
  image?: GenericLabel;
  title: GenericLabel;
  subtitle?: GenericLabel;
  num? : number;
}
