import { Injectable } from '@angular/core';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {


  constructor (private _auth: CtAuthenticationService){}

  async getCompletionStatusClaims() : Promise<number>{

    await this._auth.getClaims();
    await this._auth.claimsUploaded();
    return this._auth.getJwtValueByContainedKey("CompletionStatus");

  }


  async getEnabledTest() : Promise<Array<number>>{

    await this._auth.getClaims();
    await this._auth.claimsUploaded();
    let result : string | null = this._auth.getJwtValueByContainedKey("TestsEnabled");

    if(result){

      return result.split(",").map(r => Number(r));

    }

    return [];

  }

  async getLanguage() : Promise<string | null>{

    await this._auth.claimsUploaded();
    return this._auth.getJwtValueByContainedKey("Language");

  }



}

