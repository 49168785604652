
export class MatchNumberResult {


  private _correct?: number | undefined;

  private _endMatrixDetail?: Array<number> | undefined;

  private _actionHistoryMatrix?: Array<actionHistoryTile> | undefined;

  private _correctMatrix?: Array<number> | undefined;

  private constructor(

    correct: number,
    endMatrixDetail: Array<number>,
    correctMatrix: Array<number>,
    actionHistoryMatrix : Array<actionHistoryTile>

  )
  {


    this.correct = correct;
    this.endMatrixDetail = endMatrixDetail;
    this.correctMatrix = correctMatrix;
    this.actionHistoryMatrix = actionHistoryMatrix;


  }

  public static create(correct : number, endMatrixDetail : Array<number>, correctMatrix : Array<number>, actionHistoryMatrix : Array<actionHistoryTile>) : MatchNumberResult{


    return new MatchNumberResult(correct,endMatrixDetail,correctMatrix,actionHistoryMatrix);

  }



  public get correct(): number | undefined {
    return this._correct;
  }
  private set correct(value: number | undefined) {
    this._correct = value;
  }
  public get endMatrixDetail(): Array<number>  | undefined {
    return this._endMatrixDetail;
  }
  private set endMatrixDetail(value:Array<number> | undefined) {
    this._endMatrixDetail = value;
  }
  public get actionHistoryMatrix(): Array<actionHistoryTile> | undefined {
    return this._actionHistoryMatrix;
  }
  private set actionHistoryMatrix(value:Array<actionHistoryTile>  | undefined) {
    this._actionHistoryMatrix = value;
  }
  public get correctMatrix(): Array<number> | undefined {
    return this._correctMatrix;
  }
  private set correctMatrix(value:Array<number>  | undefined) {
    this._correctMatrix = value;
  }

}



export interface Tile {
  color: string;
  cols: number;
  rows: number;
  index?: number;
  text?: string;
  icon?: string;
  selected?: boolean;
  number?: number;
  dataclick?: Date;
}
export class MatrixOption {
  cols : string | undefined;
  rowHeight : string | undefined;
  isText : boolean | undefined


  private constructor(cols : string, rowHeight : string, isText? : boolean){

    this.cols = cols;
    this.rowHeight = rowHeight;
    this.isText = isText;

  }

  public static create(cols : string, rowHeight : string, isText? : boolean):MatrixOption{

    return new MatrixOption(cols, rowHeight,isText);

  }





}
export class actionHistoryTile{

  number : number | undefined;
  index : number | undefined;
  timestamp : Date | undefined;

  private constructor (number : number,index : number){

    this.number = number;
    this.index = index;
    this.timestamp = new Date();

  }

  public static create(number : number, index : number){

    return new actionHistoryTile(number,index);

  }

}
