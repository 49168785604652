import { GenericLabel, ModalButton } from "src/app/_components/generic-card/generic-card.component";

export class DialogClass {

  private _title!: GenericLabel;
  public get title(): GenericLabel {
    return this._title;
  }
  public set title(value: GenericLabel) {
    this._title = value;
  }

  private _description?: GenericLabel | undefined;
  public get description(): GenericLabel | undefined {
    return this._description;
  }
  public set description(value: GenericLabel | undefined) {
    this._description = value;
  }
  private _numberCoin?: number | undefined;
  public get numberCoin(): number | undefined {
    return this._numberCoin;
  }
  public set numberCoin(value: number | undefined) {
    this._numberCoin = value;
  }

  private _button!: Array<ModalButton>;
  public get button(): Array<ModalButton> {
    return this._button;
  }
  private set button(value: Array<ModalButton>) {
    this._button = value;
  }

  private constructor(title: GenericLabel, button: Array<ModalButton>, description?: GenericLabel,) {
    this.title = title;
    this.button = button;
  }

  public static create(title : GenericLabel, button : Array<ModalButton>):DialogClass{

    return new DialogClass(title, button);

  }

  public setDescription(description : GenericLabel):DialogClass{
    this.description = description;
    return this;
  }

}
