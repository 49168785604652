
<app-spinner></app-spinner>

<mat-sidenav-container class="sidenav-container" [hasBackdrop]="true">

  <mat-sidenav position="end" mode="over" #drawer>

    <app-menu></app-menu>

  </mat-sidenav>

  <mat-sidenav-content>

    <app-header></app-header>

    <div class="container padding-menu">

      <router-outlet></router-outlet>

    </div>

  </mat-sidenav-content>

</mat-sidenav-container>

