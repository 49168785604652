import { Injectable } from '@angular/core';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';

@Injectable({
  providedIn: 'root'
})
export class RimsService {

  code? : string;

constructor(private _auth: CtAuthenticationService) { }


getCode(){

  if(this.code){
    return this.code
  }
  return '';

}

setCode(codeIn : string){

this.code = codeIn;

}

checkRims(){
  let email: string = this._auth.getJwtValueByContainedKey('emailaddress',false);
  if(email.includes("rims"))
    return true;

  return false;
}


}
