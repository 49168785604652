import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestDescriptionCardComponent } from './test-description-card.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule
  ],
  exports:[TestDescriptionCardComponent],
  declarations: [TestDescriptionCardComponent]
})
export class TestDescriptionCardModule { }
