
<ng-container *ngFor="let test of testValueObject">
  <div>
<mat-card class="container test-description-cntr mb-4 mt-2" (click)="navigateTo(test.Type)">

  <div class="img-cntr">
    <img src={{test.Image}}>
  </div>
  <div class="txt-cntr">
    <h1 class="title">{{test.Name | translate}}</h1>
  </div>

</mat-card>
  </div>
</ng-container>
