<div class="generic-dialog-cntr container">

  <ng-container *ngIf="numberCoin;else emptyNumberCoin">

    <h1 mat-dialog-title class="title d-flex justify-content-center" [ngClass]="data.title.cssClass ?? ''" *ngIf="data.title">{{data.title.label |
      translate: {numberCoin} }}</h1>

  </ng-container>

  <ng-template #emptyNumberCoin>

    <h1 mat-dialog-title class="title d-flex justify-content-center" [ngClass]="data.title.cssClass ?? ''" *ngIf="data.title">{{data.title.label |
      translate }}</h1>

  </ng-template>

  <ng-container *ngIf="data.description">

    <h2 mat-dialog-title class="subtitle" [ngClass]="data.description.cssClass ?? ''">

      {{ data.description.label | translate }}

    </h2>

  </ng-container>

  <mat-dialog-actions class="d-flex justify-content-between">

    <ng-container *ngFor="let item of data.button">

      <button mat-raised-button [mat-dialog-close]="item.modalClose" color="primary" class="dialog-button titleButton"
        [ngClass]="item.cssClass ?? ''" (click)="item.action()">{{item.label|translate}}</button>

    </ng-container>

  </mat-dialog-actions>

</div>
