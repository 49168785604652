import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, Event as RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Input() disabled: boolean | undefined;
  previousPage: string ="";

  constructor(
    private _location: Location,
    private router: Router,
  ) {


  }

  ngOnInit(): void {
  }

  backClicked() {
    // this._location.back();

    const url = this._location.path();

    if(url.endsWith('mode') || url.endsWith('results') || url.endsWith('profile') || url.endsWith('game') || url.endsWith('test') ){

      this.router.navigate(['getting-started']);
      return;

    }
    if(url.endsWith('game/memory')){

      this.router.navigate(['/mode/autonomous-mode/game']);
      return;

    }
    if(url.endsWith('test/memory')){

      this.router.navigate(['/mode/autonomous-mode/test']);
      return;
    }

      this._location.back();


  }
}
