import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginController } from 'src/app/_core/controllers/login.controller';
import { PasswordStrengthValidator } from 'src/app/_core/services/password-strength.validators';

@Component({
  selector: 'app-form-reset',
  templateUrl: './form-reset.component.html',
  styleUrls: ['./form-reset.component.scss']
})
export class FormResetComponent implements OnInit {

  form: UntypedFormGroup;
  token: string | undefined;
  hide : boolean = true;
  hideConf : boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,

    private loginController: LoginController,
    private matSnackbar: MatSnackBar,
    private translate : TranslateService,
    private route : Router

  ) {

    this.form = this.fb.group({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('',[Validators.required,Validators.minLength(6), PasswordStrengthValidator]),
      ConfermaPassword: new UntypedFormControl("",[Validators.required]),

    });

    this.activatedRoute
    .queryParams.subscribe(
    p => {

      if (p['t']) {
        this.token = p['t'];
      }

      if (p['e']) {
        this.form.get('email')?.setValue(p['e']);
      }

    });

   }

  ngOnInit() {
  }

  onSubmit(){

    if (this.form.valid && this.token) {

      this.loginController
        .put(this.form.get('email')?.value, this.token, this.form.get('password')?.value)
        .then((data: any) => {

          this.matSnackbar.open(this.translate.instant('PASSWORDRECOVERY'), 'X', {
            duration: 3000,
            panelClass: 'general-snackbar-panel',
          });

          this.route.navigate(['/login'], {queryParams : {InApp:true}})


        });

    }

  }

  get passwordNotEqual() {

    const formValue = this.form.value;

    return this.form.touched && !(formValue.password === formValue.ConfermaPassword);

  }

}
