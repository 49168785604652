import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { IconaProfiloModule } from '../getting-started/_components/IconaProfilo/IconaProfilo.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {ProfileFormModule} from "./profile-form/profile-form.module";
import {  MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    MatInputModule,
    IconaProfiloModule,
    TranslateModule,
    ProfileFormModule,
    MatButtonModule,
    RouterModule.forChild([
      { path: '', component: ProfileComponent, data: { disableExit: true } }
    ])
  ],
})
export class ProfileModule { }
