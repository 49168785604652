import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GamesResultModule } from './games-result/games-result.module';
import { GamesRoutes } from './games.routing';
import { TestIntroModule } from './test-intro/test-intro.module';

@NgModule({
  imports: [CommonModule, TestIntroModule, GamesResultModule, RouterModule.forChild(GamesRoutes)],
})
export class GamesModule { }
