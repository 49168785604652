import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotsIstructionsComponent } from './dots-istructions.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [DotsIstructionsComponent],
  exports:[DotsIstructionsComponent]
})
export class DotsIstructionsModule { }
