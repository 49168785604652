<mat-card class="scheda-result-cntr mb-3">

  <mat-card-header>
    <div class="d-flex" style="    width: 95%;
    justify-content: space-between;
    align-items: center;">
    <div mat-card-avatar class="scheda-result-img"></div>
    <div>
    <mat-card-subtitle class="subtitle scheda-result-subtitle">{{"DATACREAZIONE"|translate}}:<br><span style="font-weight: bolder;">{{ dataTest }}</span>
    </mat-card-subtitle>
  </div>
  <div>
   <mat-card-subtitle *ngIf="Result" class ="subtitle scheda-result-subtitle"><span style="font-weight: bolder; color: orange;">{{Points}}<br>{{"PUNTEGGIO"|translate}}</span></mat-card-subtitle>
  </div>
</div>
  </mat-card-header>

</mat-card>
