<ng-container *ngIf="!obj.disabled else ngTemplateDisabled">

  <mat-card class="generic-scheda-cntr" (click)="obj.button.action()" >

    <mat-card-header>

      <div mat-card-avatar [ngClass]="obj.class?.label ?? ''"></div>

      <mat-card-title class="title text-left" *ngIf="obj.title.label && obj.title.label !== ''"
        [ngClass]="obj.title.cssClass ?? ''">
        {{obj.title.label | translate}}</mat-card-title>

      <mat-card-subtitle class="subtitle" [ngClass]="obj.subtitle.cssClass ?? ''"
        *ngIf="obj.subtitle && obj.subtitle.label !== ''" [innerHTML]="obj.subtitle.label |translate">
      </mat-card-subtitle>

    </mat-card-header>

    <mat-card-actions>

      <button mat-raised-button [disabled]="obj.disabled" color="primary" class="scheda-button titleButton"
        >

        {{obj.button.label |translate}}

      </button>

    </mat-card-actions>

  </mat-card>

</ng-container>



<ng-template #ngTemplateDisabled>

  <mat-card class="generic-scheda-cntr">

    <mat-card-header>

      <div mat-card-avatar [ngClass]="obj.class?.label ?? ''"></div>

      <mat-card-title class="title text-left grey-text" *ngIf="obj.title.label && obj.title.label !== ''"
        [ngClass]="obj.title.cssClass ?? ''">
        {{obj.title.label | translate}}</mat-card-title>

      <mat-card-subtitle class="subtitle" [ngClass]="obj.subtitle.cssClass ?? ''"
        *ngIf="obj.subtitle && obj.subtitle.label !== ''" [innerHTML]="obj.subtitle.label |translate">
      </mat-card-subtitle>

    </mat-card-header>

    <mat-card-actions>

      <button mat-raised-button [disabled]="obj.disabled" color="primary" class="scheda-button titleButton"
        >

        {{obj.button.label |translate}}

      </button>

    </mat-card-actions>

  </mat-card>

</ng-template>
