import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-legend',
  templateUrl: './grid-legend.component.html',
  styleUrls: ['./grid-legend.component.scss'],
})
export class GridLegendComponent implements OnInit {

  legend: Array<ILegendRow> = [
    { label: 'R&PPOSINIZIALE', cssClass: '', icon: 'circle' },
    { label: 'R&PPOSCORRETTA', cssClass: 'green', icon: 'check' },
    { label: 'R&PPOSSBAGLIATA', cssClass: 'red', icon: 'close' }
  ];

  constructor() { }

  ngOnInit(): void { }

}

export interface ILegendRow { label: string, cssClass: string, icon: string }
