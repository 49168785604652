import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DrawerService} from '../drawer.service';
import {RouterService} from '../router.service';
import { CtAuthenticationService} from "@ctsolution/ct-authentication";
import { ClaimsService } from 'src/app/_core/services/claims.service';
import { LocalStorageService } from 'src/app/_core/services/localStorage.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {


  toggleNavBar: boolean = false;
  option: any;
  elem: any;

//   @HostListener('document:click', ['$event']) onDocumentClick() {
//     if(this.drawerService.getDrawer()){
//      this.drawerService.closeDrawer();
//    }
//  }


  constructor(
    private drawerService: DrawerService,
    private routerService: RouterService,
    private _translate: TranslateService,
    public _auth : CtAuthenticationService,
    private localStorageService : LocalStorageService
  ) {

  }


  async ngOnInit(): Promise<void> {

    let defaultLang = this.langs.find(e => e.value === this._translate.currentLang);

    let nextLang = this.langs.find(e => e.value === this.localStorageService.getLanguage());

    this.elem = document.documentElement;

    if (nextLang) {

      defaultLang = nextLang;

    }

    this.option = defaultLang ?? this.langs[0];

    this.changeLang({value: this.option})

  }



  closeDrawer() {
    this.drawerService.toggle();


  }

  navigateTo(path: string) {

    this.routerService.navigateTo(path);
  }


  logout() {

    this._auth.signOut();
    this.closeDrawer();

  }

  changeLang(evnt: any) {

    this._translate.setDefaultLang(evnt.value.value)
    this._translate.use(evnt.value.value)
    this.localStorageService.setLanguage(evnt.value.value)


  }

  langs = [
    {label: 'ITA', value: 'it'},
    {label: 'EN', value: 'en'}
  ];

  openLangSelect() {

    if (this.toggleNavBar) {

      this.toggleNavBar = true;

    }

  }

}
