<div class="container actions-cntr">

  <ng-container *ngIf="viewModel.isTrainingMode; else isTestModeTemplateAfterGrid">

    <ng-container *ngIf="viewModel.readyToPlay">
      <div class="actions-button">
        <button mat-raised-button color="primary" class="titleButton start-button generic-button " [hidden]="viewModel.showTrainingResult"
          [disabled]="viewModel.disableButtonsUntilFinishGame" (click)="endGame.emit()">{{'FINEALLENAMENTOBUTTON'
          | translate}}</button>
      </div>
    </ng-container>

    <ng-container *ngIf="viewModel.showTrainingResult">

      <ng-container *ngIf="viewModel.isTrainingMode">

        <ng-container *ngIf="viewModel.showTrainingResult">
          <div class="title result-txt" style="text-align: center;" [hidden]="viewModel.readyToPlay">
            {{'R&PINFOALLENAMENTO1' | translate}} {{viewModel.result}} / {{viewModel.GRID_SETTINGS.iconsCount}}
            {{'R&PINFOALLENAMENTO2' | translate}}
          </div>
        </ng-container>

      </ng-container>


      <app-grid-legend></app-grid-legend>

      <div class="actions-button">

        <button mat-raised-button color="primary" class="titleButton start-button " (click)="startTimerBetweenTests.emit()">
          {{'REALLENAMENTOBUTTON' | translate}} </button>

        <button mat-raised-button color="primary" class="titleButton start-button " (click)="navigateToHome()">
          {{'BACKDIFFICULT' | translate}} </button>

      </div>

    </ng-container>

  </ng-container>

  <ng-template #isTestModeTemplateAfterGrid>

    <div class="actions-button">

      <ng-container *ngIf="viewModel.hiddenButtonPreNewTestOrTraining">

        <ng-container *ngIf="viewModel.testCounter<3">

          <button mat-raised-button color="primary" class="titleButton start-button "
            [disabled]="viewModel.disableButtonsUntilFinishGame" [hidden]="!viewModel.readyToPlay"
            (click)="startTimerBetweenTests.emit()">{{'NEXTBUTTON' | translate}}</button>

          <div class="subtitle mb-5" [hidden]="!viewModel.readyToPlay" [innerText]="'R&PAVANTIDESCRIPTION'|translate: {testCounter: viewModel.testCounter + 1}"></div>

        </ng-container>

        <ng-container *ngIf="viewModel.testCounter==3">

          <button mat-raised-button color="primary" class="titleButton start-button " [hidden]="!viewModel.readyToPlay"
            [disabled]="viewModel.disableButtonsUntilFinishGame" (click)="endGame.emit()">{{'FINETESTBUTTON' |
            translate}}</button>

        </ng-container>

      </ng-container>

    </div>

  </ng-template>

</div>
