export class GRID_SETTINGS {
  /**
   * used to set the number of selected icons to show in grid
   */
  private _iconsCount!: number;
  public get iconsCount(): number {
    return this._iconsCount;
  }
  public set iconsCount(value: number) {
    this._iconsCount = value;
  }
  /**
   * timing in seconds to complete game
   */
  private _timer!: number;
  public get timer(): number {
    return this._timer;
  }
  public set timer(value: number) {
    this._timer = value;
  }
  private constructor(iconsCount:number, timer:number){

    this.iconsCount = iconsCount;
    this.timer = timer;

  }
   static create(iconsCount:number, timer:number):GRID_SETTINGS{

    return new GRID_SETTINGS(iconsCount,timer)

  }
}

export class GridCellObjectValue {

  public static memory_default_settings :GRID_SETTINGS = GRID_SETTINGS.create(10,10000)
  public static memory_medium_settings: GRID_SETTINGS = GRID_SETTINGS.create(8,10000)
  public static memory_easy_settings:GRID_SETTINGS = GRID_SETTINGS.create(5,15000)


  list(): Array<GRID_SETTINGS> {
    return [
      GridCellObjectValue.memory_default_settings,
      GridCellObjectValue.memory_medium_settings,
      GridCellObjectValue.memory_easy_settings,
    ];
  }
}
export class GridCell {


  initialValue: boolean = false;
  clicked : boolean = false;

  constructor(public selected: boolean = false) {
    this.initialValue = selected;

  }


}
