import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerService } from './drawer.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {

  constructor(private drawerService: DrawerService, private router: Router) {}

  navigateTo(path: string) {

    this.router.navigate([path]);
    this.drawerService.closeDrawer();

  }

}
