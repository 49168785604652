import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericCardModule } from 'src/app/_components/generic-card/generic-card.module';
import { TestTypeComponent } from './test-type.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    GenericCardModule,
    TranslateModule,
    RouterModule.forChild([
      {
        path: '', component: TestTypeComponent,
        data: { disableBack: false },
      }
    ])
  ],
  declarations: [TestTypeComponent],
})
export class TestTypeModule { }
