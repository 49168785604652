import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QuitTestService {

  quitTest = false;

  constructor() {}

  setQuitTest(quit : boolean) {

    this.quitTest = quit;

  }

  getQuitTest(): boolean {

    return this.quitTest;

  }

}
