import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  viewModel: any = {
    handleBackRoute: false,
    handleQuitButton: true,
    handleExitRoute: true,
    disableMenu: false
  };

  constructor(private router: Router, private route: ActivatedRoute, public  _auth : CtAuthenticationService) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary')
      )
      .subscribe((route: ActivatedRoute) => {
        route.data.subscribe((d) => {
          this.viewModel.handleBackRoute = !d['disableBack'];
          this.viewModel.handleQuitButton = d['disableQuit'];
          this.viewModel.handleExitRoute = d['disableExit'];

          this.viewModel.disableMenu = d['disableMenu'];

        });
      });
  }

  ngOnInit(): void {}

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
