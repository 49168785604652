import { Component, OnInit, OnDestroy } from '@angular/core';
import { Tile, MatrixOption } from 'src/app/_core/classes/DTO/test-progress-game/match-number-result';

declare var require : any;
@Component({
  selector: 'app-secondIstructionsMatch',
  templateUrl: './secondIstructionsMatch.component.html',
  styleUrls: ['./secondIstructionsMatch.component.scss']
})
export class SecondIstructionsMatchComponent implements OnInit, OnDestroy {

  legends: Tile[] = require('../../../../../../../assets/match-number-json/legendsMatchNumber.json');
  legedsOption: MatrixOption = MatrixOption.create('9', '25px', true);
  qwerty: Tile[] = require('../../../../../../../assets/match-number-json/qwertyMatchNumber.json');
  qwertyOption: MatrixOption = MatrixOption.create('3', '30px');
  matrixTutorial: Array<Tile>[] = [];
  matrixEmptyTutorial: Array<Tile>[] = require('../../../../../../../assets/match-number-json/matrixTutorialEmptyMatchNumber.json');
  matrixCompleteTutorial: Array<Tile>[] = require('../../../../../../../assets/match-number-json/matrixTutorialMatchNumber.json');

  intervals : Array<any> = [];

  playVideo : boolean = true;


  constructor() {

  }

  ngOnInit() {

    this.playVideo = true;
    this.createDinamicVideo();

  }

  ngOnDestroy(){

    this.playVideo = false;
    this.resetVideo();
    this.clearIntervals();

  }


  createDinamicVideo(){

   this.intervals.push( setTimeout(() => {
    this. matrixCompleteTutorial[0][0].selected = true;

      this.intervals.push( setTimeout(() => {
        this.legends[2].selected = true;
        this.legends[11].selected = true;

      },2000));
      this.intervals.push( setTimeout(() => {
        this.qwerty[2].selected = true;

      },3000));
      this.intervals.push( setTimeout(() => {

        this.matrixEmptyTutorial[0][0].number = 3;
        this.matrixEmptyTutorial[0][0].selected = true;

      },4000));

    }, 2000));

    this.intervals.push( setTimeout(() => {

      this.qwerty[2].selected = false;
      this. matrixCompleteTutorial[0][1].selected = true;

      this.intervals.push( setTimeout(() => {
        this.legends[3].selected = true;
        this.legends[12].selected = true;

      },2000));
      this.intervals.push( setTimeout(() => {
        this.qwerty[3].selected = true;

      },3000));
      this.intervals.push( setTimeout(() => {
        this.matrixEmptyTutorial[0][1].number = 4;
        this.matrixEmptyTutorial[0][1].selected = true;

      },4000));
      this.intervals.push( setTimeout(() => {

        this.resetVideo();
      },6000));
    }, 8000));



  }

  resetVideo(){


      this.legends[2].selected = false;
      this.legends[11].selected = false;
      this.qwerty[2].selected = false;
      this.matrixEmptyTutorial[0][0].number = undefined;
      this.matrixEmptyTutorial[0][0].selected = false;
      this.matrixCompleteTutorial[0][0].selected = false;
      this.qwerty[2].selected = false;
      this.legends[3].selected = false;
      this.legends[12].selected = false;
      this.qwerty[3].selected = false
      this.matrixEmptyTutorial[0][1].number = undefined;
      this.matrixEmptyTutorial[0][1].selected = false;
      this.matrixCompleteTutorial[0][1].selected = false;

      if(this.playVideo){

      this.createDinamicVideo();

      }

    }

    clearIntervals(){

      this.intervals.forEach(element => {

        clearTimeout(element);

      });

    }

}
