<div class="container">

  <mat-card class="istructions-cntr">



      <div class="title mt-4">{{'A_ITITLE'|translate}}</div>



    <mat-card-content class="mat-card-istruction-content-cntr">

      <h2 class="subtitle mb-4">{{'A_IFIRSTISTRUCTIONDESCRIPTION'|translate}}</h2>


      <app-match-number-grid [matrix]="legends" [matrixOption]="legedsOption"></app-match-number-grid>



    </mat-card-content>


    <div class="dots-istructions-width">
      <app-dots-istructions [value]="1"></app-dots-istructions>
    </div>

  </mat-card>


</div>
