import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestResultComponent } from './test-result.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { ResultCardModule } from 'src/app/_components/result-card/result-card.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    TranslateModule,
    ResultCardModule,
    MatCardModule,
    MatButtonModule
  ],
  exports:[TestResultComponent],
  declarations: [TestResultComponent]
})
export class TestResultModule { }
