import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { QuitTestService } from 'src/app/layout/header/quit-test.service';
import { DGCTestStateEnum, DGCTestTypeEnum, SaveProgress } from 'src/app/_core/classes/DTO/test-progress-game/save-progress';
import { TestController } from 'src/app/_core/controllers/test.controller';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { MicrophoneService } from 'src/app/_core/services/microphone.service';
import { VerbalMemorySaveprogressService } from 'src/app/_core/services/verbal-memory-saveprogress.service';
import { TempResultService } from '../temp-result.service';

@Component({
  selector: 'app-verbal-memory-game',
  templateUrl: './verbal-memory-game.component.html',
  styleUrls: ['./verbal-memory-game.component.scss'],
})
export class VerbalMemoryGameComponent implements OnInit {

  audio = new Audio();
  isStart = true;
  isRepeat = false;
  disabledButton = false;
  numTest = 1;
  isRecorder = true;
  isHidden = true;
  isEndTest = false;
  gameType: GAME =  GAME.TRAINING_MODE;

  timeout : any;

  saveProgress?: SaveProgress;

  constructor(
    private router: Router,
    public _tempResultService: TempResultService,
    private route: ActivatedRoute,
    private verbalTestController : TestController,
    private quitTestService : QuitTestService,
    private saveProgressService : VerbalMemorySaveprogressService,
    private microphoneService : MicrophoneService

  ) {

    this.route.data.subscribe((gameTypeData : Data) => {
      if (gameTypeData) {
        this.gameType = gameTypeData['type'] as GAME;
      }
    });

    this.saveProgress = this.saveProgressService.getSaveProgress();

  }

  ngOnInit(): void {



  }
  ngOnDestroy() {
    this.stopAudio();

    if(this.quitTestService.getQuitTest() && this.gameType == GAME.TEST_MODE){

      this.saveProgressWhenQuitTest();
      this.quitTestService.setQuitTest(false);

    }
    clearTimeout(this.timeout);

    this.microphoneService.reset();

  }

  playAudio() {
    this.audio.src = '/assets/Audio/15parole.mp3';
    this.audio.load();

    this.audio.play();
    this.disabledButton = true;
    this.isHidden = false;
    this.timeout = setTimeout(() => {
      this.isStart = false;
      this.isRepeat = true;
      this._tempResultService.verbalTestPlus();
      this._tempResultService.removeVocalRecord();
      this.navigateTo();
    }, 37000);
  }

  stopAudio() {
    this.audio.pause();
  }

  recorder() {
    this.isRecorder = false;
  }

  endRecorder() {
    this.isRecorder = true;
  }

  navigateTo() {
    this.router.navigate(['record'], { relativeTo: this.route });
  }

  saveProgressWhenQuitTest() {

    if(!this.saveProgress){
      return;
    }

       this.verbalTestController
        .putFormData(this.saveProgress.Quitted());
    }

  }





