<div class="mat-card-cntr">

<mat-card class="difficult-card"    [ngClass]="{'background-color': obj.selected}" (click)="selectedBack()">

  <img mat-card-image src="{{obj.img.label}}" [ngClass]="obj.img.cssClass ?? ''" class="difficult-img"   >
  <mat-card-content>
    <div [ngClass]="obj.title.cssClass ?? ''" class="subtitle">
      {{obj.title.label | translate}}
    </div>
  </mat-card-content>

</mat-card>
</div>
