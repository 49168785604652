
<div class="result-background"></div>

<div class="container text-center d-flex result-cntr">

  <div class="row">
  <div class="col-3">
    <img src={{testInfo.Image}}>
  </div>
  <div class="col-6 col-md-9" style="align-self: center;">
    <h1 class="title">{{testInfo.Name | translate}}</h1>
  </div>
</div>
  <div class="d-flex justify-content-around mt-4">

    <mat-form-field appearance="fill" style="width: 40%; font-weight: bold; ">
       <mat-label>{{"PUNTEGGIO"|translate}}</mat-label>
       <mat-select  (selectionChange)="filterByAscending($event.value)">
         <mat-option *ngFor="let ordine of ordini" [value]="ordine">
           {{ordine|translate}}
         </mat-option>
       </mat-select>
     </mat-form-field>


     <mat-form-field appearance="fill" style="width: 40%; font-weight: bolder;" >
        <mat-label>{{"DATATEST"|translate}}</mat-label>
        <mat-select  (selectionChange)="filterByData($event.value)">
          <mat-option *ngFor="let anno of anni" [value]="anno">
            {{anno}}
          </mat-option>
        </mat-select>
      </mat-form-field>

 </div>

<div class="mt-3">

<ng-container *ngIf="testObject && resultAvaiable ; else noTestTemplate" >
   <div class="text-result-cntr">
 <ng-container *ngFor="let result of resultList">
<app-result-card [result]="result" [type]="testObject.Type"></app-result-card>
</ng-container>
</div>
</ng-container>

<ng-template #noTestTemplate>

    <mat-card class="container noTest-card-cntr mb-4">

      <div class="col-12 mb-4">
        <img src="../../../../../assets/images/result/punto_esclamativo.svg">
      </div>
      <div class="col-12 text-center">
        <h1  class="subtitle" [innerHTML]="NoTestLabel|translate" ></h1>
      </div>

    </mat-card>

    <button *ngIf="filter.Year == year" mat-raised-button  color="primary" class="generic-button title mt-4 mb-4" (click)="navigateTo()"> {{"GOTEST"|translate}}</button>

</ng-template>

</div>

</div>
