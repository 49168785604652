import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericLabel } from 'src/app/_components/generic-card/generic-card.component';
import { LEVEL } from '../../memory-game/level.service';

@Component({
  selector: 'app-difficult-card',
  templateUrl: './difficult-card.component.html',
  styleUrls: ['./difficult-card.component.scss']
})
export class DifficultCardComponent implements OnInit {

  @Input() obj!: IDifficult;
  @Output() newItemEvent = new EventEmitter<LEVEL>();



  constructor( ) {

  }

  ngOnInit() {
  }



  selectedBack() {

    this.newItemEvent.emit(this.obj.difficult);


  }

}

export interface IDifficult{

  title : GenericLabel;
  img : GenericLabel;
  selected : boolean;
  difficult : LEVEL;

}

// export class IGenericAction extends GenericLabel {
//   public action: () => any;
//   constructor (action: () => any,public label: string, public cssClass?: string) {
//       super(label, cssClass);
//       this.action = action;
//   }


