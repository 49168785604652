import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';
import { QuitTestService } from 'src/app/layout/header/quit-test.service';
import { GenericLabel, ModalButton } from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import {
  actionHistoryTile,
  MatchNumberResult,
  MatrixOption,
  Tile
} from 'src/app/_core/classes/DTO/test-progress-game/match-number-result';
import {
  DGCTestStateEnum,
  DGCTestTypeEnum,
  SaveProgress
} from 'src/app/_core/classes/DTO/test-progress-game/save-progress';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import { TestController } from 'src/app/_core/controllers/test.controller';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { TimerTestService } from '../../_components/timer-test.service';
import { TempResultService } from '../temp-result.service';

declare var require: any;
@Component({
  selector: 'app-match-numbers',
  templateUrl: './match-numbers.component.html',
  styleUrls: ['./match-numbers.component.scss'],
})
export class MatchNumbersComponent implements OnInit, OnDestroy {

  arrowRightLong = faArrowRightLong;

  posMatrix: number = 0;
  posModify: boolean = false;
  rowModify: number = 0;
  gameDuration: number = 90000;
  createAppDateTime?: Date;
  actionHistoryMatrix: Array<actionHistoryTile> = [];
  currentTimingInSecond: number = 0;
  saveProgress?: SaveProgress;

  userFinishBefore90sec = false;

  matrix: Array<Tile>[] = [];
  index: number = 0;
  matchNumberTestResult: Array<MatchNumberResult> = [];
  legends: Tile[] = require('../../../../assets/match-number-json/legendsMatchNumber.json');
  legedsOption: MatrixOption = MatrixOption.create('9', '50px', true);
  qwerty: Tile[] = require('../../../../assets/match-number-json/qwertyMatchNumber.json');
  qwertyOption: MatrixOption = MatrixOption.create('3', '70px');
  matrixComplete: Array<Tile>[] = require('../../../../assets/match-number-json/matrixMatchNumber.json');
  matrixEmpty: Array<Tile>[] = require('../../../../assets/match-number-json/matrixEmptyMatchNumber.json');

  type?: GAME;
  disabledButton : boolean = false;

  isTutorial: boolean = true;
  isTraining: boolean = true;
  matrixTutorial: Array<Tile>[] = [];
  matrixEmptyTutorial: Array<Tile>[] = require('../../../../assets/match-number-json/matrixTutorialEmptyMatchNumber.json');
  matrixCompleteTutorial: Array<Tile>[] = require('../../../../assets/match-number-json/matrixTutorialMatchNumber.json');


  labelsDialog: DialogClass = DialogClass.create(
    new GenericLabel('A_IMODALTITLE'),
    [
      new ModalButton('BACKBUTTON', () => {
      }, false),
      new ModalButton(
        'NEXTBUTTON2',
        () => {
           this.exitTutorial();
        },
        true
      ),

    ]
  );



  constructor(
    private matchNumberTestController: TestController,
    private _timerService: TimerTestService,
    private route: Router,
    private router: ActivatedRoute,
    private _result : TempResultService,
    private dialog : MatDialog,
    private quitTest : QuitTestService,

  ) {

    this.router.data.subscribe((d) => {
      if (d) {
        this.type = d['type'] as GAME;
      }
      if(this.type == GAME.TEST_MODE){
        this.saveProgress = SaveProgress.Create(DGCTestTypeEnum.MATCH);
        this.isTraining = false;
      }

    });



  }

  ngOnInit() {
    this.matrix = JSON.parse(JSON.stringify(this.matrixEmpty));
    this.matrixTutorial = JSON.parse(JSON.stringify(this.matrixEmptyTutorial));
  }
  ngOnDestroy(): void {

    this.userFinishBefore90sec = true;
    if(this.quitTest.getQuitTest() && !this.isTraining)
    {
      this.saveTestProgress(false);
      this.quitTest.setQuitTest(false);
    }
  }

  exitTutorial() {
    this._timerService.open();
     setTimeout(() => {
      this.isTutorial = false;
      this.startTimer(this.gameDuration);
    }, 6000);
  }

  openDialog() {
    this.labelsDialog.description = new GenericLabel('A_IFINETUTORIAL');
    this.dialog.open(GenericModalComponent, {
      data: this.labelsDialog,
    });
  }

  startTimer(ms: number) {
    let seconds = ms / 100;

    const timer$ = interval(100);

    const sub = timer$.subscribe((sec: number) => {
      this.currentTimingInSecond = sec;

      if(this.currentTimingInSecond == 300){
        let matrixCorrectNumber: Array<number> = this.reduceMatrix(
          this.matrixComplete
        );
        let matrixUserNumber: Array<number> = this.reduceMatrix(this.matrix);
        let correct = this.getCorrectResult(matrixCorrectNumber, matrixUserNumber);
        this.matchNumberTestResult?.push(  MatchNumberResult.create(
          correct,
          matrixUserNumber,
          matrixCorrectNumber,
          this.actionHistoryMatrix
        ));

      }
      if(this.currentTimingInSecond == 600){
        let matrixCorrectNumber: Array<number> = this.reduceMatrix(
          this.matrixComplete
        );
        let matrixUserNumber: Array<number> = this.reduceMatrix(this.matrix);
        let correct = this.getCorrectResult(matrixCorrectNumber, matrixUserNumber);
        this.matchNumberTestResult?.push(  MatchNumberResult.create(
          correct,
          matrixUserNumber,
          matrixCorrectNumber,
          this.actionHistoryMatrix
        ));

      }

      if (this.currentTimingInSecond === seconds && !this.userFinishBefore90sec) {
        sub.unsubscribe();
        this.concludeTest();
      }
    });
  }

  selectNumber(num: number) {
    let provamatrix = this.matrix;

    if (this.isTutorial) {
      provamatrix = this.matrixTutorial;
    }
    this.index = this.posMatrix;

    if (
      this.getPosMatrix() < provamatrix[this.index].length &&
      this.getPosMatrix() != -1
    ) {
      this.pushActionHistory(this.index, num, this.getPosMatrix());
      provamatrix[this.index][this.getPosMatrix()].number = num;
      this.posModify = false;
      provamatrix[this.index][this.rowModify].selected = false;
    }
  }

  getPosMatrix(): number {
    let provamatrix = this.matrix;

    if (this.isTutorial) {
      provamatrix = this.matrixTutorial;
    }
    if (this.posModify) {
      return this.rowModify;
    } else {
      return (
        provamatrix[this.index].find((matrix) => matrix.number == null)
          ?.index ?? -1
      );
    }
  }

  pushActionHistory(index: number, num: number, posMatrix: number) {
    if (index > 0) {
      index = index * 4;
    }

    this.actionHistoryMatrix.push(
      actionHistoryTile.create(num, index + posMatrix)
    );
  }

  nextMatrix() {
    this.disabledButton = true;
    if (this.posMatrix <= this.matrixComplete.length) {
      this.posMatrix++;
    }
    setTimeout(() => {
      this.disabledButton = false;
    }, 2000);

  }
  getPosition(row: number, matrix: Array<Tile>[], index: number) {
    if (row != -1) {
      if (this.posModify) {
        matrix[index][this.rowModify].selected = false;
      }
      this.posModify = true;
      this.rowModify = row;
      matrix[index][row].selected = true;
    }
  }

  concludeTest() {
    this.userFinishBefore90sec = true;
    this.saveTestProgress();
    this.route.navigate(['../../result'], { relativeTo: this.router });
  }

  getCorrectResult(
    userMatrix: Array<number>,
    correctMatrix: Array<number>
  ): number {
    if (!userMatrix || !correctMatrix) {
      return 0;
    }

    return correctMatrix.filter((num, i) => num == userMatrix[i]).length;
  }

  reduceMatrix(matrix: Array<Tile>[]): Array<number> {
    let numberArray: Array<number> = [];
    if (!matrix) {
      return numberArray;
    }
    const reduceMatrix = matrix.reduce((previousValue, currentValue) => [
      ...previousValue,
      ...currentValue,
    ]);
    reduceMatrix.forEach((element) => {
      if (element.number) {
        numberArray.push(element.number);
      } else {
        numberArray.push(-1);
      }
    });
    return numberArray;
  }

  saveTestProgress(completedTest : boolean = true){

    if(!this.saveProgress){
      return
    }

      if(completedTest){
      this.setMatchNumberTestResult();
      this.saveProgress.Completed(JSON.stringify(this.matchNumberTestResult),DGCTestStateEnum.Completed)
      .setExecutionSecondsSet(Math.round(
             this.currentTimingInSecond / 10
      ));
      }
      else{
        this.saveProgress.Quitted();
      }
      this.matchNumberTestController
      .putFormData(this.saveProgress);
  }

  setMatchNumberTestResult(){

    let matrixCorrectNumber: Array<number> = this.reduceMatrix(
      this.matrixComplete
    );

   let matrixUserNumber: Array<number> = this.reduceMatrix(this.matrix);

   let correct = this.getCorrectResult(matrixCorrectNumber, matrixUserNumber);

   this._result.setMatchNumberResult(correct);

   this.matchNumberTestResult.push(  MatchNumberResult.create(
    correct,
    matrixUserNumber,
    matrixCorrectNumber,
    this.actionHistoryMatrix
  ));

  }

}
