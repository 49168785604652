<div class="menu-cntr">

  <div class="container pt-2">

    <div class="text-right ">

      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon> close </mat-icon>
      </button>

    </div>

    <div class="menu-buttons ">
      <ng-container *ngIf="_auth.userValue" >


      <button mat-flat-button class="text-left" color="primary" (click)="navigateTo('/profile')"> {{'PROFILE'|translate}}
      </button>
      <!-- <button mat-flat-button class="text-left mb-2" color="primary" (click)="navigateTo('/results')"> {{'RESULT'|translate}} </button> -->
      <button mat-flat-button class="text-left mb-2" color="primary" (click)="navigateTo('/tutorial')"> {{'TUTORIAL'|translate}} </button>

      <!-- <button mat-flat-button class="text-left" color="primary" (click)="logout()"> Esci </button> -->
    </ng-container>

        <div class="d-flex langs-cntr mb-2" (click)="openLangSelect()">
          <mat-select (selectionChange)="changeLang($event)" [(ngModel)]="option"  panelClass="language-select-cntr">

            <mat-select-trigger> {{ option.label }}</mat-select-trigger>
            <mat-option *ngFor="let lang of langs" [value]="lang"> {{ lang.label }}</mat-option>

          </mat-select>


        </div>



        <ng-container *ngIf="_auth.userValue" >

        <button mat-flat-button class="text-left" color="primary" (click)="logout()"> {{'LOGOUT'|translate}}
        </button>
        </ng-container>

        <div class="end-cntr">
        <div class="link"><a href="mailto:digicogms@aism.it?subject=Segnalazioni app DIGICOG-MS" target="_blank">{{'CONTACTUS' | translate}}</a> </div>
        <div class="link"><a href="https://sostienici.aism.it/dona-ora/?cm=0842_APPDG" target="_blank">{{'SUPPORT'|translate}}</a></div>
        <div class="privacy-cntr">
          <div class="privacy-text"><span>©COPYRIGHT FISM 2024 – <br> {{'COPYRIGHT'|translate}}<br>
            <a href="https://www.aism.it/informativa_sulla_privacy_sito_web_aism_fism" target="_blank">{{'PRIVACY'|translate}}</a></span></div>

            <div class="privacy-logo"><img src="\assets\images\logo\logo_fism.png" ></div>


        </div>

    </div>

  </div>

</div>
