import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { RouterModule } from '@angular/router';
import { ResultCardComponent } from './result-card.component';
import { TestScoreCardComponent } from './test-score-card/test-score-card.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatCardModule, RouterModule, TranslateModule],
  declarations: [ResultCardComponent, TestScoreCardComponent],
  exports: [ResultCardComponent],
})
export class ResultCardModule {}
