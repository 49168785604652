import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  readonly ConstantString ={

    language : "Language"

  }

  constructor (){}

  private getLocal = (key: string): any => localStorage.getItem(key);
  private setLocal = (key: string, value: any): any => localStorage.setItem(key, value);
  private removeLocal = (key: string): any => localStorage.removeItem(key);

  public setLanguage =(value : string) => this.setLocal(this.ConstantString.language, value);
  public getLanguage =(): string => this.getLocal(this.ConstantString.language);




}

