import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-card',
  templateUrl: './generic-card.component.html',
  styleUrls: ['./generic-card.component.scss'],
})
export class GenericCardComponent implements OnInit {
  @Input() obj!: ICard;

  constructor() {}

  ngOnInit(): void {}
}

export interface ICard {
  title: GenericLabel;
  subtitle?: GenericLabel;
  button: GenericButton;
  class?: GenericLabel;
  disabled?: boolean;
  type : number;
}

export class GenericLabel {
  constructor(public label: string, public cssClass?: string) {
  }
}

export class GenericButton extends GenericLabel {
  public action: () => any;
  constructor (public label: string, action: () => any, public cssClass?: string) {
      super(label, cssClass);
      this.action = action;
  }


}

export class ModalButton extends GenericButton{

  public modalClose! : boolean;

  constructor (public label: string, action: () => any, modalClose: boolean, public cssClass?: string) {
    super(label, action, cssClass);
    this.modalClose = modalClose;
  }
}

