import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { GenericModalModule } from 'src/app/_components/generic-modal/generic-modal.module';
import { RouterModule } from '@angular/router';
import { RegistrationController } from 'src/app/_core/controllers/registration.controller';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    GenericModalModule,
    TranslateModule,
    MatCheckboxModule,
    MatDialogModule,
    RouterModule.forChild([
      { path: '', component: RegistrationComponent, },
    ])
  ],
  declarations: [RegistrationComponent],
  exports : [RegistrationComponent],
  providers:[RegistrationController, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class RegistrationModule { }
