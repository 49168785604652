import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserController} from "../../../../_core/controllers/user.controller";

@Component({
  selector: 'app-IconaProfilo',
  templateUrl: './IconaProfilo.component.html',
  styleUrls: ['./IconaProfilo.component.scss'],
  providers: [UserController]
})
export class IconaProfiloComponent implements OnInit {

  backgroundImg: string = `/assets/images/logo/avatar.svg`;
  loading: boolean = false;

  constructor(private userController: UserController, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {

    this.getUserAvatar();

  }

  getUserAvatar() {

    this.loading = true;

    this.userController
      .getAvatar()
      .then((result: Blob) => {

        this.loading = false;

        if (result) {

          this.toBase64(result)
            .then((result: any) => {

              this.backgroundImg = result;
              this.cdr.detectChanges();

            });

        }

      })

  }

  uploadImg(event: any) {

    const file = event?.target?.files[0];

    if (file) {

      this.userController
        .setAvatar(file)
        .then(() => {

          setTimeout(() => this.getUserAvatar(), 0);
        })

    }

  }

  toBase64 = (file: File | Blob): Promise<any> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

}
