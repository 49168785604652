import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CtAuthenticationService, SignInParameter } from '@ctsolution/ct-authentication';
import { CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { Registration } from '../classes/Registration';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { DialogClass } from '../classes/modal-class';
import { GenericLabel, ModalButton } from 'src/app/_components/generic-card/generic-card.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationController {

constructor(  private ctWebApiService: CtWebapiService,private userController : CtAuthenticationService, private router : Router,private dialog: MatDialog) { }

private controller: string = '/user';






signup( registrationForm : Registration): Observable<any> {

  const request: DataRequest = new DataRequest(`${this.controller}/Registration`).setContentType('application/json-patch+json');
  const parameter: SignInParameter = new SignInParameter(registrationForm.Email, registrationForm.Password, true);

 return this.ctWebApiService.post(request, registrationForm.getJsonRequest());

}

navigateToHome = () => this.router.navigate(['getting-started']);



}
