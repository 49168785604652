import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { CtResetPasswordService } from '@ctsolution/ct-authentication';
import { LoginController } from 'src/app/_core/controllers/login.controller';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(

    private fb: UntypedFormBuilder,
    private loginController : LoginController

  ) {

    this.form = this.fb.group({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),

    });

  }

  ngOnInit() {
  }

resetPassword(){

  if (this.form.valid) {

    const linkUrl = window.location.origin + '/login/form-reset';

    this.loginController.forgotPassword(this.form.get('email')?.value, linkUrl);

  }

}

}
