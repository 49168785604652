import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dots-istructions',
  templateUrl: './dots-istructions.component.html',
  styleUrls: ['./dots-istructions.component.scss']
})
export class DotsIstructionsComponent implements OnInit {

  firstIstructions = '';
  secondIstructions = '';
  thirdIstructions = '';
  @Input() value? : number;

  constructor() { }

  ngOnInit() {

    if(this.value){

      switch (this.value){
      case 1: this.firstIstructions='activated'; break;
      case 2: this.secondIstructions='activated'; break;
      case 3: this.thirdIstructions='activated';

    }

  }

}
}
