import { ENV } from "./environment-type.enum";

export const environment = {
  production: false,
  type: ENV.TEST,
  API_URL: 'https://digicog.test.api.testctsolution.it/api',
  Project: {
    Name: 'DIGICOG-MS-TEST'
  },
  google: {
    tagManagerId: 'GTM-5MDBBM' // TAG DI CTSOLUTION: GTM-5HNP5KR
  }
};
