<div class="d-flex flex-column memory-game-cntr container padding-top">

  <div class="memory-game-title-cntr">

    <app-memory-game-title [viewModel]="viewModel"></app-memory-game-title>

  </div>

  <app-grid class="mb-2 mt-2" [type]="type" [viewModel]="viewModel" #grid (startTimer)="startTimer($event)"></app-grid>


    <div class="mat-progress-bar-cntr"  *ngIf="!viewModel.readyToPlay && !viewModel.showTrainingResult">

      <mat-progress-bar mode="determinate" [value]="viewModel.progressBarValue">
      </mat-progress-bar>

    </div>

  <div class="memory-game-actions-cntr">

  <app-memory-game-actions [viewModel]="viewModel" (startTimerBetweenTests)="startTimerBetweenTests()"
    (endGame)="endGame()">
  </app-memory-game-actions>

  </div>

</div>
