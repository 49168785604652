import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CtWebviewService} from '@ctsolution/ct-webview';
import * as RecordRTC from 'recordrtc';
import {interval} from 'rxjs';
import {QuitTestService} from 'src/app/layout/header/quit-test.service';
import {
  GenericLabel,
  ModalButton
} from 'src/app/_components/generic-card/generic-card.component';
import {GenericModalComponent} from 'src/app/_components/generic-modal/generic-modal.component';
import {
  DGCTestStateEnum,
  DGCTestTypeEnum,
  SaveProgress
} from 'src/app/_core/classes/DTO/test-progress-game/save-progress';
import {DialogClass} from 'src/app/_core/classes/modal-class';
import {RecordRTCOptions} from 'src/app/_core/classes/record-option-class';
import {GAME} from 'src/app/_core/enums/game-type.enum';
import {TestController} from '../../../_core/controllers/test.controller';
import {TempResultService} from '../temp-result.service';
import {MicrophoneService} from './../../../_core/services/microphone.service';

@Component({
  selector: 'app-generates-words',
  templateUrl: './generates-words.component.html',
  styleUrls: ['./generates-words.component.scss'],
})
export class GeneratesWordsComponent implements OnInit, OnDestroy {
  viewModel: any = {
    isTrainingMode: false,
    progressBarValue: 0,
    gameDuration: 90000, //in ms
    currentTimingInSecond: null,
    isRecording: false,
    disableAudioPlay: false,
    userStopBefore90Seconds: false,
  };

  saveProgress?: SaveProgress;

  audioGameResult: any;
  record: any;
  createAppDateTime?: Date;
  timeout: any;

  labelsDialog: DialogClass = DialogClass.create(
    new GenericLabel('QUITRECORD'),
    [
      new ModalButton('NO', () => {
      }, false),
      new ModalButton(
        'SI',
        () => {
          this.stopRecording(true);
        },
        true
      ),

    ]
  );

  constructor(
    private route: Router,
    public _tempResultService: TempResultService,
    public dialog: MatDialog,
    private router: ActivatedRoute,
    private _testController: TestController,
    private microphoneService: MicrophoneService,
    private quitTestService: QuitTestService,
    private ctWebViewService: CtWebviewService
  ) {
    this.router.data.subscribe((d) => {
      if (d) {
        this.viewModel.isTrainingMode =
          (d['type'] as GAME) === GAME.TRAINING_MODE;
      }
    });
    if (!this.viewModel.isTrainingMode) {
      this.saveProgress = SaveProgress.Create(DGCTestTypeEnum.GENERATE);
    }
  }

  ngOnInit() {
    this.initiateRecording();
    this.createAppDateTime = new Date();

    this.timeout = setTimeout(() => {
      this.stopRecording(false);
    }, this.viewModel.gameDuration);

  }

  ngOnDestroy(): void {

    if (!this.viewModel.isTrainingMode && this.quitTestService.getQuitTest() && this.saveProgress) {
      this._testController.putFormData(this.saveProgress.Quitted());
      clearTimeout(this.timeout);
    }
    this.stopMicrophone();

  }


  stopMicrophone(){

    if(this.record){
      this.record.microphone.stop();
      this.record.stop((blob: Blob) => this.setGameResult(blob));
      this.record = null;
      }
      this.microphoneService.reset();

  }

  /**
   * "When the user clicks the record button, the app will start recording audio and start a timer."
   *
   * The first thing we do is set the isRecording property to true. This will be used to show/hide the
   * record button and the stop button.
   *
   * Next, we create a mediaConstraints object. This object will be used to tell the browser what type
   * of media we want to record. In this case, we want to record audio only.
   *
   * Next, we call the getUserMedia() function. This function will ask the user for permission to
   * record audio. If the user grants permission, the successCallback() function will be called. If the
   * user denies permission, the errorCallback() function will be called.
   *
   * Finally, we call the startTimer() function. This function will start a timer that will count down
   * from the gameDuration property.
   */
  initiateRecording() {

    if (this.ctWebViewService.isCtWebView() && this.ctWebViewService.isAndroidDevice()) {

      this.ctWebViewService.AndroidFunctions.checkMicrophonePermissions();

    }

    this.microphoneService
      .checkMicrophonePermissions()
      .then((stream: MediaStream) => this.successCallback(stream))
      .catch((err: string) => console.error(err));

  }

  /**
   * It takes a number of milliseconds as an argument, converts it to seconds, and then uses the RxJS
   * interval operator to emit a value every 100 milliseconds.
   *
   * The value emitted is used to update the progress bar value and the current timing in seconds.
   *
   * The subscription is unsubscribed when the current timing in seconds is equal to the number of
   * seconds passed in as an argument
   * @param {number} ms - number - the number of milliseconds to count down from
   */
  startTimer(ms: number) {
    let seconds = ms / 100;

    const timer$ = interval(100);

    const sub = timer$.subscribe((sec: number) => {
      this.viewModel.progressBarValue = 1 + (sec * 100) / seconds;
      this.viewModel.currentTimingInSecond = sec;

      if (
        this.viewModel.currentTimingInSecond === seconds
      ) {

        sub.unsubscribe();

      }
    });
  }

  /**
   * The stopRecording() function is called when the user presses the stop button. It sets the
   * isRecording property to false, stops the recording, and hides the first screen.
   */
  stopRecording(stopBefore90Sec: boolean): any {

    this.viewModel.isRecording = false;
    this.viewModel.userStopBefore90Seconds = stopBefore90Sec;
    this.stopMicrophone();

  }


  /**
   * It records audio from the microphone.
   * @param {any} stream - The stream object returned by getUserMedia()
   */
  successCallback(stream: MediaStream) {

    this.viewModel.isRecording = true;

    let optionsRecord = RecordRTCOptions.create();
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, optionsRecord);
    this.record.record();
    // release microphone on stopRecording
    this.record.microphone = stream;

    this.startTimer(this.viewModel.gameDuration);

  }

  /**
   * A callback function that is called when an error occurs.
   * @param {any} error - The error object
   */
  errorCallback(error: any) {


    console.log('Can not play audio in your browser'); // TODO: gestire il messaggio l'errore
  }

  /**
   * Navigate to the result component, relative to the current route.
   */
  navigateToResult() {
    if (!this.quitTestService.getQuitTest()) {
      this.setSaveProgress();
    }
    this.route.navigate(['../result'], {relativeTo: this.router});
  }

  openDialog() {
    this.dialog.open(GenericModalComponent, {
      data: this.labelsDialog,
    });
  }


  setGameResult(blob: Blob) {

    this.audioGameResult = blob;
    this.navigateToResult();

  }


  setSaveProgress() {


    if (!this.saveProgress) {

      return

    }

 // TODO: Verificare come mai veniva chiamato
    // this.microphoneService.checkMicrophonePermissions();
    this.saveProgress.Completed('Nessun dato richiesto', DGCTestStateEnum.Completed);
    if (this.audioGameResult) {
      this.saveProgress.setFile(this.audioGameResult, 'Tentativo numero 1');
    }
    this._testController.putFormData(this.saveProgress);

  }

}
