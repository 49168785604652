import {Component, OnInit} from '@angular/core';
import {DGCTestStateEnum, SaveProgress} from 'src/app/_core/classes/DTO/test-progress-game/save-progress';
import {TestController} from "../../_core/controllers/test.controller";

@Component({
  selector: 'app-your-result',
  templateUrl: './your-result.component.html',
  styleUrls: ['./your-result.component.scss'],
})
export class YourResultComponent implements OnInit {

  resultList: SaveProgress[] = [];
  viewModel = {

    completedTestCounter: 0,
    quitTestCounter: 0

  }

  constructor() {
  }

  ngOnInit(): void {

  }



  countCompletedTest = () => this.filterByState(DGCTestStateEnum.Completed);

  countQuitTest = () => this.filterByState(DGCTestStateEnum.Quitted);

  private filterByState = (state: DGCTestStateEnum): number => this.resultList.filter((result: SaveProgress) => result._State === state).length;


}
