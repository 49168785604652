import { Injectable } from '@angular/core';
import { CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { DGCFilterTestDTO } from '../classes/DTO/DGCFilterTestDTO';
import { SaveProgress } from '../classes/DTO/test-progress-game/save-progress';


@Injectable()
export class TestController {

  private controller: string = '/test';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  putFormData(testResult: any): Promise<any> {

    const formData: FormData = new FormData();

    Object.keys(testResult)
      .forEach(key => formData.append(key, testResult[key]));

    if(formData.has('File')){
        const value: any = formData.get('File');
        formData.set('File', value, `DIGICOG-MS-${new Date().getTime()}.wav`) // da rinominare con tentativo1-timestamp.wav
    }else{
      formData.append('File', '');
    }

    const request: DataRequest = new DataRequest(`${this.controller}/SaveProgress`, { hideSpinner: true});

    return new Promise<any>((resolve) => {

      this.ctWebApiService
        .put(request, formData)
        .subscribe((result: any) => resolve(result));

    });

  }
  put(testResult: SaveProgress): Promise<any> {

    const request: DataRequest = new DataRequest(`${this.controller}/SaveProgress`, { hideSpinner: true});

    return new Promise<any>((resolve) => {

      this.ctWebApiService
        .put(request, testResult)
        .subscribe((result: any) => resolve(result));

    });

  }

  list(filter : DGCFilterTestDTO): Promise<any> {

    const request: DataRequest = new DataRequest(`${this.controller}/List`,filter);

    return new Promise<any>((resolve) => {

      this.ctWebApiService
        .get(request)
        .subscribe((result: any) => resolve(result));

    });

  }

  detail(numOid: any): Promise<any> {

    const request: DataRequest = new DataRequest(`${this.controller}/Detail`);

    return new Promise<any>((resolve) => {

      this.ctWebApiService
        .post(request, {Oid: numOid})
        .subscribe((result: any) => resolve(result));

    });

  }

}
