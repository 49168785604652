import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimerTestComponent } from './timer-test.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, RouterModule,TranslateModule],
  declarations: [TimerTestComponent],
  exports: [TimerTestComponent],
})
export class TimerTestModule {}
