import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerService } from 'src/app/layout/drawer.service';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { ClaimsService } from 'src/app/_core/services/claims.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
})
export class GettingStartedComponent implements OnInit {

  projectName: string = 'DIGICOG-MS';

  constructor(
    private drawerService: DrawerService,
    private router: Router,
    private _auth : CtAuthenticationService,
    private _claims : ClaimsService,
    private translate : TranslateService
  ) {

  }

 async ngOnInit() {

    let tutorial = await this._claims.getCompletionStatusClaims();
    if(tutorial == 1){
     this.router.navigate(['tutorial']);
    }


  

   }

  closeDrawer() {

    this.drawerService.toggle();

  }

  navigateTo(path: string) {

    this.router.navigate([path]);

  }

}
