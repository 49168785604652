import { Injectable } from '@angular/core';
import { TEST_TYPES } from 'src/app/_core/enums/test-types.enum';

@Injectable({
  providedIn: 'root',
})
export class TempResultService {
  counters = {
    executed: 0,
    quitted: 0,
    score: 0,
  };
  vocalRecord: { records: any[]; countRecord: number } = {
    records: [],
    countRecord: 0,
  };

  memoryTestResult: Array<TestResult> = [];
  numVerbalTest = 1;
  isTest = false;
  creationDataVerbalMemoryTest? : Date;
  matchNumberResult: number = 0;

  constructor() { }

/**
 * It takes a position, a score, and a test type, and then it creates a new test result object with the
 * score and test type, and then it checks if the memory test result array already has a test result
 * object with the same test type, and if it does, it replaces the score in the test result object with
 * the new score, and if it doesn't, it pushes the new test result object into the memory test result
 * array.
 * @param {number} posTest - number - the position of the test (1, 2, 3, 4, 5, 6, 7, 8, 9, 10)
 * @param {number} score - number = the score of the test
 * @param {TEST_TYPES} testType - TEST_TYPES = TEST_TYPES.MEMORY_TEST_1;
 * @returns The return value is the memoryTestResultElement.
 */
  setOneResult(posTest: number, score: number, testType: TEST_TYPES) {
    let testResult: TestResult = new TestResult([score], testType);

    let memoryTestResultElement: TestResult | undefined = this.memoryTestResult.find(memoryTest => memoryTest.testType == testType);

    if(memoryTestResultElement){
      memoryTestResultElement.scoreTest[posTest - 1] = testResult.scoreTest[0];
      return;
    }

    this.memoryTestResult.push(testResult);
  }

  resetOneType(testType: TEST_TYPES) {
    this.memoryTestResult = this.memoryTestResult.filter(
      (item) => item.testType !== testType
    );
  }

  getOneResult(posTest: number, testType: TEST_TYPES): number {
    let score = 0;
    let memoryTest = this.memoryTestResult.find((element) => element.testType == testType)
    if(memoryTest){
      score = memoryTest.scoreTest[posTest - 1];
      return score;
    }else{
      return 0;
    }
  }

  getTotatResult(testType : TEST_TYPES): number{

    let total = 0;
    let memoryTest = this.memoryTestResult.find((element) => element.testType == testType)
    if(memoryTest){

     memoryTest.scoreTest.forEach(element => {
      total = total + element;
     });

     return total;
    }else{
      return 0;
    }
  }

  setCreationDataVerbalMemoryGame(){

    this.creationDataVerbalMemoryTest = new Date();

  }
  getCreationDataVerbalMemoryGame(): Date | undefined {

    return this.creationDataVerbalMemoryTest;

  }


  gameEnded(score: number) {
    if (score > this.counters.score) {
      this.counters.score = score;
    }

    this.counters.executed++;
  }

  gameQuitted() {
    this.counters.quitted++;
  }

  testSet() {
    this.isTest = true;
  }
  resetTest() {
    this.isTest = false;
  }

  verbalTestPlus() {
    this.numVerbalTest++;
  }
  returnVerbalTestNum() {
    return this.numVerbalTest;
  }
  resetVerbalTest() {
    this.numVerbalTest = 0;
  }
  pushVocalRecord(item: any) {
    this.vocalRecord.records[this.vocalRecord.countRecord] = item;
    this.vocalRecord.countRecord++;
    console.log(this.vocalRecord);
  }
  removeVocalRecord() {
    this.vocalRecord.records = [];
    this.vocalRecord.countRecord = 0;
  }


  setMatchNumberResult(result : number){

    this.matchNumberResult = result;

  }

  getMatchNumberResult(): number{

    return this.matchNumberResult;

  }

}

export class TestResult {
  scoreTest: Array<number>;
  scoreTotal?: number;
  testType: TEST_TYPES;

  constructor(
    scoreTest: Array<number>,
    testType: TEST_TYPES,
    scoreTotal?: number
  ) {
    this.scoreTest = scoreTest;
    this.testType = testType;
    this.scoreTotal = scoreTotal;
  }



}
