import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExitButtonComponent } from './exit-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ExitButtonComponent],
  imports: [CommonModule,MatButtonModule,TranslateModule],
  exports:[ExitButtonComponent]
})
export class ExitButtonModule {}
