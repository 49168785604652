import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from './back-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BackButtonComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule,TranslateModule],
  exports: [BackButtonComponent],
})
export class BackButtonModule {}
