import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { BackButtonModule } from './_components/back-button/back-button.module';
import { LogoModule } from './_components/logo/logo.module';
import { HamburgerMenuModule } from './_components/hamburger-menu/hamburger-menu.module';
import { ExitButtonModule } from './_components/exit-button/exit-button.module';
import { QuitButtonModule } from './_components/quit-button/quit-button.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    BackButtonModule,
    LogoModule,
    HamburgerMenuModule,
    ExitButtonModule,
    QuitButtonModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
