export class diseaseForm {

  constructor(
    public id : number,
    public label : string,
  )
{}


}

export class DISEASEFORMObjectValue {

  public static zero : diseaseForm = new diseaseForm (0,'Sindrome clinicamente isolata');
  public static primo : diseaseForm = new diseaseForm (1,'Ricadute seguite da una ripresa completa o quasi completa (Recidivante-remittente)');
  public static secondo : diseaseForm = new diseaseForm (2,'Graduale peggioramento con ricadute (secondariamente progressiva)');
  public static terzo : diseaseForm = new diseaseForm (3,'Graduale peggioramento senza ricadute (Primariamente progressiva)');




  public static list = () => [this.zero,this.primo,this.secondo,this.terzo]

  constructor(){}

}
