import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TimerTestComponent } from './timer-test/timer-test.component';

@Injectable({
  providedIn: 'root',
})
export class TimerTestService {

  public duration: number = 6000;

  constructor(private dialog: MatDialog) { }

  open(): MatDialogRef<TimerTestComponent> {

    return this.dialog.open(TimerTestComponent, {
      panelClass: 'timer-dialog-cntr',
      backdropClass: 'white-overlay',
      disableClose: true,
      data: this.duration
    });

  }

}
