import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DifficultCardComponent } from './difficult-card.component';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    MatCardModule,TranslateModule
  ],
  declarations: [DifficultCardComponent],
  exports : [DifficultCardComponent]
})
export class DifficultCardModule { }
