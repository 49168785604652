import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialLoginSchedaComponent } from './tutorial-login-scheda.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,

  ],
  declarations: [TutorialLoginSchedaComponent],
  exports: [TutorialLoginSchedaComponent]
})
export class TutorialLoginSchedaModule { }
