import { ISaveProgressDTO } from "src/app/_core/interface/ISaveProgress";

export class SaveProgress {

  private CreateAppDateTime: String;
  private TestType: DGCTestTypeEnum;
  private Oid: number;
  private JsonData?: string;
  private UpdateAppDateTime?: String | undefined;
  private State?: DGCTestStateEnum;
  private CustomCode?: string | undefined;
  private RawPointResult?: number | undefined;
  private RemainingSeconds?: number | undefined;
  private ExecutionSecondsSet?: number | undefined;
  private File?: any;
  private FileCategory?: any;
  private ClinicPointResult?: number;


  constructor(
    testType : DGCTestTypeEnum,
  )
  {
    this.Oid = -1;
    let createData : Date = new Date();
    createData.setMilliseconds(0);
    this.CreateAppDateTime = createData.toISOString();
    this.TestType = testType;
  }

  public get _CreateAppDateTime(): String {
    return this.CreateAppDateTime;
  }
  public set _CreateAppDateTime(value: String) {
    this.CreateAppDateTime = value;
  }

  public get _State(): DGCTestStateEnum | undefined {
    return this.State;
  }
  public set _State(value: DGCTestStateEnum | undefined) {
    this.State = value;
  }

  public get _TestType(): DGCTestTypeEnum {
    return this.TestType;
  }
  public set _TestType(value: DGCTestTypeEnum) {
    this.TestType = value;
  }

  public get _Oid(): number {
    return this.Oid;
  }
  public set _Oid(value: number) {
    this.Oid = value;
  }

  public get _JsonData(): string | undefined {
    return this.JsonData;
  }
  public set _JsonData(value: string | undefined) {
    this.JsonData = value;
  }

  public get _UpdateAppDateTime(): String | undefined {
    return this.UpdateAppDateTime;
  }
  public set _UpdateAppDateTime(value: String | undefined) {
    this.UpdateAppDateTime = value;
  }

  public get _CustomCode(): string | undefined {
    return this.CustomCode;
  }
  public set _CustomCode(value: string | undefined) {
    this.CustomCode = value;
  }

  public get _RawPointResult(): number | undefined {
    return this.RawPointResult;
  }
  public set _RawPointResult(value: number | undefined) {
    this.RawPointResult = value;
  }

  public get _ClinicPointResult(): number | undefined {
    return this.ClinicPointResult;
  }
  public set _ClinicPointResult(value: number | undefined) {
    this.ClinicPointResult = value;
  }

  public get _RemainingSeconds(): number | undefined {
    return this.RemainingSeconds;
  }
  public set _RemainingSeconds(value: number | undefined) {
    this.RemainingSeconds = value;
  }

  public get _ExecutionSecondsSet(): number | undefined {
    return this.ExecutionSecondsSet;
  }
  public set _ExecutionSecondsSet(value: number | undefined) {
    this.ExecutionSecondsSet = value;
  }

  public get _File(): any {
    return this.File;
  }
  public set _File(value: any) {
    this.File = value;
  }

  public get _FileCategory(): any {
    return this.FileCategory;
  }
  public set _FileCategory(value: any) {
    this.FileCategory = value;
  }


  setFile(file : any, fileCategory? : any) : SaveProgress {
    this.File = file;
    if(fileCategory){
      this.FileCategory = fileCategory;
    }
    return this;
  }
  setCustomCode(customCode: string) : SaveProgress {
    this.CustomCode = customCode;
    return this;
  }
  setRawPointResult(rawPointResult: number) : SaveProgress {
    this.RawPointResult = rawPointResult;
    return this;
  }
  setClinicPointResult(clinicPointResult: number) : SaveProgress {
    this.ClinicPointResult = clinicPointResult;
    return this;
  }
  setRemainingSeconds(remainingSeconds: number) : SaveProgress {
    this.RemainingSeconds = remainingSeconds;
    return this;
  }
  setExecutionSecondsSet(executionSecondsSet: number) : SaveProgress {
    this.ExecutionSecondsSet = executionSecondsSet;
    return this;
  }

  public static Create(testType : DGCTestTypeEnum) : SaveProgress{

      return new SaveProgress(testType)

    }

  public static CreateByDTO(saveProgressDTO : ISaveProgressDTO) : SaveProgress{

    let saveProgress = new SaveProgress (saveProgressDTO.TestType);

    saveProgress.CreateAppDateTime = saveProgressDTO.CreateAppDateTime;
    saveProgress.Oid = saveProgressDTO.Oid;
    saveProgress.RawPointResult = saveProgressDTO.RawPointResult;
    saveProgress.ExecutionSecondsSet = saveProgressDTO.ExecutionSecondsSet;
    saveProgress.ClinicPointResult = saveProgressDTO.ClinicPointResult;
    return saveProgress

  }

  Quitted() : SaveProgress {

    this._JsonData = 'Test abbandonato';
    this._State = DGCTestStateEnum.Quitted;
    let updateAppDateTime  = new Date();
    updateAppDateTime.setMilliseconds(0);
    this.UpdateAppDateTime = updateAppDateTime.toISOString();

    return this;

  }

  Completed(jsonData : string,stateTest : DGCTestStateEnum): SaveProgress {

    this._JsonData = jsonData;
    let updateAppDateTime  = new Date();
    updateAppDateTime.setMilliseconds(0);
    this.UpdateAppDateTime = updateAppDateTime.toISOString();
    this.State = stateTest;
    return this;

  }

}

export enum DGCTestStateEnum {
  Executing = 0,
  Completed = 1,
  Quitted = 2,
}

export enum DGCTestTypeEnum {
  MEMORIA = 0,
  VERBAL = 1,
  GENERATE = 2,
  MATCH = 3

}



