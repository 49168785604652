import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { GamesResultComponent } from '../games-result/games-result.component';
import { GeneratesWordsComponent } from './generates-words.component';

import { TEST_TYPES } from 'src/app/_core/enums/test-types.enum';
import { TestIntroComponent } from '../test-intro/test-intro.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { GenericModalModule } from 'src/app/_components/generic-modal/generic-modal.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TestController } from 'src/app/_core/controllers/test.controller';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    TranslateModule,
    MatDialogModule,
    GenericModalModule,
    RouterModule.forChild([
      {
        path: 'training',
        component: GeneratesWordsComponent,
        data: { type: GAME.TRAINING_MODE },
      },
      {
        path: 'test',
        component: GeneratesWordsComponent,
        data: { type: GAME.TEST_MODE, disableBack: true, disableQuit: true },
      },
      {
        path: 'result',
        component: GamesResultComponent,
        data: { type: TEST_TYPES.GENERATES_WORDS, disableBack: true, disableQuit: false },
      },
      {
        path: '',
        component: TestIntroComponent,
        data: { type: TEST_TYPES.GENERATES_WORDS, disableBack: false, disableQuit: false },
      },
    ]),
  ],
  declarations: [GeneratesWordsComponent],
  exports: [GeneratesWordsComponent],
  providers :[TestController]
})
export class GeneratesWordsModule { }
