<div class="verbal-cntr">

  <div class="title verbal-title-repeat">{{"A&RRECTITLE"|translate}}</div>

  <div class="subtitle verbal-subtitle-repeat">{{"A&RRECSUBTITLE"|translate}} </div>

  <img class="verbal-img mt-3" src="/assets/images/verbal-game/ripeti.svg">

  <div [hidden]="hiddenRecordingButton">

    <div class="subtitle verbal-subtitle-margin">{{"A&RSTARTRECORDER"|translate}} </div>

    <button id="start-record-btn" title="Start Recording" mat-raised-button color="primary"
      class="verbal-button titleButton" (click)="recordTest()">{{"A&RRECORDER"|translate}} <img
        class="verbal-icon-width" src="assets/images/verbal-game/mic.svg"></button>

  </div>

  <div [hidden]="!hiddenRecordingButton || !hiddenStopButton">

    <div class="subtitle verbal-subtitle-margin">{{"A&RSTOPRECORDER"|translate}}</div>

    <button id="pause-record-btn" title="Pause Recording" mat-raised-button color="primary"
      class="verbal-button titleButton" (click)="pause()">{{"A&RSTOP"|translate}} <img class="verbal-icon-width"
        src="assets/images/verbal-game/mic-red.svg"></button>

  </div>

  <ng-container *ngIf="attemptNumberForTranslate < 6 && !isTrainingMode ; else goToResultTemplate">

    <div [hidden]="hiddenStopButton" class="verbal-subtitle-margin">

      <button mat-raised-button color="primary" class="verbal-button titleButton"
        (click)="navigateTo()">{{'A&RRECBACK'|translate: { attemptNumberForTranslate } }}</button>

    </div>

  </ng-container>

  <ng-template #goToResultTemplate>

    <div [hidden]="hiddenStopButton" class="verbal-subtitle-margin">
      <button mat-raised-button color="primary" class="verbal-button titleButton"
        (click)="navigateTo()">{{'G_PRESULT'|translate }}</button>

    </div>

  </ng-template>

</div>
