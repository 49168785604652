import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Recorder_componentComponent } from './recorder_component.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    MatDialogModule
  ],
  declarations: [Recorder_componentComponent],
  exports:[Recorder_componentComponent]
})
export class Recorder_componentModule { }
