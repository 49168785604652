import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TutorialLoginComponent } from './tutorial-login.component';
import {CarouselModule } from '@coreui/angular';
import { RouterModule } from '@angular/router';
import { TutorialLoginSchedaModule } from './tutorial-login-scheda/tutorial-login-scheda.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    TutorialLoginSchedaModule,
    MatButtonModule,
    TranslateModule,
     RouterModule.forChild([
      { path: '', component: TutorialLoginComponent, },
    ])
  ],
  declarations: [TutorialLoginComponent],
  exports: [TutorialLoginComponent],
})
export class TutorialLoginModule {}
