import { DGCTestTypeEnum } from "./test-progress-game/save-progress";

export class DGCFilterTestDTO
{
     TestType? : DGCTestTypeEnum;
     Year? : number;
     ResultAscending? : boolean;

     constructor(testType? : DGCTestTypeEnum,year? : number,resultAscending? : boolean){

        this.ResultAscending = resultAscending;
        this.TestType = testType;
        this.Year = year;

     }
}