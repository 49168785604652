import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericCardModule } from 'src/app/_components/generic-card/generic-card.module';
import { TestModeComponent } from './test-mode.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    TestModeComponent
  ],
  imports: [
    CommonModule,
    GenericCardModule,
    TranslateModule,
    RouterModule.forChild([
      { path: '', component: TestModeComponent, data: { disableBack: false } }
    ])
  ]
})
export class TestModeModule { }
