<div class="timer-cntr">
  <div class="timer-container">

    <span>3</span>
    <span>2</span>
    <span>1</span>
    <span>{{'TIMERSTART'|translate}}</span>

  </div>
</div>
