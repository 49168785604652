import { Injectable } from '@angular/core';
import { DGCTestTypeEnum, SaveProgress } from '../classes/DTO/test-progress-game/save-progress';
import { VerbalMemoryGameInfo } from '../classes/DTO/test-progress-game/verbal-memory-game-info';

@Injectable({
  providedIn: 'root'
})
export class VerbalMemorySaveprogressService {

saveProgressService?: SaveProgress;
attemptString : Array<VerbalMemoryGameInfo> = [];

constructor() {


}

createSaveProgress(){

  this.saveProgressService = SaveProgress.Create(DGCTestTypeEnum.VERBAL);

}

getSaveProgress() : SaveProgress | undefined{

  return this.saveProgressService;

}

pushVerbalMemoryGameInfo(data : VerbalMemoryGameInfo){

  this.attemptString.push(data);

}

getVerbalMemoryGameInfo(): Array<VerbalMemoryGameInfo>{

  return this.attemptString;

}

resetAttemptString(){

  this.attemptString = [];

}


}
