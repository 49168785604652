import { Component, Input, OnInit } from '@angular/core';
import { DrawerService } from 'src/app/layout/drawer.service';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
})
export class HamburgerMenuComponent implements OnInit {

  @Input() disabled: boolean = false;

  constructor(private drawerService: DrawerService) {}

  ngOnInit(): void {}

  toggleDrawer() {
    this.drawerService.toggle();
  }
}
