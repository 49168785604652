import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchNumbersComponent } from './match-numbers.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TestController } from 'src/app/_core/controllers/test.controller';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { TEST_TYPES } from 'src/app/_core/enums/test-types.enum';
import { GamesResultComponent } from '../games-result/games-result.component';
import { TestIntroComponent } from '../test-intro/test-intro.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatchNumberGridModule } from './match-number-grid/match-number-grid.module';
import { InstructionsModule } from '../test-intro/instructions/instructions.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    MatGridListModule,
    MatCardModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatchNumberGridModule,
    FontAwesomeModule,

    RouterModule.forChild([
      {
        path: 'training',
        data: { type: GAME.TRAINING_MODE },
        children: [
          {
            path: '',
            component: MatchNumbersComponent,
          },
        ],
      },
      {
        path: 'instructions',
        data: { type: GAME.TEST_MODE, testType : TEST_TYPES.MATCH_NUMBERS,  disableBack: false,
          disableQuit: false, },
        loadChildren: () => InstructionsModule,
      },
      {
        path: 'instructions-training',
        data: { type: GAME.TRAINING_MODE, testType : TEST_TYPES.MATCH_NUMBERS,  disableBack: false,
          disableQuit: false, },
        loadChildren: () => InstructionsModule,
      },
      {
        path: 'test',
        data: { type: GAME.TEST_MODE },
        children: [
          {
            path: '',
            component: MatchNumbersComponent,
          },
        ],
      },
      {
        path: 'result',
        component: GamesResultComponent,
        data: {
          type: TEST_TYPES.MATCH_NUMBERS,
          disableBack: true,
          disableQuit: false,
        },
      },
      {
        path: '',
        component: TestIntroComponent,
        data: {
          type: TEST_TYPES.MATCH_NUMBERS,
          disableBack: false,
          disableQuit: false,
        },
      },
    ]),
  ],
  declarations: [MatchNumbersComponent],
  exports: [MatchNumbersComponent],
  providers: [TestController],
})
export class MatchNumbersModule {}
