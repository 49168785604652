export class Education {

  constructor(
    public id : number,
    public label : string,
  )
{}


}

export class EducationObjectValue {

  public static primaria : Education = new Education (0,'Scuola primaria ');
  public static secondaria1grado : Education = new Education (1,'Scuola secondaria di primo grado ');
  public static terziaria : Education = new Education (2,'Scuola secondaria di secondo grado');
  public static università : Education = new Education (3,'Università');
  public static dottorato : Education = new Education (4,'Dottorato di ricerca o scuola di specializzazione');


  public static list = () => [this.primaria,this.secondaria1grado,this.terziaria,this.università,this.dottorato]

  constructor(){}

}
