<div class="form-cntr">

<form [formGroup]="form" (ngSubmit)="putUserInfo()">

  <p>
    <mat-form-field appearance="fill">
      <mat-label> {{"NOME"|translate}}</mat-label>
      <input matInput [placeholder]="'NOME'|translate" formControlName="Name">
      <mat-error *ngIf="form.get('Name')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field appearance="fill">
      <mat-label> {{"COGNOME"|translate}}</mat-label>
      <input matInput [placeholder]="'COGNOME'|translate" formControlName="Surname">
      <mat-error *ngIf="form.get('Surname')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>
    </mat-form-field>
  </p>

  <!-- <p>
    <mat-form-field appearance="fill">
      <mat-label> {{"CODICEFISCALE"|translate}}</mat-label>
      <input matInput [placeholder]="'CODICEFISCALE'|translate" formControlName="FiscalCode">
    </mat-form-field>
  </p> -->

  <p>

    <mat-form-field appearance="fill">
      <mat-label> {{"DATA"|translate}} </mat-label>
      <input matInput [matDatepicker]="picker" formControlName="Birthday">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

  </p>
  <!-- <p>

    <mat-form-field appearance="fill">
      <mat-label> {{"ANNODIAGNOSI"|translate}} </mat-label>
      <input matInput  type="number" min="0" max="{{thisYear}}" formControlName="DiagnosisDate">
    </mat-form-field>


  <p>

    <mat-form-field appearance="fill">
      <mat-label>{{"STATOCIVILE"|translate}}</mat-label>
      <mat-select formControlName="MaritalStatus">
        <mat-option *ngFor="let maritalStatus of maritalStatuses" [value]="maritalStatus.id">
          {{maritalStatus.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </p>

  <p>

    <mat-form-field appearance="fill">
      <mat-label> {{"ISTRUZIONE"|translate}}</mat-label>
      <mat-select formControlName="Education">
        <mat-option *ngFor="let education of educationes" [value]="education.id">
          {{education.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </p>

  <p>

    <mat-form-field appearance="fill">
      <mat-label> {{"CONDIZIONEPROFESSIONALE"|translate}}</mat-label>
      <mat-select formControlName="ProfessionalCondition">
        <mat-option *ngFor="let professionalCondition of professionalConditions" [value]="professionalCondition">
          {{professionalCondition}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </p>
  <div class="mat-option-cntr">

  <p>
    <mat-form-field appearance="fill">
      <mat-label> {{"EDSS"|translate}} </mat-label>
      <mat-select formControlName="EDSS">
        <mat-option *ngFor="let value of edss" [value]="value.id">
          {{value.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </p>
</div>
<div class="mat-option-cntr">

  <p>
    <mat-form-field appearance="fill">
      <mat-label> {{"FORMAMALATTIA"|translate}} </mat-label>
      <mat-select formControlName="DiseaseForm">
        <mat-option *ngFor="let value of diseaseForm" [value]="value.id">
          {{value.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </p>
</div> -->

  <button mat-raised-button type="submit" color="primary" class="titleButton mb-4" > {{"SALVA"|translate}}</button>

</form>

</div>
