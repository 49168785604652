import { Component, OnInit } from '@angular/core';
import {
  Tile,
  MatrixOption,
} from 'src/app/_core/classes/DTO/test-progress-game/match-number-result';
declare var require : any;

@Component({
  selector: 'app-thirdIstructionsMatch',
  templateUrl: './thirdIstructionsMatch.component.html',
  styleUrls: ['./thirdIstructionsMatch.component.scss'],
})
export class ThirdIstructionsMatchComponent implements OnInit {
  constructor() {}

  legends: Tile[] = require('../../../../../../../assets/match-number-json/legendsMatchNumber.json');
  qwerty: Tile[] = require('../../../../../../../assets/match-number-json/qwertyMatchNumber.json');
  matrixTutorial: Array<Tile>[] = [];
  matrixEmptyTutorial: Array<Tile>[] = require('../../../../../../../assets/match-number-json/matrixTutorialEmptyMatchNumber.json');
  matrixCompleteTutorial: Array<Tile>[] = require('../../../../../../../assets/match-number-json/matrixTutorialMatchNumber.json');

  intervals: Array<any> = [];

  playVideo: boolean = true;

  ngOnInit() {
    this.playVideo = true;
    this.createDinamicVideo();
  }

  ngOnDestroy() {
    this.playVideo = false;
    this.resetVideo();
    this.clearIntervals();
  }

  createDinamicVideo() {
    this.intervals.push(
      setTimeout(() => {
        this.matrixCompleteTutorial[0][0].selected = true;

        this.intervals.push(
          setTimeout(() => {
            this.legends[2].selected = true;
        this.legends[11].selected = true;

          }, 2000)
        );
        this.intervals.push(
          setTimeout(() => {
            this.qwerty[2].color ='#93ce93';
          }, 3000)
        );
        this.intervals.push(
          setTimeout(() => {
            this.matrixEmptyTutorial[0][0].number = 3;
            this.matrixEmptyTutorial[0][0].color = '#93ce93';
            this.matrixCompleteTutorial[0][0].selected = false;
            this.matrixCompleteTutorial[0][0].color = '#93ce93';

          }, 4000)
        );
      }, 2000)
    );

    this.intervals.push(
      setTimeout(() => {
        this.qwerty[2].color = '#fcaf05';
        this.matrixCompleteTutorial[0][1].selected = true;

        this.intervals.push(
          setTimeout(() => {
            this.legends[3].selected = true;
            this.legends[12].selected = true;

          }, 2000)
        );
        this.intervals.push(
          setTimeout(() => {
            this.qwerty[4].color = 'rgb(224, 94, 94)';
          }, 3000)
        );
        this.intervals.push(
          setTimeout(() => {
            this.matrixEmptyTutorial[0][1].number = 5;
            this.matrixEmptyTutorial[0][1].color = 'rgb(224, 94, 94)';
            this.matrixCompleteTutorial[0][1].selected = false;
            this.matrixCompleteTutorial[0][1].color = 'rgb(224, 94, 94)';
          }, 4000)
        );

        this.intervals.push(
          setTimeout(() => {
            this.qwerty[4].color = '#fcaf05';
            this.qwerty[3].color = '#93ce93';


          }, 5000)
        );



      }, 8000)
    );

    this.intervals.push(setTimeout(() => {

        this.matrixEmptyTutorial[0][1].number = 4;
        this.matrixEmptyTutorial[0][1].color = '#93ce93';
        this.matrixCompleteTutorial[0][1].color = '#93ce93';


      this.intervals.push(setTimeout(() => {
        this.resetVideo()},2000));
      }, 14000)

    );


  }

  resetVideo() {
    this.legends[2].selected = false;
    this.legends[11].selected = false;
    this.qwerty[2].selected = false;
    this.qwerty[3].selected = false;
    this.matrixEmptyTutorial[0][0].number = undefined;
    this.matrixEmptyTutorial[0][0].selected = false;
    this.matrixCompleteTutorial[0][0].selected = false;
    this.qwerty[2].selected = false;
    this.legends[3].selected = false;
    this.legends[12].selected = false;
    this.qwerty[4].selected = false;
    this.matrixEmptyTutorial[0][1].number = undefined;
    this.matrixEmptyTutorial[0][1].color = '#F4F4F4';
    this.matrixCompleteTutorial[0][1].color = '#F4F4F4';
    this.matrixEmptyTutorial[0][1].number = undefined;
    this.matrixEmptyTutorial[0][1].selected = false;
    this.matrixCompleteTutorial[0][1].selected = false;
    this.matrixEmptyTutorial[0][1].color = '#F4F4F4';
    this.matrixCompleteTutorial[0][1].color = '#F4F4F4';
    this.qwerty[3].color = '#fcaf05';
    this.qwerty[2].color = '#fcaf05';
    this.qwerty[4].color = '#fcaf05';
    this.matrixEmptyTutorial[0][0].color = '#F4F4F4';
    this.matrixCompleteTutorial[0][0].color ='#F4F4F4';

    if (this.playVideo) {
      this.createDinamicVideo();
    }
  }

  clearIntervals() {
    this.intervals.forEach((element) => {
      clearTimeout(element);
    });
  }
}
