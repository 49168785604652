import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {
  CtAuthenticationService,
  SignInParameter,
} from '@ctsolution/ct-authentication';
import { TranslateService } from '@ngx-translate/core';
import { GenericLabel, ModalButton } from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import { UserInfo } from 'src/app/_core/classes/user-info';
import { UserController } from 'src/app/_core/controllers/user.controller';
import { ClaimsService } from 'src/app/_core/services/claims.service';
import { LocalStorageService } from 'src/app/_core/services/localStorage.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  hide: boolean = true;
  profile?: UserInfo;

  constructor(
    private fb: UntypedFormBuilder,
    private _auth: CtAuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private _user : UserController,
    private translate : TranslateService,
    private claimService :ClaimsService,
    private localStorageService : LocalStorageService
  ) {
    this.form = this.fb.group({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new UntypedFormControl('', [Validators.required]),
    });

    this.route.queryParams.subscribe(params => {
      if (params['returnUrl']) {
        const returnUrlParams = new URLSearchParams(params['returnUrl'].split('?')[1]); // Ottieni i parametri dalla returnUrl
        const email = returnUrlParams.get('Email');
        if (email) {
          this.form.get('email')?.setValue(email); // Imposta il valore dell'email nel campo email del form
        }
      }
    });

    if (this._auth.userValue) this.navigateToHome();
  }

  ngOnInit(): void {}

  async login() {
    if (this.form.valid) {
      const parameter: SignInParameter = new SignInParameter(
        this.form.value.email,
        this.form.value.password,
        true
      );

      this._auth.signIn(parameter).subscribe(async () => {
          this.navigateToHome();
          let language = await this.claimService.getLanguage();
          let code = language?.split("-")[0];
          if(code){

            if(!this.localStorageService.getLanguage()){

              this.localStorageService.setLanguage(code);

            }
          }
    })


  }
  }
  navigateToHome = () => this.router.navigate(['getting-started']);
  navigateToReset = () =>
    this.router.navigate(['reset-password'], { relativeTo: this.route });
}
