
 <div class="d-flex flex-column result-mode-cntr">

  <div class="text-center games-result-cntr">



    <ng-container *ngIf="selected ; else defaultTplt">

        <p class="title mb-4">{{"G_PINFOTEST3"|translate}} </p>
        <img src="/assets/images/iconResult/flag.svg" class="text-center mb-4 mt-4 icon-result">
        <p class="subtitle mb-4" *ngIf="selected.description" [innerHtml]=" selected.description | translate">  </p>

        <ng-container [ngSwitch]="selected.type">

          <ng-container *ngSwitchCase="testType.MEMORY" [ngTemplateOutlet]="memoryResultTemplate"> </ng-container>
          <ng-container *ngSwitchCase="testType.LISTEN" [ngTemplateOutlet]="listenResultTemplate"> </ng-container>
          <ng-container *ngSwitchCase="testType.GENERATES_WORDS" [ngTemplateOutlet]="generateWordsResultTemplate">
          </ng-container>
          <ng-container *ngSwitchCase="testType.MATCH_NUMBERS" [ngTemplateOutlet]="MatchNumberResultTemplate"> </ng-container>

          <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTplt"></ng-container>

        </ng-container>


      <button mat-raised-button color="primary" class="titleButton result-game-button" (click)="navigateTo()">
        {{'BACKBUTTONTEST'| translate}} </button>

    </ng-container>

    <ng-template #memoryResultTemplate>

      <div class="memory-result-cntr">

        <p class="title number-score mb-4"> {{this.totalScoreMemory}}/30 </p>

        <div class="text-left mb-4">
          <li class="subtitle"> {{'R&PRESULT1'| translate}} {{this.score1Memory}}/10 </li><br>
          <li class="subtitle"> {{'R&PRESULT2'| translate}} {{this.score2Memory}}/10 </li><br>
          <li class="subtitle"> {{'R&PRESULT3'| translate}} {{this.score3Memory}}/10 </li><br>
        </div>

      </div>

    </ng-template>

    <ng-template #listenResultTemplate>

    </ng-template>

    <ng-template #generateWordsResultTemplate>

    </ng-template>

    <ng-template #MatchNumberResultTemplate>

      <div class="match-number-result-cntr" *ngIf="!isTrainingMode">

        <p class="title number-score mb-4"> {{this.scoreMatchNumber}}</p>


      </div>


    </ng-template>

    <ng-template #defaultTplt>

      <p class="subtitle mb-4 mt-4" > {{'RISULTATONONDISPONIBILE'| translate}} </p>

      <button mat-raised-button color="primary" class="titleButton result-game-button" (click)="navigateTo()">
        {{'BACKHOMETESTBUTTON'| translate}} </button>

    </ng-template>

  </div>
 </div>
