export class MaritalStatus {

  constructor(
    public id : number,
    public label : string,
  )
{}


}

export class MaritalStatusObjectValue {

  public static celibeNubile : MaritalStatus = new MaritalStatus (0,'Celibe/nubile');
  public static coniugatoConvivente : MaritalStatus = new MaritalStatus (1,'Coniugato/convivente');
  public static vedovo : MaritalStatus = new MaritalStatus (2,'Vedovo');
  public static separatoDivorziato : MaritalStatus = new MaritalStatus (3,'Separato/divorziato');

  public static list = () => [this.celibeNubile,this.coniugatoConvivente,this.vedovo,this.separatoDivorziato]

  constructor(){}

}
