import { Routes } from '@angular/router';
import { TestSelectionComponent } from '../test-type/test-mode/test-selection/test-selection.component';
import { TestSelectionModule } from '../test-type/test-mode/test-selection/test-selection.module';
import { GeneratesWordsModule } from './generates-words/generates-words.module';
import { MatchNumbersModule } from './match-numbers/match-numbers.module';
import { MemoryGameModule } from './memory-game/memory-game.module';
import { VerbalMemoryGameModule } from './verbal-memory-game/verbal-memory-game.module';

export const GamesRoutes: Routes = [
  {
    path: 'memory',
    loadChildren: () => MemoryGameModule,
    data: { disableBack: true, disableQuit: true, disableMenu: true },
  },
  {
    path: 'verbal',
    loadChildren: () => VerbalMemoryGameModule,
    data: { disableBack: true, disableQuit: true, disableMenu: true },
  },
  {
    path: 'generate',
    loadChildren: () => GeneratesWordsModule,
    data: { disableBack: true, disableQuit: true, disableMenu: true },
  },
  {
    path: 'match_numbers',
    loadChildren: () => MatchNumbersModule,
    data: { disableBack: true, disableQuit: true, disableMenu: true },
  },
  {
    path: '',
    component: TestSelectionComponent,
    data: { disableBack: false },
  },
];
