import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { CtWebviewService } from '@ctsolution/ct-webview';
import { TranslateService } from '@ngx-translate/core';
import * as RecordRTC from 'recordrtc';
import { QuitTestService } from 'src/app/layout/header/quit-test.service';
import {
  GenericLabel,
  ModalButton
} from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import {
  DGCTestStateEnum,
  SaveProgress
} from 'src/app/_core/classes/DTO/test-progress-game/save-progress';
import { VerbalMemoryGameInfo } from 'src/app/_core/classes/DTO/test-progress-game/verbal-memory-game-info';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import { RecordRTCOptions } from 'src/app/_core/classes/record-option-class';
import { TestController } from 'src/app/_core/controllers/test.controller';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { VerbalMemorySaveprogressService } from 'src/app/_core/services/verbal-memory-saveprogress.service';
import { TempResultService } from '../../temp-result.service';
import { MicrophoneService } from './../../../../_core/services/microphone.service';

@Component({
  selector: 'app-recorder_component',
  templateUrl: './recorder_component.component.html',
  styleUrls: ['./recorder_component.component.scss'],
})
export class Recorder_componentComponent implements OnInit, OnDestroy {
  gameType: GAME = GAME.TRAINING_MODE;

  record: any;
  isTrainingMode = false;
  recording = false;
  url: any;
  error: any;

  startAttempt?: number;
  endAttempt?: number;

  timeout: any;

  gameDuration: number = 45000;

  hiddenRecordingButton = false;
  hiddenStopButton = true;
  attemptNumberForTranslate: number = 0;
  Audiotest = new Audio();

  saveProgress? : SaveProgress;

  SaveProgressViewModel: {
    createAppDateTime: Date | undefined;
    verbalTestAttempt: number;
    audioGameResult: Blob | null;
  } = {
    createAppDateTime: undefined,
    verbalTestAttempt: 0,
    audioGameResult: null,
  };

  constructor(
    private route: Router,
    public _tempResultService: TempResultService,
    private domSanitizer: DomSanitizer,
    private router: ActivatedRoute,
    private verbalTestController: TestController,
    private quitTestService: QuitTestService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private microphoneService: MicrophoneService,
    private saveProgressService: VerbalMemorySaveprogressService,
    private ctWebViewService: CtWebviewService
  ) {
    this.SaveProgressViewModel.verbalTestAttempt =
      _tempResultService.returnVerbalTestNum();

    this.attemptNumberForTranslate =
      this.SaveProgressViewModel.verbalTestAttempt + 1;

    this.router.data.subscribe((gameTypeData: Data) => {
      if (gameTypeData) {
        this.gameType = gameTypeData['type'] as GAME;
      }
    });
  }

  ngOnInit() {
    if(this.ctWebViewService.isCtWebView() && this.ctWebViewService.isAndroidDevice()){
      this.ctWebViewService.AndroidFunctions.checkMicrophonePermissions();
    }
    this.saveProgress = this.saveProgressService.getSaveProgress();

    this.isTrainingMode = this.gameType === GAME.TRAINING_MODE;
  }

  ngOnDestroy() {
    if (
      this.quitTestService.getQuitTest() &&
      this.gameType == GAME.TEST_MODE
    ) {
      this.verbalTestController
      .putFormData(this.saveProgress?.Quitted());
      this.quitTestService.setQuitTest(false);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.stopMicrophone();

  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  initiateRecording() {
    this.recording = true;
    this.startAttempt = performance.now();
    this.microphoneService
    .checkMicrophonePermissions()
    .then((stream: MediaStream) => this.successCallback(stream))
    .catch((err: string) => console.error(err));
  }
  /**
   * Will be called automatically.
   */

  playAudio(url: string) {
    this.Audiotest.src = this.url;
    this.Audiotest.play();
  }
  successCallback(stream: any) {
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    let optionRecorder = RecordRTCOptions.create();
    this.record = new StereoAudioRecorder(stream, optionRecorder);
    this.record.record();
    this.record.microphone = stream;
  }
  stopRecording() {
    this.recording = false;
    this.stopMicrophone();
  }

  stopMicrophone(){

    if(this.record){
    this.record.microphone.stop();
    this.record.stop((blob: Blob) => this.setGameResult(blob));
    this.record = null;

    }
    this.microphoneService.reset();
  }
  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob: any) {
    this.setGameResult(blob);
  }

  setGameResult(blob: Blob) {
    this.SaveProgressViewModel.audioGameResult = blob;
    this.navigateTo();
  }
  /**
   * Process Error.
   */
  errorCallback(error: any) {
    let dialogContent = DialogClass.create(new GenericLabel(this.translateService.instant('WARNING')), [
      new ModalButton("OK", () => {
        this.route.navigate(['home']);
      }, true)
    ]).setDescription(new GenericLabel(this.translateService.instant('MICROPHONE.ERROR')))

    this.dialog.open(GenericModalComponent,{
        data: dialogContent,
        disableClose: true,
      });
  }

  navigateTo() {
    if (this.gameType == GAME.TRAINING_MODE) {
      this.route.navigate(['../../result'], { relativeTo: this.router });
    } else {
      if (this.SaveProgressViewModel.verbalTestAttempt < 5) {
        this.saveTestProgress(DGCTestStateEnum.Executing);
        this.route.navigate(['/mode/autonomous-mode/test/verbal/test']);
      } else {
        this._tempResultService.resetVerbalTest();
        this.saveTestProgress(DGCTestStateEnum.Completed);
        this.route.navigate(['../../result'], { relativeTo: this.router });
      }
    }
  }
  navigateToHome() {
    this.route.navigate(['getting-started']);
  }

  recordTest() {
    this.initiateRecording();
    this.hiddenRecordingButton = true;

    this.timeout = setTimeout(() => {
      this.pause();
    }, this.gameDuration);
  }
  pause() {
    clearTimeout(this.timeout);
    this.endAttempt = performance.now();
    this.stopRecording();
    this.hiddenRecordingButton = true;
    this.hiddenStopButton = false;
  }

  saveTestProgress(stateTest: DGCTestStateEnum) {
    if (
      !this.saveProgress ||
      !this.startAttempt ||
      !this.endAttempt ||
      this.isTrainingMode
    ) {
      return;
    }



    let attemptString : VerbalMemoryGameInfo = VerbalMemoryGameInfo.create(this.getSecondsAttempt(this.startAttempt,this.endAttempt),this.SaveProgressViewModel.verbalTestAttempt);
    this.saveProgressService.pushVerbalMemoryGameInfo(attemptString);
    this.saveProgress.Completed(JSON.stringify(this.saveProgressService.getVerbalMemoryGameInfo()),stateTest)
    .setFile(this.SaveProgressViewModel.audioGameResult);

    this.verbalTestController
    .putFormData(this.saveProgress);

  }

  getSecondsAttempt(start : number , end : number) : number{

    return (end - start)/1000;

  }

}
