<div class="container page-profile-cntr">

  <div class="mt-5 mb-5">

    <app-IconaProfilo></app-IconaProfilo>

  </div>

  <div class="profile-cntr">

    <app-profile-form #ProfileForm></app-profile-form>
    <div class="delete-text" style="cursor: pointer;" (click)="deleteProfileOpenDialog()">{{'DELETEPROFILOTITLE'|translate}}</div>

  </div>





</div>
