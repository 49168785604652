import { Component, OnInit } from '@angular/core';
import { MatrixOption, Tile } from 'src/app/_core/classes/DTO/test-progress-game/match-number-result';

declare var require: any;


@Component({
  selector: 'app-FirstIstructionsMatch',
  templateUrl: './FirstIstructionsMatch.component.html',
  styleUrls: ['./FirstIstructionsMatch.component.scss']
})


export class FirstIstructionsMatchComponent implements OnInit {



  legends: Tile[] = require('../../../../../../../assets/match-number-json/legendsMatchNumber.json');
  legedsOption: MatrixOption = MatrixOption.create('9', '50px', true);


  constructor() { }

  ngOnInit() {
  }

}
