<div class="tutorial-login-cntr" *ngIf="visible">

  <c-carousel (itemChange)="eventChange($event)"  [wrap]="false" [interval]="0" [touch]="false"
    [animate]="false" [scrollLeft]="false" [scrollTop]="false">
    <c-carousel-inner>
      <c-carousel-item *ngFor="let scheda of schede">


        <app-tutorial-login-scheda [scheda]="scheda"></app-tutorial-login-scheda>

      </c-carousel-item>
    </c-carousel-inner>

    <div class="control-cntr">
      <div class="indicators-cntr">
        <c-carousel-indicators></c-carousel-indicators>
      </div>
      <div class="button-cntr">
        <c-carousel-control [routerLink] direction="prev"><button [disabled]="index == 0" style="margin-top: 10px;"
            mat-raised-button color="primary"
            class="titleButton start-button generic-button">{{"BACKBUTTON"|translate}}</button></c-carousel-control>
        <c-carousel-control *ngIf="index != 4" [routerLink] direction="next"><button style="margin-top: 10px;"
            mat-raised-button color="primary"
            class="titleButton start-button generic-button">{{"NEXTBUTTON"|translate}}</button></c-carousel-control>
        <c-carousel-control (click)="endTutorial()" *ngIf="index == 4"><button style="margin-top: 10px;"
            mat-raised-button color="primary"
            class="titleButton start-button generic-button">{{"STARTBUTTON"|translate}}</button></c-carousel-control>
      </div>
      <div class="txt-cntr" (click)="endTutorial()">{{"SALTA"|translate}}</div>
    </div>
  </c-carousel>

</div>
