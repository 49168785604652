import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-memory-game-title',
  templateUrl: './memory-game-title.component.html',
  styleUrls: ['./memory-game-title.component.scss']
})
export class MemoryGameTitleComponent implements OnInit {

  @Input() viewModel: any;

  constructor(private translateService: TranslateService ) { }

  ngOnInit(): void {
  }


}
