<mat-card class="grid-cntr" *ngIf="viewModel.hiddenButtonPreNewTestOrTraining">

  <mat-grid-list [cols]="row.length" *ngFor="let row of matrix" [gutterSize]="'-1'">

    <mat-grid-tile *ngFor="let col of row" (click)="selectTile(col)">

      <mat-icon *ngIf="col.selected || (col.initialValue && viewModel.gameFinished)" [ngClass]="getCircleClass(col)">
        {{checkIconOnFinishedTraining(col)}}

      </mat-icon>

    </mat-grid-tile>

  </mat-grid-list>

</mat-card>
