<div class="container">

  <mat-card class="istructions-cntr">



      <h1 class="title mt-4 ">{{'A_ITITLE'|translate}}</h1>



    <mat-card-content class="mat-card-istruction-content-cntr">


      <mat-card class="grid-istruction-number-cntr mb-4 text-right">

        <mat-grid-list cols="9" rowHeight="25px">

          <mat-grid-tile *ngFor="let tile of legends" [colspan]="tile.cols"
            [rowspan]="tile.rows" [style.background]="tile.color" [ngClass]="{'border-selected': tile.selected}">

            <ng-container *ngIf="tile.text ; else iconTemplate">

              <div class="bolder">{{tile.text}}</div>

            </ng-container>

            <ng-template #iconTemplate>

              <mat-icon>{{tile.icon}}</mat-icon>

            </ng-template>

          </mat-grid-tile>

        </mat-grid-list>

      </mat-card>



      <mat-card class="grid-istruction-number-cntr mb-4 text-right">

        <mat-grid-list cols="4" rowHeight="25px">

          <mat-grid-tile *ngFor="let tile of matrixCompleteTutorial[0]" [colspan]="tile.cols"
            [rowspan]="tile.rows" [style.background]="tile.color" [ngClass]="{'border-selected': tile.selected}">

            <ng-container *ngIf="tile.text ; else iconTemplate">

              <div class="bolder">{{tile.text}}</div>

            </ng-container>

            <ng-template #iconTemplate>

              <mat-icon>{{tile.icon}}</mat-icon>

            </ng-template>

          </mat-grid-tile>

          <mat-grid-tile *ngFor="let tile of  matrixEmptyTutorial[0]" [colspan]="tile.cols"
            [rowspan]="tile.rows" [style.background]="tile.color" [ngClass]="{'border-selected': tile.selected}">

            <div class="bolder">{{tile.number}}</div>

          </mat-grid-tile>

        </mat-grid-list>

      </mat-card>



      <mat-card class="grid-istruction-number-cntr mb-5 text-right">

        <mat-grid-list cols="3" rowHeight="35px">

          <mat-grid-tile *ngFor="let tile of qwerty" [colspan]="tile.cols"
            [rowspan]="tile.rows" [style.background]="tile.color" [ngClass]="{'border-selected': tile.selected}">

            <ng-container *ngIf="tile.number ; else iconTemplate">

              <div class="bolder">{{tile.number}}</div>

            </ng-container>

            <ng-template #iconTemplate>

              <mat-icon>{{tile.icon}}</mat-icon>

            </ng-template>

          </mat-grid-tile>

        </mat-grid-list>

      </mat-card>


      <h2 class="subtitle mt-2">{{'A_ITHIRDTISTRUCTIONDESCRIPTION'|translate}}</h2>




    </mat-card-content>


    <div class="dots-istructions-width">
      <app-dots-istructions [value]="3"></app-dots-istructions>
    </div>

  </mat-card>


</div>
