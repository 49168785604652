import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {GenericLabel, ModalButton} from 'src/app/_components/generic-card/generic-card.component';
import {GenericModalComponent} from 'src/app/_components/generic-modal/generic-modal.component';
import {DialogClass} from '../classes/modal-class';

const MEDIA_AUDIO_CONSTRAINTS = {audio: true};

@Injectable({
  providedIn: 'root'
})
export class MicrophoneService {

  stream: MediaStream | null = null;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) {
  }

  checkMicrophonePermissions(): Promise<MediaStream> {

    return new Promise<MediaStream>((resolve, reject) => {

      if (this.stream) {

        resolve(this.stream);
        return;

      }

      navigator.mediaDevices
        .getUserMedia(MEDIA_AUDIO_CONSTRAINTS)
        .then((microphone: MediaStream) => {

          this.stream = microphone;
          resolve(this.stream)

        })
        .catch(() => {

          this.microphoneCheckFallbackDialog()
            .afterClosed()
            .subscribe(() => reject('Can not play audio in your browser'))

        });

    });

  }

  private microphoneCheckFallbackDialog(): MatDialogRef<GenericModalComponent> {

    const dialogLabel: GenericLabel = new GenericLabel(this.translateService.instant('WARNING'));
    const dialogButtons: ModalButton[] = [new ModalButton("OK", () => {
      this.router.navigate(['home']);
    }, true)]

    const dialogContent = DialogClass
      .create(dialogLabel, dialogButtons)
      .setDescription(new GenericLabel(this.translateService.instant('MICROPHONE.ERROR')))

    return this.dialog.open(GenericModalComponent, {
      data: dialogContent,
      disableClose: true,
    });

  }

  reset() {

    this.stream = null;

  }

}
