import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TempResultService } from 'src/app/tests/games/temp-result.service';
import { DialogClass } from 'src/app/_core/classes/modal-class';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent implements OnInit {
  closeResult = '';
  numberCoin : number |undefined;
  constructor(
    private route: Router,
    private _tempResult: TempResultService,
    @Inject(MAT_DIALOG_DATA) public data: DialogClass
  ) {

    this.numberCoin = data.numberCoin;

  }

  ngOnInit() {}
  backClicked() {
    this.route.navigate(['mode']);
    this._tempResult.gameQuitted();
  }
}
