<ng-container>

<div class="form-cntr">

  <p class="title">{{"REGISTRAZIONE"|translate}}</p>
<ng-container *ngIf="!stepComplete; else step2template">
  <form [formGroup]="form" (ngSubmit)="step1complete()">

    <p>
      <mat-form-field appearance="fill">
        <mat-label> {{"NOME"|translate}}</mat-label>
        <input matInput [placeholder]="'NOME'|translate" formControlName="Name">
        <mat-error *ngIf="form.get('Name')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill">
        <mat-label> {{"COGNOME"|translate}}</mat-label>
        <input matInput [placeholder]="'COGNOME'|translate" formControlName="Surname">
        <mat-error *ngIf="form.get('Surname')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>

      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label> {{"EMAIL"|translate}}</mat-label>
        <input matInput [placeholder]="'EMAIL'|translate" formControlName="Email">
        <mat-error *ngIf="form.get('Email')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>
        <mat-error *ngIf="form.get('Email')?.hasError('email')">{{"Email non valida"|translate}}</mat-error>

      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label> {{"PASSWORD"|translate}}</mat-label>
        <input matInput type="password" [type]="hide ? 'password' : 'text'" [minlength]="6" [placeholder]="'PASSWORD'|translate" formControlName="Password">
        <mat-icon matSuffix  (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="form.get('Password')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>
        <mat-error *ngIf="form.get('Password')?.hasError('minlength')">{{"6CHAR"|translate}}</mat-error>
        <mat-error *ngIf=" form.get('Password')?.hasError('passwordStrength')">{{"PWSTRENGHT"|translate}} </mat-error>
      </mat-form-field>

    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label> {{ "CONFERMAPASSWORD" | translate }}</mat-label>
        <input matInput type="password" [type]="hideConf ? 'password' : 'text'" [minlength]="6" [placeholder]="'CONFERMAPASSWORD'|translate" formControlName="ConfermaPassword">
        <mat-icon matSuffix  (click)="hideConf = !hideConf">{{hideConf ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="form.get('ConfermaPassword')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>

      </mat-form-field>
      <small>
        <mat-error class="pw-not-equal-cntr" *ngIf="passwordNotEqual && !form.get('ConfermaPassword')?.hasError('required')">* {{ 'PASSWORDDIVERSE' | translate }}</mat-error>
    </small>
    </p>


    <!-- <p>
      <mat-form-field appearance="fill">
        <mat-label> {{"CODICEFISCALE"|translate}}</mat-label>
        <input matInput [placeholder]="'CODICEFISCALE'|translate" formControlName="FiscalCode">
        <mat-error *ngIf="form.get('FiscalCode')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>
        <mat-error *ngIf="form.get('FiscalCode')?.hasError('pattern')">{{"Codice fiscale non valido"|translate}}</mat-error>
      </mat-form-field>
    </p> -->

    <!-- <p>

      <mat-form-field appearance="fill">
        <mat-label> {{"LINGUA"|translate}}</mat-label>
        <mat-select formControlName="Language">
          <mat-option *ngFor="let lang of languages" [value]="lang" >
            {{lang}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </p> -->

    <p>

      <mat-form-field appearance="fill">
        <mat-label> {{"DATA"|translate}} </mat-label>
        <input matInput [matDatepicker]="picker"  [max]="maxDate"  formControlName="Birthday">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="form.get('Birthday')?.hasError('required')">{{"CAMPOOBBL"|translate}}</mat-error>
      </mat-form-field>

    </p>

    <button mat-raised-button type="submit" color="primary" class="titleButton mb-4" > {{"NEXTBUTTON"|translate}}</button>

  </form>
</ng-container>

<ng-template #step2template>

  <form class="form-display" [formGroup]="formStep2" (ngSubmit)="createUser()">

<div class="section-cntr">
      <section>
        <div class="checkbox-cntr">
      <mat-checkbox   formControlName="TermsAndConditions" ></mat-checkbox><h2>Ho preso visione dei <span style="cursor: pointer; text-decoration: underline;" (click)="openTermini(0)">Termini e Condizioni</span></h2>
    </div>

    </section>
      <section>
        <div class="checkbox-cntr">
        <mat-checkbox formControlName="Privacy" ></mat-checkbox><h2>Ho preso visione dell’<span style="cursor: pointer; text-decoration: underline;" (click)="openTermini(1)">informativa </span>ex art. 13 del Regolamento UE 679/201
        </h2></div>
      </section>
        </div>
<div class="button-cntr">
    <button mat-raised-button type="submit" color="primary" class="titleButton mb-4" [disabled]="!this.form.valid || !this.formStep2.valid" > {{"REGISTRATI"|translate}}</button>
    <h2 class="back-text" (click)="stepComplete = !stepComplete" > {{"BACKBUTTON"|translate}}</h2>
</div>
  </form>


</ng-template>


  </div>
</ng-container>
