import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { TimerTestModule } from 'src/app/tests/_components/timer-test/timer-test.module';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { TEST_TYPES } from 'src/app/_core/enums/test-types.enum';
import { GamesResultComponent } from '../games-result/games-result.component';
import {
  TestIntroComponent
} from '../test-intro/test-intro.component';
import { GridModule } from './grid/grid.module';
import { MemoryGameComponent } from './memory-game.component';
import { MemoryGameTitleComponent } from './memory-game-title/memory-game-title.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MemoryGameActionsComponent } from './memory-game-actions/memory-game-actions.component';
import { GridLegendModule } from './grid-legend/grid-legend.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TestController } from 'src/app/_core/controllers/test.controller';

@NgModule({
  imports: [
    CommonModule,
    MatGridListModule,
    GridModule,
    TimerTestModule,
    MatButtonModule,
    GridLegendModule,
    TranslateModule,
    MatProgressBarModule,
    RouterModule.forChild([
      {
        path: 'training',
        component: MemoryGameComponent,
        data: { type: GAME.TRAINING_MODE },
      },
      {
        path: 'test',
        component: MemoryGameComponent,
        data: { type: GAME.TEST_MODE, disableBack: true, disableQuit: true },
      },
      {
        path: 'result',
        component: GamesResultComponent,
        data: { type: TEST_TYPES.MEMORY,  disableBack: true, disableQuit: false  },
      },
      {
        path: '',
        component: TestIntroComponent,
        data: { type: TEST_TYPES.MEMORY, disableBack: false, disableQuit: false },
      },
    ]),
  ],
  declarations: [MemoryGameComponent, MemoryGameTitleComponent, MemoryGameActionsComponent],
  providers : [TestController]
})
export class MemoryGameModule { }
