<ng-container *ngIf="_auth.userValue ;  else showUndefinedUserValue">

  <div class="header-cntr container-fluid pt-2 pb-2">
    <div class="first-item">
      <ng-container *ngIf="viewModel.handleBackRoute">
        <app-back-button></app-back-button>
      </ng-container>
      <ng-container *ngIf="viewModel.handleQuitButton && _auth.userValue" >
        <app-quit-button ></app-quit-button>
      </ng-container>
    </div>
    <div class="second-item">
      <app-logo></app-logo>
    </div>
    <div class="third-item">
      <app-hamburger-menu></app-hamburger-menu>
    </div>
  </div>
</ng-container>

<ng-template #showUndefinedUserValue >
  <div class="header-cntr container-fluid pt-2 pb-2">
    <div class="first-item">
      <!-- momentaneamente vuota non serve un terzo elemento -->
    </div>
    <div class="second-item">
      <app-logo></app-logo>
    </div>
    <div class="third-item">
      <app-hamburger-menu></app-hamburger-menu>
    </div>
  </div>

</ng-template>
