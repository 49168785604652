
<!-- <ng-template #isTestModeTemplate>

  <div class="title mb-1 text-center" *ngIf="viewModel.readyToPlay"> {{viewModel.testCounter }}° {{'TEST' |
    translate}}</div>

</ng-template> -->
<ng-container *ngIf="viewModel.readyToPlay ; else notReadyToPlayTemplate">

  <ng-container *ngIf="!viewModel.showTrainingResult">

    <div class="title result-txt" [innerHtml]="'R&PINFOTEST1' | translate :{coinNumber: viewModel.missingCellsCount + '/' + viewModel.GRID_SETTINGS.iconsCount} ">
    </div>

  </ng-container>

</ng-container>

<ng-template #notReadyToPlayTemplate>

  <div class="title result-txt " [hidden]="!viewModel.hiddenButtonPreNewTestOrTraining || viewModel.showTrainingResult" [innerHtml]="'R&PINFOPAGE' | translate"> </div>

</ng-template>
