import { DGCTestTypeEnum } from "../classes/DTO/test-progress-game/save-progress";
import { TEST_TYPES } from "../enums/test-types.enum";

export class TestInfoValueObject{


    public static MemoryGame : TestConfigurationValue = {
      Name : "R&PTITLE",
      Type : DGCTestTypeEnum.MEMORIA,
      Image : "../../../assets/images/testType/remember.svg"
      
    };
    public static VerbalMemoryGame : TestConfigurationValue = {
        Name : "A&RTITLE",
        Type : DGCTestTypeEnum.VERBAL,
        Image : "../../../assets/images/testType/listen.svg"
    };
    public static MatchNumbers : TestConfigurationValue = {
        Name : "A_ITITLE",
        Type : DGCTestTypeEnum.MATCH,
        Image : "../../../assets/images/testType/couple.svg"
    };
    public static GeneratesWords : TestConfigurationValue = {
        Name : "G_PTITLE",
        Type : DGCTestTypeEnum.GENERATE,
        Image : "../../../assets/images/testType/generate.svg"
    };
  
    public static Items : Array<TestConfigurationValue> = new Array<TestConfigurationValue>(this.MemoryGame,this.VerbalMemoryGame,this.GeneratesWords,this.MatchNumbers);
    public static getByType(type : DGCTestTypeEnum) : TestConfigurationValue | undefined {

     return this.Items.find(elem => elem.Type == type);

    };
  
  
  }
  
  
  
  export class TestConfigurationValue{
  
      public Name : string;
      public Type : DGCTestTypeEnum;
      public Image : string;
      
  
      constructor(name : string,type : DGCTestTypeEnum, image : string){
        this.Name = name;
        this.Type = type;
        this.Image = image;
      }
  
      public static create(name : string,type : DGCTestTypeEnum, image : string) : TestConfigurationValue {
  
        return new TestConfigurationValue(name,type,image);
      }
  
    }