export class RecordRTCOptions {
  mimeType: any | undefined;
  numberOfAudioChannels: any | undefined;
  sampleRate: number | undefined;

  private constructor(mimeType : any,numberOfAudioChannels : any,sampleRate : number){

    this.mimeType = mimeType;
    this.numberOfAudioChannels = numberOfAudioChannels;
    this.sampleRate = sampleRate;

  }

  public static create(mimeType : any = "audio/wav", numberOfAudioChannels : any = 2,sampleRate : number = 48000):RecordRTCOptions{

    return new RecordRTCOptions(mimeType, numberOfAudioChannels,sampleRate);

  }

}
