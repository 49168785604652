import { Component, OnInit } from '@angular/core';
import { RouterService } from 'src/app/layout/router.service';
import { QuitTestService } from '../../quit-test.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor(private routerService: RouterService,private quitTestService : QuitTestService) {}

  ngOnInit(): void {}

  navigateToHome() {
    this.routerService.navigateTo('getting-started');
    this.quitTestService.setQuitTest(true);
  }
}
