import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdIstructionsMatchComponent } from './thirdIstructionsMatch.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { DotsIstructionsModule } from '../../dots-istructions/dots-istructions.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatchNumberGridModule } from 'src/app/tests/games/match-numbers/match-number-grid/match-number-grid.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    DotsIstructionsModule,
    TranslateModule,
    MatchNumberGridModule,
    MatGridListModule,
    MatIconModule
  ],
  declarations: [ThirdIstructionsMatchComponent],
  exports:[ThirdIstructionsMatchComponent]
})
export class ThirdIstructionsMatchModule { }
