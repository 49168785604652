export class EDSS {

  constructor(
    public id : number,
    public label : string,
  )
{}


}

export class EDSSObjectValue {

  public static zero : EDSS = new EDSS (0,'Non ho problemi o limitazioni');
  public static primo : EDSS = new EDSS (1,'A causa della SM ho qualche sintomo moderato, prevalentemente di tipo sensoriale, ma questo non limita le mie attività. Superato l’attacco le mie condizioni ritornano normali');
  public static secondo : EDSS = new EDSS (2,'Ho alcuni sintomi evidenti dovuti alla SM (debolezza muscolare, lievi disturbi visivi), ma sono di tipo minore e influenzano poco il mio stile di vita');
  public static terzo : EDSS = new EDSS (3,'Ho i sintomi descritti sopra ma non ho nessuna limitazione a camminare. Tuttavia la SM limita in altro modo le mie attività giornaliere');
  public static quarto : EDSS = new EDSS (4,'La SM interferisce con le mie attività, in particolare con la mia capacità di camminare. Sono in grado di camminare per almeno 300-500 metri senza un aiuto e senza fermarmi e posso lavorare a tempo pieno, ma sono diventate più difficili le attività sportive e quelle che richiedono uno sforzo fisico.  Normalmente non ho bisogno di un bastone o altre forme di sostegno per camminare, ma potrò aver bisogno di assistenza durante una ricaduta');
  public static quinto : EDSS = new EDSS (5,'Sono in grado di camminare per 100-200 metri senza aiuto e senza fermarmi, ma spesso uso il bastone o altre forme di sostegno (come appoggiarmi al muro o al braccio di qualcuno) specialmente quando cammino fuori casa. Ho sempre bisogno di assistenza (bastone o grucce) quando cammino per più di 200 metri. Molte delle mie attività giornaliere sono limitate e posso aver bisogno di assistenza.');
  public static sesto : EDSS = new EDSS (6,'Ho bisogno di un bastone o di una gruccia o di qualcuno a cui appoggiarmi per camminare fino a 100 metri. Posso girare per casa o per altri ambienti appoggiandomi ai mobili o ai muri. Per lunghe distanze è possibile che utilizzi la carrozzina. Le mie attività sono limitate.');
  public static settimo : EDSS = new EDSS (7,'Per poter camminare fino a 20 metri ho bisogno di 2 bastoni o delle grucce o di un deambulatore. Utilizzo la carrozzina o lo scooter per distanze maggiori');
  public static ottavo : EDSS = new EDSS (8,'Il mio mezzo principale per muovermi è la carrozzina e sono in grado di muovermi con la carrozzina anche senza aiuto. Sono in grado di stare in piedi e/o di fare uno o due gradini, ma non posso camminare per 10 metri, anche con l’aiuto delle grucce o del deambulatore.');
  public static nono : EDSS = new EDSS (9,'Sono costretto a letto o sulla sedia. Il mio principale mezzo per muovermi è la carrozzina, ma ho bisogno di un aiuto per muovermi con la carrozzina.');
  public static decimo : EDSS = new EDSS (10,'Sono costretto a letto e non riesco a stare sulla carrozzina per più di un’ora al giorno.');




  public static list = () => [this.zero,this.primo,this.secondo,this.terzo,this.quarto,this.quinto,this.sesto,this.settimo,this.ottavo,this.nono,this.decimo]

  constructor(){}

}
