import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { SchedaTutorialLogin } from '../tutorial-login.component';
import { Router } from '@angular/router';
import { UserController } from 'src/app/_core/controllers/user.controller';

@Component({
  selector: 'app-tutorial-login-scheda',
  templateUrl: './tutorial-login-scheda.component.html',
  styleUrls: ['./tutorial-login-scheda.component.scss']
})
export class TutorialLoginSchedaComponent implements OnInit,AfterViewInit {

  @Input() scheda! : SchedaTutorialLogin;
  visible : boolean = false;

  constructor(private route: Router, private userController : UserController) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.visible = true;
  }


}
