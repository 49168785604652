import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultCardModule } from 'src/app/_components/result-card/result-card.module';
import { YourResultComponent } from './your-result.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TestDescriptionCardModule } from 'src/app/_components/test-description-card/test-description-card/test-description-card.module';
import { TestResultModule } from './test-result/test-result/test-result.module';

@NgModule({
  declarations: [YourResultComponent],
  imports: [
    CommonModule,
    ResultCardModule,
    TranslateModule,
    TestDescriptionCardModule,
    TestResultModule,
    RouterModule.forChild([
      { path: '', component: YourResultComponent, data: { disableExit: true }, }
    ])
  ],
})
export class YourResultModule { }
