<div class="privacy-cntr">
<h1 mat-dialog-title class="title">
  Condizioni contrattuali
</h1>
<div mat-dialog-content class="subtitle privacyBody" [innerHTML]="text">
<!-- <h2>TERMINI E CONDIZIONI D’USO DELL’ APP “DIGICOG-MS”®
GLI UTENTI SONO INVITATI A LEGGERE ATTENTAMENTE I PRESENTI TERMINI E CONDIZIONI (PRIMA DI UTILIZZARE L’APP DIGICOG-MS®</h2><br>
<h3>I presenti Termini, unitamente all’informativa sulla privacy dell’APP, disponibile nell’APP e qui incorporata per riferimento (insieme i “Termini”), costituiscono un accordo vincolante che disciplina l’utilizzo della APP da parte dell’utente (di seguito “Utente”) e la Fondazione Italiana Sclerosi Multipla ETS (di seguito “FISM”). Utilizzando l’APP, l’Utente accetta espressamente i presenti Termini. Se non si accettano i presenti Termini, si prega di non scaricare o utilizzare l’APP.<br>
</h3><br><h2>1.	DESCRIZIONE E AMBITO DI APPLICAZIONE DELL’APP</h2><br>
<h3>Questa APP consente di avere accesso a moduli di potenziamento delle funzioni cognitive (i.e., memoria visuo-spaziale) e visualizzare i risultati delle performance. L'APP non è destinata a fornire diagnosi, analizzare o interpretare i risultati ottenuti. L'APP non è destinata all'uso da parte di persone di età inferiore ai 18 anni, né è destinata a ottenere informazioni da persone di età inferiore ai 18 anni. Le informazioni fornite tramite l'APP (e.g., punteggio al termine del modulo di potenziamento) non sostituiscono il consiglio del proprio medico o di altro operatore sanitario qualificato. L’APP può essere gestita o utilizzata solo ed esclusivamente secondo lo scopo, le specifiche e le aree di applicazione dell'APP come documentato nei presenti Termini.
</h3><br><h2>2.	REGISTRAZIONE DELL’UTENTE</h2><br>
<h3>È necessario avere almeno 18 anni per avere accesso all’APP e utilizzarla. Accedendo all’APP usandola e/o inviando informazioni ad essa o tramite essa, L’Utente dichiara di avere almeno 18 anni. Per l’utilizzo dell’APP è necessario registrarsi e accettare i presenti Termini. Successivamente l’Utente potrà creare un proprio account e utilizzare l’APP. L’utente, creando il proprio account, accetta di fornire informazioni veritiere, aggiornate e corrette ed è responsabile di proteggere le proprie credenziali per evitare l’accesso da parte di soggetti terzi o da abusi o utilizzi fraudolenti. L’Utente è responsabile per tutte le attività di qualsiasi account registrato a suo nome e tutti i rischi di un eventuale uso non autorizzato del suo account.
</h3><br><h2>3.	LICENZA D’USO APP</h2><br>
<h3>L’APP viene fornita all’Utente gratuitamente in ragione di una licenza revocabile, non esclusiva, non trasferibile e limitata per accedere e utilizzare l'APP esclusivamente per uso personale e rigorosamente in conformità con i presenti Termini. L'utente accetta di non e non consentirà ad altri di: (i) concedere in licenza, vendere, noleggiare, assegnare, distribuire, trasmettere, divulgare o altrimenti sfruttare l'APP; (ii) utilizzare l'APP per scopi diversi da quelli consentiti dai presenti Termini; (iii) modificare, creare opere derivate, suddividere, incorporare o comunque alterare l'APP in tutto o in parte; (iv) rimuovere, alterare o oscurare qualsiasi riferimento alla proprietà (incluso qualsiasi avviso di copyright o marchio) di FISM; o (v) utilizzare l'APP in modo tale da violare la legge applicabile.
L’Utente riconosce e accetta che FISM potrà non fornire l’accesso all’APP o interromperne (in modo permanente o temporaneo) la disponibilità a sua esclusiva discrezione, senza preavviso e senza che ciò implichi alcuna responsabilità nei suoi confronti. L’Utente può interrompere l’utilizzo di qualsiasi parte dell’APP senza obbligo di alcuna informativa a FISM. FISM si riserva il diritto di bloccare l’account dell’Utente e interrompere l’accesso alla APP qualora ritenga che l’utilizzo della stessa stia avvenendo in violazione dei presenti Termini che si intenderanno pertanto risolti di diritto. L’Utente riconosce e accetta che, in caso di account disabilitato, gli sarà impedito di accedere all’APP, ai dettagli del suo account o a qualsiasi file e altro contenuto del suo account. La licenza di utilizzo dell’APP è valida dal momento dell’accettazione dei presenti Termini fino al verificarsi di una delle cause di cessazione di cui al presente paragrafo.
</h3><br><h2>4.	MODIFICHE ALL’APP</h2><br>
<h3>FISM si riserva il diritto di modificare, sospendere o interrompere, temporaneamente o permanentemente, l’accesso all’ APP senza alcuna responsabilità nei confronti dell’Utente e a sua assoluta discrezione. FISM può periodicamente procedere al rilascio di miglioramenti delle caratteristiche/funzionalità dell'APP, che possono includere patch, correzioni di bug, aggiornamenti, upgrade e altre modifiche (“Aggiornamenti”). Gli aggiornamenti possono modificare o eliminare determinate caratteristiche e/o funzionalità dell'APP. L'Utente accetta che FISM non abbia alcun obbligo di (i) fornire eventuali aggiornamenti; o (ii) continuare a fornire o abilitare eventuali caratteristiche e/o funzionalità particolari dell'APP. Tutti gli Aggiornamenti saranno considerati parte integrante dell'APP e saranno soggetti ai presenti Termini.
</h3><br><h2>5.	SERVIZI DI TERZE PARTI</h2><br>
<h3>L'APP può visualizzare, includere o rendere disponibili contenuti di terze parti (inclusi dati, informazioni, applicazioni e altri servizi di prodotti) o fornire collegamenti a prodotti, siti Web o servizi di terze parti ("Servizi di terze parti"). L'utente riconosce e accetta che FISM non sarà responsabile dei Servizi di terzi, inclusa la loro accuratezza, completezza, tempestività, validità, conformità al copyright, legalità, decenza, qualità o qualsiasi altro aspetto degli stessi. FISM non si assume e non avrà alcuna responsabilità nei confronti dell'utente o di qualsiasi altra persona o entità per servizi di terze parti. I Servizi di terze parti e i relativi collegamenti sono forniti esclusivamente per comodità dell'utente e del suo accesso, e l'utilizzo di tali servizi è interamente a proprio rischio e soggetto ai termini e alle condizioni di tali terze parti. Troverai la nostra APP negli APP Store. In generale si applicano le condizioni commerciali dei relativi fornitori di piattaforme (sui quali non abbiamo alcuna influenza); questi non fanno parte dei nostri Termini né del nostro rapporto contrattuale con te come Utente della nostra APP. Ciò vale anche per le condizioni commerciali del vostro dispositivo mobile o del vostro fornitore di servizi.
</h3><br><h2>6.	PROPRIETA’ INTELLETTUALE E MARCHI</h2><br>
<h3>Fatta eccezione per la licenza d’uso espressamente riconosciuta all'Utente ai sensi dei presenti Termini, tutti i diritti sull'APP, inclusi tutti i diritti di proprietà industriale e proprietà intellettuale nessuno escluso, compresi eventuali diritti derivati e connessi all’APP sono di proprietà esclusiva di FISM. Qualsiasi feedback, commento, idea, suggerimento o proposta (collettivamente, “Proposta”) fornita dall'Utente a FISM in relazione all'APP diverrà di titolarità esclusiva di FISM senza alcun obbligo di compenso o riconoscimento alcuno nei confronti dell’Utente.
Salvo quanto diversamente indicato, tutti i contenuti dell'APP, inclusi testi, immagini, foto, grafiche, loghi, icone, nomi, marchi, moduli, audio, video, questionari e software (collettivamente “Contenuti”), sono di proprietà di FISM. il risultato della compilazione dell'APP è e sarà di proprietà esclusiva di FISM.  Salvo specifica autorizzazione, è vietato qualsiasi utilizzo dei Contenuti su qualsiasi altro sito Web o ambiente informatico di qualsiasi tipo e natura per qualsiasi scopo.
</h3><br><h2>7.	TUTELA DEI DATI PERSONALI</h2><br>
<h3>All’atto della registrazione l’Utente dichiara di aver letto, compreso e accettato l'Informativa sulla privacy dell'APP e di acconsentire al trattamento dei suoi dati in conformità con la stessa. Si rimanda all’Informativa per una descrizione dettagliata delle modalità e finalità del trattamento nonché delle modalità per l’esercizio dei diritti dell’interessato. Per quanto riguarda l’analisi dei dati, FISM raccoglie e conserva dati e informazioni per contribuire a migliorare l’APP. Queste informazioni possono includere, e non sono limitate a, feedback sull'utilizzo dell'APP, dati aggregati, analisi (come marca, tipo e modello di dispositivo che stai utilizzando) e altre informazioni risultanti dall'utilizzo dell'APP. Si prega di consultare l'Informativa sulla privacy dell'APP per maggiori dettagli sull'uso dell'analisi dei dati.
</h3><br><h2>8.	ESCLUSIONE DI GARANZIE E LIMITAZIONE DI RESPONSABILITÀ</h2><br>
<h3>L’APP è concessa in licenza nello stato in cui si trova (“as is”). FISM non fornisce nessuna garanzia implicita o esplicita sull’APP. Si intende sin da ora esclusa ogni conformità all’uso o garanzie di buon funzionamento per un fine o un risultato particolare diverso da quello di cui al paragrafo 1). L’utente accetta che l'utilizzo dell'APP è volontario e interamente a sua discrezione e che qualora sia insoddisfatto dell'APP e/o dei servizi correlati potrà esercitare come rimedio solo la cessazione di utilizzo dell’APP. È esclusa sin da ora in capo a FISM qualsiasi responsabilità contrattuale e/o extracontrattuale, per danni diretti e indiretti derivanti all’utente o a terzi dall’utilizzo o dal mancato utilizzo dell’APP, quali, a mero titolo esemplificativo e non esaustivo, perdite di informazioni, interruzioni di servizio etc. Sono espressamente inclusi eventuali danni derivanti da azioni intraprese dall’utente o da altri in base alle informazioni fornite tramite l'APP. In nessun caso FISM sarà ritenuta impegnata, direttamente o indirettamente, in attività medica o nella erogazione di servizi medici, nè sarà responsabile di qualsiasi interpretazione, giudizio o decisione medica (diagnostica, clinica o altra) realizzata dall’utente in ragione delle informazioni e dei contenuti dell’APP.
</h3><br><h2>MODIFICHE AI TERMINI</h2><br>
<h3>FISM si riserva il diritto di modificare i presenti Termini o altre norme che ne disciplinano l’uso in qualsiasi momento. Le modifiche verranno pubblicate all’interno dell’APP. Continuando ad utilizzarla dopo la pubblicazione delle suddette modifiche, l’Utente dichiara di accettarle. Tutte le modifiche saranno efficaci dal momento in cui saranno pubblicate e da quel momento si applicheranno in caso di accesso ed utilizzo dell’APP.
</h3><br><h2>LEGGE APPLICABILE E FORO COMPETENTE</h2><br>
<h3>I presenti Termini e l’uso dell’APP è disciplinato dalla legge italiana. Per qualsiasi controversia sarà esclusivamente competente il foro di Genova.
</h3> -->

</div>
<div mat-dialog-content class="subtitle privacyBody" *ngIf="data == 1">
  <h2>TERMINI E CONDIZIONI D’USO DELL’ APP “DIGICOG-MS”® DDDDDD
  GLI UTENTI SONO INVITATI A LEGGERE ATTENTAMENTE I PRESENTI TERMINI E CONDIZIONI (PRIMA DI UTILIZZARE L’APP DIGICOG-MS®</h2><br>
  <h3>I presenti Termini, unitamente all’informativa sulla privacy dell’APP, disponibile nell’APP e qui incorporata per riferimento (insieme i “Termini”), costituiscono un accordo vincolante che disciplina l’utilizzo della APP da parte dell’utente (di seguito “Utente”) e la Fondazione Italiana Sclerosi Multipla ETS (di seguito “FISM”). Utilizzando l’APP, l’Utente accetta espressamente i presenti Termini. Se non si accettano i presenti Termini, si prega di non scaricare o utilizzare l’APP.<br>
  </h3><br><h2>1.	DESCRIZIONE E AMBITO DI APPLICAZIONE DELL’APP</h2><br>
  <h3>Questa APP consente di avere accesso a moduli di potenziamento delle funzioni cognitive (i.e., memoria visuo-spaziale) e visualizzare i risultati delle performance. L'APP non è destinata a fornire diagnosi, analizzare o interpretare i risultati ottenuti. L'APP non è destinata all'uso da parte di persone di età inferiore ai 18 anni, né è destinata a ottenere informazioni da persone di età inferiore ai 18 anni. Le informazioni fornite tramite l'APP (e.g., punteggio al termine del modulo di potenziamento) non sostituiscono il consiglio del proprio medico o di altro operatore sanitario qualificato. L’APP può essere gestita o utilizzata solo ed esclusivamente secondo lo scopo, le specifiche e le aree di applicazione dell'APP come documentato nei presenti Termini.
  </h3><br><h2>2.	REGISTRAZIONE DELL’UTENTE</h2><br>
  <h3>È necessario avere almeno 18 anni per avere accesso all’APP e utilizzarla. Accedendo all’APP usandola e/o inviando informazioni ad essa o tramite essa, L’Utente dichiara di avere almeno 18 anni. Per l’utilizzo dell’APP è necessario registrarsi e accettare i presenti Termini. Successivamente l’Utente potrà creare un proprio account e utilizzare l’APP. L’utente, creando il proprio account, accetta di fornire informazioni veritiere, aggiornate e corrette ed è responsabile di proteggere le proprie credenziali per evitare l’accesso da parte di soggetti terzi o da abusi o utilizzi fraudolenti. L’Utente è responsabile per tutte le attività di qualsiasi account registrato a suo nome e tutti i rischi di un eventuale uso non autorizzato del suo account.
  </h3><br><h2>3.	LICENZA D’USO APP</h2><br>
  <h3>L’APP viene fornita all’Utente gratuitamente in ragione di una licenza revocabile, non esclusiva, non trasferibile e limitata per accedere e utilizzare l'APP esclusivamente per uso personale e rigorosamente in conformità con i presenti Termini. L'utente accetta di non e non consentirà ad altri di: (i) concedere in licenza, vendere, noleggiare, assegnare, distribuire, trasmettere, divulgare o altrimenti sfruttare l'APP; (ii) utilizzare l'APP per scopi diversi da quelli consentiti dai presenti Termini; (iii) modificare, creare opere derivate, suddividere, incorporare o comunque alterare l'APP in tutto o in parte; (iv) rimuovere, alterare o oscurare qualsiasi riferimento alla proprietà (incluso qualsiasi avviso di copyright o marchio) di FISM; o (v) utilizzare l'APP in modo tale da violare la legge applicabile.
  L’Utente riconosce e accetta che FISM potrà non fornire l’accesso all’APP o interromperne (in modo permanente o temporaneo) la disponibilità a sua esclusiva discrezione, senza preavviso e senza che ciò implichi alcuna responsabilità nei suoi confronti. L’Utente può interrompere l’utilizzo di qualsiasi parte dell’APP senza obbligo di alcuna informativa a FISM. FISM si riserva il diritto di bloccare l’account dell’Utente e interrompere l’accesso alla APP qualora ritenga che l’utilizzo della stessa stia avvenendo in violazione dei presenti Termini che si intenderanno pertanto risolti di diritto. L’Utente riconosce e accetta che, in caso di account disabilitato, gli sarà impedito di accedere all’APP, ai dettagli del suo account o a qualsiasi file e altro contenuto del suo account. La licenza di utilizzo dell’APP è valida dal momento dell’accettazione dei presenti Termini fino al verificarsi di una delle cause di cessazione di cui al presente paragrafo.
  </h3><br><h2>4.	MODIFICHE ALL’APP</h2><br>
  <h3>FISM si riserva il diritto di modificare, sospendere o interrompere, temporaneamente o permanentemente, l’accesso all’ APP senza alcuna responsabilità nei confronti dell’Utente e a sua assoluta discrezione. FISM può periodicamente procedere al rilascio di miglioramenti delle caratteristiche/funzionalità dell'APP, che possono includere patch, correzioni di bug, aggiornamenti, upgrade e altre modifiche (“Aggiornamenti”). Gli aggiornamenti possono modificare o eliminare determinate caratteristiche e/o funzionalità dell'APP. L'Utente accetta che FISM non abbia alcun obbligo di (i) fornire eventuali aggiornamenti; o (ii) continuare a fornire o abilitare eventuali caratteristiche e/o funzionalità particolari dell'APP. Tutti gli Aggiornamenti saranno considerati parte integrante dell'APP e saranno soggetti ai presenti Termini.
  </h3><br><h2>5.	SERVIZI DI TERZE PARTI</h2><br>
  <h3>L'APP può visualizzare, includere o rendere disponibili contenuti di terze parti (inclusi dati, informazioni, applicazioni e altri servizi di prodotti) o fornire collegamenti a prodotti, siti Web o servizi di terze parti ("Servizi di terze parti"). L'utente riconosce e accetta che FISM non sarà responsabile dei Servizi di terzi, inclusa la loro accuratezza, completezza, tempestività, validità, conformità al copyright, legalità, decenza, qualità o qualsiasi altro aspetto degli stessi. FISM non si assume e non avrà alcuna responsabilità nei confronti dell'utente o di qualsiasi altra persona o entità per servizi di terze parti. I Servizi di terze parti e i relativi collegamenti sono forniti esclusivamente per comodità dell'utente e del suo accesso, e l'utilizzo di tali servizi è interamente a proprio rischio e soggetto ai termini e alle condizioni di tali terze parti. Troverai la nostra APP negli APP Store. In generale si applicano le condizioni commerciali dei relativi fornitori di piattaforme (sui quali non abbiamo alcuna influenza); questi non fanno parte dei nostri Termini né del nostro rapporto contrattuale con te come Utente della nostra APP. Ciò vale anche per le condizioni commerciali del vostro dispositivo mobile o del vostro fornitore di servizi.
  </h3><br><h2>6.	PROPRIETA’ INTELLETTUALE E MARCHI</h2><br>
  <h3>Fatta eccezione per la licenza d’uso espressamente riconosciuta all'Utente ai sensi dei presenti Termini, tutti i diritti sull'APP, inclusi tutti i diritti di proprietà industriale e proprietà intellettuale nessuno escluso, compresi eventuali diritti derivati e connessi all’APP sono di proprietà esclusiva di FISM. Qualsiasi feedback, commento, idea, suggerimento o proposta (collettivamente, “Proposta”) fornita dall'Utente a FISM in relazione all'APP diverrà di titolarità esclusiva di FISM senza alcun obbligo di compenso o riconoscimento alcuno nei confronti dell’Utente.
  Salvo quanto diversamente indicato, tutti i contenuti dell'APP, inclusi testi, immagini, foto, grafiche, loghi, icone, nomi, marchi, moduli, audio, video, questionari e software (collettivamente “Contenuti”), sono di proprietà di FISM. il risultato della compilazione dell'APP è e sarà di proprietà esclusiva di FISM.  Salvo specifica autorizzazione, è vietato qualsiasi utilizzo dei Contenuti su qualsiasi altro sito Web o ambiente informatico di qualsiasi tipo e natura per qualsiasi scopo.
  </h3><br><h2>7.	TUTELA DEI DATI PERSONALI</h2><br>
  <h3>All’atto della registrazione l’Utente dichiara di aver letto, compreso e accettato l'Informativa sulla privacy dell'APP e di acconsentire al trattamento dei suoi dati in conformità con la stessa. Si rimanda all’Informativa per una descrizione dettagliata delle modalità e finalità del trattamento nonché delle modalità per l’esercizio dei diritti dell’interessato. Per quanto riguarda l’analisi dei dati, FISM raccoglie e conserva dati e informazioni per contribuire a migliorare l’APP. Queste informazioni possono includere, e non sono limitate a, feedback sull'utilizzo dell'APP, dati aggregati, analisi (come marca, tipo e modello di dispositivo che stai utilizzando) e altre informazioni risultanti dall'utilizzo dell'APP. Si prega di consultare l'Informativa sulla privacy dell'APP per maggiori dettagli sull'uso dell'analisi dei dati.
  </h3><br><h2>8.	ESCLUSIONE DI GARANZIE E LIMITAZIONE DI RESPONSABILITÀ</h2><br>
  <h3>L’APP è concessa in licenza nello stato in cui si trova (“as is”). FISM non fornisce nessuna garanzia implicita o esplicita sull’APP. Si intende sin da ora esclusa ogni conformità all’uso o garanzie di buon funzionamento per un fine o un risultato particolare diverso da quello di cui al paragrafo 1). L’utente accetta che l'utilizzo dell'APP è volontario e interamente a sua discrezione e che qualora sia insoddisfatto dell'APP e/o dei servizi correlati potrà esercitare come rimedio solo la cessazione di utilizzo dell’APP. È esclusa sin da ora in capo a FISM qualsiasi responsabilità contrattuale e/o extracontrattuale, per danni diretti e indiretti derivanti all’utente o a terzi dall’utilizzo o dal mancato utilizzo dell’APP, quali, a mero titolo esemplificativo e non esaustivo, perdite di informazioni, interruzioni di servizio etc. Sono espressamente inclusi eventuali danni derivanti da azioni intraprese dall’utente o da altri in base alle informazioni fornite tramite l'APP. In nessun caso FISM sarà ritenuta impegnata, direttamente o indirettamente, in attività medica o nella erogazione di servizi medici, nè sarà responsabile di qualsiasi interpretazione, giudizio o decisione medica (diagnostica, clinica o altra) realizzata dall’utente in ragione delle informazioni e dei contenuti dell’APP.
  </h3><br><h2>MODIFICHE AI TERMINI</h2><br>
  <h3>FISM si riserva il diritto di modificare i presenti Termini o altre norme che ne disciplinano l’uso in qualsiasi momento. Le modifiche verranno pubblicate all’interno dell’APP. Continuando ad utilizzarla dopo la pubblicazione delle suddette modifiche, l’Utente dichiara di accettarle. Tutte le modifiche saranno efficaci dal momento in cui saranno pubblicate e da quel momento si applicheranno in caso di accesso ed utilizzo dell’APP.
  </h3><br><h2>LEGGE APPLICABILE E FORO COMPETENTE</h2><br>
  <h3>I presenti Termini e l’uso dell’APP è disciplinato dalla legge italiana. Per qualsiasi controversia sarà esclusivamente competente il foro di Genova.
  </h3>

  </div>

<div class="button-cntr mt-2">

  <button mat-raised-button color="primary" class="privacyButton titleButton" (click)="close()"> Chiudi</button>

</div>
</div>


