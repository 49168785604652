import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { TranslateService } from '@ngx-translate/core';
import { CondizioniContrattualiService } from 'src/app/_components/condizioni-contrattuali/condizioni-contrattuali.service';
import { GenericLabel, ModalButton } from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { diseaseForm, DISEASEFORMObjectValue } from 'src/app/_core/classes/DTO/userInfoHistory/diseaseForm';
import { EDSS, EDSSObjectValue } from 'src/app/_core/classes/DTO/userInfoHistory/edss';
import { Education, EducationObjectValue } from 'src/app/_core/classes/DTO/userInfoHistory/education';
import { MaritalStatus, MaritalStatusObjectValue } from 'src/app/_core/classes/DTO/userInfoHistory/marital-status';
import { UserInfoHistory } from 'src/app/_core/classes/DTO/userInfoHistory/user-info-history';
import { Registration } from 'src/app/_core/classes/Registration';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import { RegistrationController } from 'src/app/_core/controllers/registration.controller';
import { PasswordStrengthValidator } from 'src/app/_core/services/password-strength.validators';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  form: FormGroup;
  formStep2 : FormGroup;
  maritalStatuses : Array<MaritalStatus> = MaritalStatusObjectValue.list();
  educationes : Array<Education> = EducationObjectValue.list();
  professionalConditions : Array<string> = ['Occupato','Disoccupato','Pensionato'];
  edss : Array<EDSS> = EDSSObjectValue.list();
  diseaseForm : Array<diseaseForm> = DISEASEFORMObjectValue.list();
  thisYear? : number ;
  checked = false;
  stepComplete = false;

  private labelsDialog: DialogClass = DialogClass.create(
    new GenericLabel('AVVISO'),
    [
      new ModalButton('OK', () => { this.navigateToHome()
      }, false),
    ]
  ).setDescription(new GenericLabel ('EMAILREGISTRATION'));


  maxDate : Date ;
  hide: boolean = true;
  hideConf: boolean = true;




  constructor(private translateService : TranslateService ,public privacyservicy : CondizioniContrattualiService ,private fb: FormBuilder, private registrationController : RegistrationController, private route : ActivatedRoute, private _auth : CtAuthenticationService,private router : Router,private dialog : MatDialog) {

    this.maxDate = new Date();

    this.form = this.fb.group({
      Oid: new FormControl([Validators.required]),
      Name: new FormControl("",[Validators.required]),
      Surname: new FormControl("",[Validators.required]),
      Email: new FormControl("",[Validators.required, Validators.email]),
      Password: new FormControl("",[Validators.required, Validators.minLength(6), PasswordStrengthValidator]),
      ConfermaPassword: new FormControl("",[Validators.required]),
      //FiscalCode: new FormControl("",[Validators.required, Validators.pattern('^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$')]),
      Language: new FormControl(),
      Birthday: new FormControl("", [Validators.required]),
    });

    this.formStep2 = this.fb.group({

      TermsAndConditions : new FormControl("",[Validators.requiredTrue]),
      Privacy : new FormControl("",[Validators.requiredTrue]),

    });

   }

  ngOnInit() {

    if (this._auth.userValue) this.navigateToHome();

    this.thisYear = new Date().getFullYear();

  }



  get passwordNotEqual() {

    const formValue = this.form.value;

    return this.form.touched && !(formValue.Password === formValue.ConfermaPassword);

  }

  step1complete(){

    if(this.form.valid &&!this.passwordNotEqual ){

      this.stepComplete = true;

    }

  }


  createUser(){


    if(this.form.valid && this.formStep2.valid &&!this.passwordNotEqual){

      if(this.form.controls['Birthday'].value){

        this.form.controls['Birthday'].setValue(this.transformDateToCurrentTimezone(this.form.controls['Birthday'].value));

      }

      var registrationInfo : Registration = Registration.createByClass(this.form.value as Registration);

      if(this.formStep2.controls['TermsAndConditions'].value){

        registrationInfo.TermsAndConditionAcceptedAt = this.transformDateToCurrentTimezone(new Date());

      }
      if(this.formStep2.controls['Privacy'].value){

        registrationInfo.PrivacyAcceptedAt = this.transformDateToCurrentTimezone(new Date());

      }



      if(this.translateService.currentLang == "it"){

        registrationInfo.Language = "it-IT";

      }else
      {
        registrationInfo.Language = "en-EN"
      }




      registrationInfo.UserInfoHistory = UserInfoHistory.create(this.form.value as UserInfoHistory);


      this.registrationController.signup(registrationInfo).subscribe((result => {
        this.openDialog();
      }));

    }

  }
  openDialog() {
    this.dialog.open(GenericModalComponent, {
      data: this.labelsDialog,
    });
  }


    navigateToHome = () => this.router.navigate(['getting-started']);

    transformDateToCurrentTimezone(date: Date): Date {

      const os = date.getTimezoneOffset();
      return new Date(date.getTime() - os * 60 * 1000);

    }

    openTermini(elmnt : number){


      this.privacyservicy.openCondizioniContrattuali(elmnt);

    }

}
