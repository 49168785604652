import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionsComponent } from './instructions.component';
import { RouterModule } from '@angular/router';
import { MatchNumbersComponent } from '../../match-numbers/match-numbers.component';
import { GAME } from 'src/app/_core/enums/game-type.enum';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FirstIstructionsMatchModule } from './IstructionsMatchNumbers/FirstIstructionsMatch/FirstIstructionsMatch.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SecondIstructionsMatchModule } from './IstructionsMatchNumbers/secondIstructionsMatch/secondIstructionsMatch.module';
import { ThirdIstructionsMatchModule } from './IstructionsMatchNumbers/thirdIstructionsMatch/thirdIstructionsMatch.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    FirstIstructionsMatchModule,
    SecondIstructionsMatchModule,
    ThirdIstructionsMatchModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule.forChild([

      {
        path: '',
        children: [
          {
            path: 'test',
            component: MatchNumbersComponent,
            data: { disableBack: true, disableQuit: true,type: GAME.TEST_MODE },

          },
          {
            path: 'training',
            component: MatchNumbersComponent,
            data: { disableBack: true, disableQuit: true,type: GAME.TRAINING_MODE },

          },
          {
            path: '',
            component: InstructionsComponent,

          }
        ]
      },
    ])
  ],

  declarations: [InstructionsComponent]
})
export class InstructionsModule { }
