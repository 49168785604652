import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { GridLegendComponent } from './grid-legend.component';
import { TranslateModule } from '@ngx-translate/core';
import { LegendRowComponent } from './legend-row/legend-row.component';

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule],
  declarations: [GridLegendComponent, LegendRowComponent],
  exports: [GridLegendComponent],
})
export class GridLegendModule {}
