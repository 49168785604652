<div class="d-flex flex-column test-type-cntr padding-top">

  <div class="text-center margin-bot-50-tablet ">

    <p class="title mb-5 ">

      {{'TYPETITLE' | translate}}

    </p>

  </div>

  <ng-container *ngFor="let mode of modes">

    <div class="type-scheda-cntr margin-bot-50-tablet ">

      <app-generic-card [obj]="mode"></app-generic-card>

    </div>

  </ng-container>

</div>
