import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-timer-test',
  templateUrl: './timer-test.component.html',
  styleUrls: ['./timer-test.component.scss'],
})
export class TimerTestComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<TimerTestComponent>,
    @Inject(MAT_DIALOG_DATA) public duration: number) { }

  ngOnInit(): void {

    setTimeout(() => {

      this.dialogRef.close();

    }, this.duration);

  }

}
