import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { DifficultCardModule } from './difficult-card/difficult-card.module';
import { TestIntroComponent } from './test-intro.component';

@NgModule({
  declarations: [TestIntroComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    DifficultCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
  ],

})
export class TestIntroModule {}
