import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-memory-game-actions',
  templateUrl: './memory-game-actions.component.html',
  styleUrls: ['./memory-game-actions.component.scss']
})
export class MemoryGameActionsComponent implements OnInit {

  @Input() viewModel: any;
  @Output() startTimerBetweenTests: EventEmitter<any> = new EventEmitter<any>();
  @Output() endGame: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigateToHome = () => this.router.navigate(['/mode/autonomous-mode/game/memory' ]);

}
