import {Component, OnInit, ViewChild} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CtAuthenticationService, CtProfileService } from '@ctsolution/ct-authentication';
import { GenericLabel, ModalButton } from 'src/app/_components/generic-card/generic-card.component';
import { GenericModalComponent } from 'src/app/_components/generic-modal/generic-modal.component';
import { DialogClass } from 'src/app/_core/classes/modal-class';
import {ProfileFormComponent} from "./profile-form/profile-form.component";
import { UserController } from 'src/app/_core/controllers/user.controller';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  @ViewChild('ProfileForm') profileForm!: ProfileFormComponent;

  constructor(private dialog : MatDialog, private profileService: CtProfileService, private userController : UserController) {
  }

  ngOnInit(): void {
  };

  deleteProfileOpenDialog(){

    let dialogData = DialogClass.create(new GenericLabel('DELETEPROFILEDESCRIPTION'),[
      new ModalButton("NO", () => {
      }, false),
      new ModalButton("SI", () => {
         this.deleteProfile();
      }, true),

    ])
    this.dialog.open(GenericModalComponent, {data : dialogData});

  }



  deleteProfile(){

    this.userController.ProfileDeletionRequest(null).then( r => {
        let label : GenericLabel = new GenericLabel('DELETESUCCESS');
        let buttons : ModalButton[] = [new ModalButton("OK",()=>{},true)];
        let dialogData : DialogClass = DialogClass.create(label,buttons);
        this.dialog.open(GenericModalComponent, {data : dialogData});
      });
  }
}
