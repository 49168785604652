import { Injectable } from "@angular/core";
import { CondizioniContrattualiComponent } from "./condizioni-contrattuali.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { DialogClass } from "src/app/_core/classes/modal-class";
import { GenericLabel } from "../generic-card/generic-card.component";

@Injectable({
  providedIn: "root"
})
export class CondizioniContrattualiService {

  constructor(private dialog: MatLegacyDialog) {
  }

  openCondizioniContrattuali(elmnt : number) {

    this.dialog
      .open(CondizioniContrattualiComponent, { maxWidth: "80vw", maxHeight:"80vh", data : elmnt});

  }
}
