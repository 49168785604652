<div class="d-flex flex-column test-selection-cntr padding-top">

  <ng-container *ngIf="isTest; else isTraining">

    <div class="text-center ">

      <p class="title mb-5"> {{'TESTSELECTTITLE'| translate}} </p>

    </div>

  </ng-container>

  <ng-template #isTraining>

    <div class="text-center">

      <p class="title mb-5"> {{'TRAININGTITLE'| translate}} </p>

    </div>

  </ng-template>
<div class="mobileView">
  <ng-container *ngFor="let mode of modesMobile">

    <div class="selection-scheda-cntr">

      <app-generic-card [obj]="mode"></app-generic-card>

    </div>

  </ng-container>
</div>
<div class="desktopView">
  <ng-container *ngFor="let mode of modesDesktop">

    <div class="selection-scheda-cntr">

      <app-generic-card [obj]="mode"></app-generic-card>

    </div>

  </ng-container>
</div>
</div>
