import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CtAuthenticationModule, CtAuthenticationSetup } from "@ctsolution/ct-authentication";
import { CtWebapiModule, CtWebapiSetup } from "@ctsolution/ct-webapi";
import { ApplicationConfig, CtWebviewModule } from '@ctsolution/ct-webview';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { SpinnerComponent } from "./_components/spinner.component";
import { UserController } from './_core/controllers/user.controller';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';

const AUTHENTICATION_CONFIG = CtAuthenticationSetup.create(environment.Project.Name, environment.API_URL);
AUTHENTICATION_CONFIG.redirectUrl = '/login';

const APPLICATION_CONFIG: ApplicationConfig = {
  Android: {
    package: 'it.digicog.app'
  },
  Apple: {
    scheme: 'digicogms',
    storeLink: ''
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {

  return new TranslateHttpLoader(http);

}

@NgModule({
  declarations: [AppComponent, SpinnerComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatSidenavModule,
    MatDialogModule,
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CtWebapiModule.setup(CtWebapiSetup.create(environment.API_URL)),
    CtAuthenticationModule.setup(AUTHENTICATION_CONFIG),
    CtWebviewModule.setup(APPLICATION_CONFIG)
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: environment.google.tagManagerId,},
    UserController,
    // provideAnimations(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
