import {Location} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CtWebviewService} from '@ctsolution/ct-webview';
import {QuitTestService} from 'src/app/layout/header/quit-test.service';
import {GenericLabel} from 'src/app/_components/generic-card/generic-card.component';
import {TestIntroInfo} from 'src/app/_core/classes/DTO/test-intro-info';
import {RimsService} from 'src/app/_core/services/rims.service';
import {VerbalMemorySaveprogressService} from 'src/app/_core/services/verbal-memory-saveprogress.service';
import {TEST_TYPES} from '../../../_core/enums/test-types.enum';
import {TimerTestService} from '../../_components/timer-test.service';
import {LEVEL, LevelService} from '../memory-game/level.service';
import {TempResultService} from '../temp-result.service';
import {MicrophoneService} from './../../../_core/services/microphone.service';
import {IDifficult} from './difficult-card/difficult-card.component';
import {InstructionsComponent} from './instructions/instructions.component';

@Component({
  selector: 'app-test-intro',
  templateUrl: './test-intro.component.html',
  styleUrls: ['./test-intro.component.scss'],
})
export class TestIntroComponent implements OnInit,OnDestroy {
  selected: TestIntroInfo | undefined;
  testTypes = TEST_TYPES;
  form: FormGroup;

  difficults: Array<IDifficult> = [
    {
      title: new GenericLabel('FACILE', 'text-center'),
      img: new GenericLabel('/assets/images/difficult/facile.svg'),
      selected: false,
      difficult: LEVEL.EASY,
    },
    {
      title: new GenericLabel('MEDIO', 'text-center'),
      img: new GenericLabel('/assets/images/difficult/medio.svg'),
      selected: false,
      difficult: LEVEL.MEDIUM,
    },
    {
      title: new GenericLabel('DIFFICILE', 'text-center'),
      img: new GenericLabel('/assets/images/difficult/difficile.svg'),
      selected: false,
      difficult: LEVEL.HARD,
    },
  ];

  introductions = {
    allenamento: [
      {
        title: 'R&PTITLE',
        description: 'R&PDESCRIPTIONALLENAMENTO',
        type: TEST_TYPES.MEMORY,
        img: '/assets/images/test-intro/allenamento/memory.svg',
      },
      {
        title: 'A&RTITLE',
        description: 'A&RDESCRIPTIONALLENAMENTO',
        type: TEST_TYPES.LISTEN,
        img: '/assets/images/test-intro/allenamento/listen.svg',
      },
      {
        title: 'A_ITITLE',
        description: 'A_IISTRUZIONITRAIN',
        type: TEST_TYPES.MATCH_NUMBERS,
        img: '/assets/images/test-intro/allenamento/couple.svg',
      },
      {
        title: 'G_PTITLE',
        description: 'G_PDESCRIPTIONALLENAMENTO',
        type: TEST_TYPES.GENERATES_WORDS,
        img: '/assets/images/test-intro/allenamento/generate.svg',
      },
    ],

    test: [
      {
        title: 'R&PTITLE',
        description: 'R&PDESCRIPTIONTEST',
        type: TEST_TYPES.MEMORY,
        img: '/assets/images/test-intro/test/memory.svg',
      },
      {
        title: 'A&RTITLE',
        description: 'A&RDESCRIPTIONTEST',
        type: TEST_TYPES.LISTEN,
        img: '/assets/images/test-intro/test/listen.svg',
      },
      {
        title: 'A_ITITLE',
        description: 'A_IISTRUZIONI',
        type: TEST_TYPES.MATCH_NUMBERS,
        img: '/assets/images/test-intro/test/couple.svg',
      },
      {
        title: 'G_PTITLE',
        description: 'G_PDESCRIPTIONTEST',
        type: TEST_TYPES.GENERATES_WORDS,
        img: '/assets/images/test-intro/test/generate.svg',
      },
    ],
  };

  isTest: boolean = false;
  isRims: boolean = false;
  url = this._location.path();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _timer: TimerTestService,
    public _result: TempResultService,
    public _difficult: LevelService,
    private _location: Location,
    private fb: FormBuilder,
    private rimsService: RimsService,
    private quitTestService: QuitTestService,
    private microphoneService: MicrophoneService,
    private saveProgresService: VerbalMemorySaveprogressService,
    private ctWebViewService: CtWebviewService,
    private dialog: MatDialog
  ) {
    this.isRims = rimsService.checkRims();

    this.form = this.fb.group({
      codeRims: new FormControl(
        this.rimsService.getCode(),
        Validators.required
      ),
    });

    this.route.data.subscribe((d) => {
      if (d['type']) {
        let introduction = this.introductions.allenamento;

        if (
          this._result.isTest ||
          this.url.endsWith('test/memory') ||
          this.url.endsWith('test/verbal') ||
          this.url.endsWith('test/generate')
        ) {
          introduction = this.introductions.test;
        }

        this.selected = introduction.find((i) => i.type === d['type']);
      }
    });
  }

  ngOnInit(): void {
    this._difficult.reset();

    if (this._result.isTest || this.url.includes('test')) {
      this.isTest = true;
    }

    if (this.url.includes('verbal') || this.url.includes('generate')) {

      if (this.ctWebViewService.isCtWebView() && this.ctWebViewService.isAndroidDevice()) {

        this.ctWebViewService.AndroidFunctions.checkMicrophonePermissions();

      }

      this.microphoneService.checkMicrophonePermissions();

    }

  }

  ngOnDestroy(){
    this.microphoneService.reset();
  }

  onSelected(nextPath: string) {
    if (this.selected) {
      if (
        this.selected.type == TEST_TYPES.GENERATES_WORDS ||
        this.selected.type == TEST_TYPES.MEMORY
      ) {
        this._timer.open();

        setTimeout(() => {
          this.navigateTo(nextPath);
        }, this._timer.duration);
      } else {
        if (this.selected.type == TEST_TYPES.LISTEN) {
          this.saveProgresService.createSaveProgress();
        }

        this.navigateTo(nextPath);
      }
    }
  }

  navigateTo(path: string) {
    this.router.navigate([path], {relativeTo: this.route});
    this._result.resetVerbalTest();
    this.quitTestService.setQuitTest(false);
  }

  setArray(level: LEVEL) {
    this.difficults.forEach((element) => {
      element.selected = element.difficult === level;
      this._difficult.setLevel(level);
    });
  }

  putUserInfo = () => {
    if (this.form.valid) {
      let userForm: rims = this.form.value as rims;
      this.rimsService.setCode(userForm.codeRims);
      this.onSelected('test');
    }
  };


}

interface rims {
  codeRims: string;
}
