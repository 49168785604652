import { diseaseForm } from "./diseaseForm";
import { EDSS } from "./edss";
import { Education } from "./education";
import { MaritalStatus } from "./marital-status";

export class UserInfoHistory {

  constructor(
    public Oid: number,
    public MaritalStatus: MaritalStatus,
    public Education: Education,
    public EDSS: EDSS,
    public ReferenceDate: Date,
    public ProfessionalCondition: string,
    public DiseaseForm : diseaseForm,
    public DiagnosisDate : number,
  ) {
  }


  public static create (userInfoo : UserInfoHistory) : UserInfoHistory {

      return new UserInfoHistory(-1,userInfoo.MaritalStatus,userInfoo.Education,userInfoo.EDSS,userInfoo.ReferenceDate,userInfoo.ProfessionalCondition,userInfoo.DiseaseForm,userInfoo.DiagnosisDate)

  }

}

